/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
import {extend} from 'umi-request';
import {message} from "antd";
import { notification } from 'antd';

// const codeMessage = {
//   200: '服务器成功返回请求的数据。',
//   201: '新建或修改数据成功。',
//   202: '一个请求已经进入后台排队（异步任务）。',
//   204: '删除数据成功。',
//   400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
//   401: '用户没有权限（令牌、用户名、密码错误）。',
//   403: '用户得到授权，但是访问是被禁止的。',
//   404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
//   406: '请求的格式不可得。',
//   410: '请求的资源被永久删除，且不会再得到的。',
//   422: '当创建一个对象时，发生一个验证错误。',
//   500: '服务器发生错误，请检查服务器。',
//   502: '网关错误。',
//   503: '服务不可用，服务器暂时过载或维护。',
//   504: '网关超时。',
// };

/**
 * 异常处理程序
 */
const errorHandler = (error: { response: Response }): Response => {
  const { response } = error;
  if (response && response.status) {
    // const errorText = codeMessage[response.status] || response.statusText;
    const { status, url } = response;
    if(status===302&&window.location.pathname!=="/"){
      const redirectUrl = encodeURIComponent(window.location.href);
      window.location.replace(`/login?redirect=${redirectUrl}`)
    }
    console.error(`请求错误 ${status}: ${url}`)
    // notification.error({
    //   message: `请求错误 ${status}: ${url}`,
    //   description: errorText,
    // });
  } else if (!response) {
    console.error(`您的网络发生异常，无法连接服务器，请检测网络或刷新页面`)
    //超时、没有网络的情况
    notification.error({
      description: '您的网络发生异常，无法连接服务器，请检测网络或刷新页面',
      message: '网络异常',
    });
  }
  return response;
};

const allowAccessRequestList=[
  '/yuncut_base_address_db/ServerFunc_GetBaseAddresses',
  '/yuncut_base_address_db/ServerFunc_GetDefaultBaseAddress',
  '/yuncut_base_address_db/ServerFunc_GetDefaultCenter',
  '/yuncut_base_brand_db/ServerFunc_CheckBrand',
  '/yuncut_base_brand_db/ServerFunc_GetBaseBrand_DropDown',
  '/yuncut_base_brand_db/ServerFunc_GetBrand',
  '/yuncut_base_cutmethod_db/ServerFunc_GetPlateThicknessByCutMethodId',
  '/yuncut_base_cutmethod_db/ServerFunc_GetCutmethod',
  '/yuncut_base_cutmethod_db/ServerFunc_GetCutmethod_DropDown',
  '/yuncut_base_cutmethod_db/ServerFunc_GetCutmethodById',
  '/yuncut_base_cuttingseam/ServerFunc_GetCuttingSeam',
  '/yuncut_base_equipbrand_db/ServerFunc_Getequipbrand',
  '/yuncut_base_equipbrand_db/ServerFunc_GetEquipBrand_DropDown',
  '/yuncut_base_freight_db/ServerFunc_GetDistanceByDistrictId',
  '/yuncut_base_freight_db/ServerFunc_GetDistanceCrossBorder',
  '/yuncut_base_freight_db/ServerFunc_GetEqTypeByTonnage',
  '/yuncut_base_freight_db/ServerFunc_GetFreight',
  '/yuncut_base_global_utilization/ServerFunc_GetGlobalUtilization',
  '/yuncut_base_global_utilization/ServerFunc_GetGlobalUtilizationById',
  '/yuncut_base_global_utilization/ServerFunc_Validation_Global_Utilization',
  '/yuncut_base_material_db/ServerFunc_CheckMaterial',
  '/yuncut_base_material_db/ServerFunc_GetMaterial',
  '/yuncut_base_platethick_db/ServerFunc_GetPlatethick',
  '/yuncut_base_platethick_db/ServerFunc_GetPlateThick_dropdown',
  '/yuncut_base_platethick_db/ServerFunc_GetPlateThick_dropdownCustomerParts',
  '/yuncut_base_platethick_db/ServerFunc_isExitsThick',
  '/yuncut_base_platethick_db/ServerFunc_isExitsThick_V2',
  '/yuncut_base_proximatematter_db/ServerFunc_GetProximateMatter',
  '/yuncut_base_texture_db/ServerFunc_GetTexture',
  '/yuncut_base_texture_db/ServerFunc_GetTexture_DropDown',
  '/yuncut_base_texture_db/ServerFunc_GetTexture_DropDownCustomerParts',
  '/yuncut_base_texture_db/ServerFunc_GetTextureList',
  '/yuncut_base_texture_db/ServerFunc_IfTextureListCodeExist',
  '/yuncut_base_texture_db/ServerFunc_IfTextureListNameExist',
  '/yuncut_base_texture_db/ServerFunc_isExitsTextureCode',
  '/yuncut_base_texturecategory_db/ServerFunc_GetTexturecategory',
  '/yuncut_base_texturecategory_db/ServerFunc_GetTextureCategory_DropDown',
  '/yuncut_base_texturecategory_db/ServerFunc_GetTextureCategoryInfo_V2',
  '/yuncut_base_texturestandard_db/ServerFunc_GetTexturestandard',
  '/yuncut_base_texturestandard_db/ServerFunc_GetTexturestandard_Drop_Down',
  '/yuncut_base_texture_db/ServerFunc_GetTextureClassification_DropDown',
  '/yuncut_base_texturestandard_db/ServerFunc_IfTextureNameExist',
  '/yuncut_base_workstage_db/ServerFunc_GetWorkstage',
  '/yuncut_base_workstage_db/ServerFunc_GetWorkstage_Dropdown',
  '/yuncut_base_workstage_db/ServerFunc_VerifyProcessUnique',
  '/yuncut_contract_template/ServerFunc_GetAllTemplate',
  '/yunncut_base_warranty/ServerFunc_AccordingBatchIdGetSteelPlate',
  '/yunncut_base_warranty/ServerFunc_AccordingWarrantyIdGetInfo',
  '/yunncut_base_warranty/ServerFunc_CheckWarrantyExist',
  '/yunncut_base_warranty/ServerFunc_CheckWarrantySteelPlate',
  '/yunncut_base_warranty/ServerFunc_GetSteelAmountInfo',
  '/yunncut_base_warranty/ServerFunc_GetWarranty',
  '/yunncut_base_warranty/ServerFunc_GetWarrantyList',
  '/yuncut_customer_db/ServerFunc_CheckCusAccount',
  '/yuncut_customer_db/ServerFunc_CheckCusPhone',
  '/yuncut_customer_login/ServerFunc_Customer_LoginInByMP',
  '/yuncut_customer_db/ServerFunc_CheckCusPhone',
  '/common_certcode_service/ServerFunc_SendCertCodeNew',
  '/yuncut_customer_db/ServerFunc_GetAccountSonInfo',
  '/yuncut_customer_db/ServerFunc_GetAllAccountMainInfo',
  '/yuncut_customer_db/ServerFunc_GetAllCusAccount',
  '/yuncut_customer_db/ServerFunc_GetCusAccount',
  '/yuncut_customer_db/ServerFunc_GetCusAccountById',
  '/yuncut_customer_db/ServerFunc_GetCusAccountByMP',
  '/yuncut_customer_db/ServerFunc_GetIndividualUser',
  '/yuncut_customer_login/ServerFunc_Customer_LoginIn',
  '/yuncut_customer_db/ServerFunc_GetSonAccount',
  '/yuncut_customer_statistics/ServerFunc_GetPartDatumStatistics',
  '/yuncut_customer_statistics/ServerFunc_GetPortalStatistics',
  '/yuncut_customer_statistics/ServerFunc_GetPortalStatistics_Main',
  '/yuncut_customer_statistics/ServerFunc_GetPortalStatistics_Sup',
  '/yuncut_customer_db/ServerFunc_DeleteSendUser',
  '/yuncut_customer_db/ServerFunc_DeleteYmambdept',
  '/yuncut_customer_db/ServerFunc_GetSend_User',
  '/yuncut_customer_db/ServerFunc_GetUnassigned_User',
  '/yuncut_customer_db/ServerFunc_Save_Send_Ymambdept',
  '/yuncut_customer_db/ServerFunc_UpdateSendYmambdept',
  '/yuncut_customer_db/ServerFunc_UpdateSendYmambdeptCode',
  '/yuncut_customer_db/ServerFunc_SaveCustomerConsultInfo',
  '/yuncut_customer_db/ServerFunc_GetCustomerConsultInfo',
  '/yuncut_customer_db/ServerFunc_modifyCustomerConsultStatus',
  '/yuncut_customer_db/ServerFunc_delConsultServiceRecords',
  '/yuncut_cusparts_datum_db/ServerFunc_GetDatumDrawing',
  '/yuncut_cusparts_datum_db/ServerFunc_CheckDatumCodeIsExits',
  '/yuncut_cusparts_datum_db/ServerFunc_CheckDatumsUniqueness',
  '/yuncut_cusparts_datum_db/ServerFunc_CheckDatumUniqueness',
  '/yuncut_cusparts_datum_db/ServerFunc_CuspartsByIdsGetInfo',
  '/yuncut_cusparts_datum_db/ServerFunc_GetCusFixedDatums',
  '/yuncut_cusparts_datum_db/ServerFunc_GetCusPartsDatumById',
  '/yuncut_cusparts_datum_db/ServerFunc_GetCusPartsDatumByIds',
  '/yuncut_cusparts_datum_db/ServerFunc_GetCusPartsDatums',
  '/yuncut_cusparts_datum_db/ServerFunc_GetCusPartsDatums_zh',
  '/yuncut_cusparts_datum_db/ServerFunc_GetDatumByCustomer',
  '/yuncut_cusparts_datum_db/ServerFunc_GetDatumInfoById',
  '/yuncut_cusparts_datum_db/ServerFunc_GetFixedPriceDatumList',
  '/yuncut_cusparts_datum_db/ServerFunc_GetCusPartsDatumCategorys',
  '/yuncut_cusparts_datum_db/ServerFunc_GetCusPartsDatumWorkStageGroups',
  '/yuncut_cusparts_datum_db/ServerFunc_GetCusPartsDatumCombine',
  '/yuncut_cusparts_datum_db/ServerFunc_GetCusPartsDatumCombineDatums',
  '/yuncut_cusparts_datum_db/ServerFunc_GetCusPartsDatumCombineDatumsSimple',
  '/yuncut_cusparts_datum_db/ServerFunc_GetCusPartsDatumCombines',
  '/yuncut_cusparts_datum_db/ServerFunc_GetCusPartsDatumCombinesByIds',
  '/yuncut_customer_db/ServerFunc_GetAllCustomer',
  '/yuncut_cusparts_datum_db/ServerFunc_GetDatumByCustomer_Excel',
  '/yuncut_cusparts_datum_db/ServerFunc_InquiryCusPartsDatumPrice_Cus',
  '/yuncut_cusparts_datum_db/ServerFunc_InquiryCusPartsDatumPriceNoLogin_Cus',
  '/yuncut_inquire_db/ServerFunc_AccordingDatumIdGetParts',
  '/yuncut_inquire_db/ServerFunc_GetInquireById',
  '/yuncut_inquire_db/ServerFunc_GetInquireById_cus',
  '/yuncut_inquire_db/ServerFunc_GetInquireList',
  '/yuncut_inquire_db/ServerFunc_GetInquireList_All',
  '/yuncut_inquire_db/ServerFunc_GetLanTaskInquireCusparts',
  '/yuncut_inquire_db/ServerFunc_GetInquireList_first',
  '/yuncut_inquire_db/ServerFunc_GetCusparts',
  '/yuncut_inquire_db/ServerFunc_GetCuspartsById',
  '/yuncut_inquire_db/ServerFunc_GetCuspartsById_cus',
  '/yuncut_inquire_db/ServerFunc_GetInquirePartsDocumentId',
  '/yuncut_inquire_db/ServerFunc_GetLayoutNestingImages',
  '/yuncut_inquire_db/ServerFunc_GetLayoutNestingList',
  '/yuncut_inquire_db/ServerFunc_GetLayoutNestingDetail',
  '/yuncut_inquire_db/ServerFunc_delLayoutNestingRecord',
  '/yuncut_inquire_db/ServerFunc_GetLayoutNestingPartsList',
  '/yuncut_pricemgr_db/ServerFunc_CheckMaterialPriceExist',
  '/yuncut_pricemgr_db/ServerFunc_GetMaterialPrices',
  '/yuncut_pricemgr_db/ServerFunc_CheckCutMethodPriceExist',
  '/yuncut_pricemgr_db/ServerFunc_GetStubPrices',
  '/yuncut_pricemgr_db/ServerFunc_GetPunchingPrices',
  '/yuncut_pricemgr_db/ServerFunc_GetCutMethodPrices',
  '/yuncut_pricemgr_db/ServerFunc_GetCutDiscounts',
  '/yuncut_pricemgr_db/ServerFunc_GetCusPartsDatumPrice',
  '/yuncut_pricemgr_db/ServerFunc_GetShipmentDistanceAndPrice',
  '/yuncut_pricemgr_db/ServerFunc_GetCuspartsAllPriceEntity',
  '/yuncut_pricemgr_db/ServerFunc_GetCuspartsBasePrices',
  '/yuncut_pricemgr_db/ServerFunc_GetCuspartsBasePricesCustomer',
  '/yuncut_pricemgr_db/ServerFunc_SaveMaterialPriceBatch',
  '/yuncut_order_manage_db/ServerFunc_AccrodingOrderIdByParts',
  '/yuncut_order_manage_db/ServerFunc_GetRepairablePart',
  '/yuncut_order_manage_db/ServerFunc_GetRepairablePart_Item',
  '/yuncut_order_manage_db/ServerFunc_GetAfterSalesApplicationById',
  '/yuncut_order_manage_db/ServerFunc_GetAfterSalesApplicationList',
  '/yuncut_order_manage_db/ServerFunc_GetAvaiableRequestItemListById',
  '/yuncut_order_manage_db/ServerFunc_GetAvaiableRequestItemListPagin',
  '/yuncut_order_manage_db/ServerFunc_GetAvaiableRequestList',
  '/yuncut_order_manage_db/ServerFunc_GetRequestedList',
  '/yuncut_order_manage_db/ServerFunc_GetCutProgram',
  '/yuncut_order_manage_db/ServerFunc_GetDistributableParts',
  '/yuncut_order_manage_db/ServerFunc_GetDistributablePartsV2',
  '/yuncut_order_manage_db/ServerFunc_GetFilteredByParts',
  '/yuncut_order_manage_db/ServerFunc_GetQualifiedDevices',
  '/yuncut_order_manage_db/ServerFunc_AllocatedOverview',
  '/yuncut_order_manage_db/ServerFunc_FinishedOverview',
  '/yuncut_order_manage_db/ServerFunc_NotPlannedOverview',
  '/yuncut_order_manage_db/ServerFunc_PlannedOverview',
  '/yuncut_order_manage_db/ServerFunc_UnallocatedOverview',
  '/yuncut_order_manage_db/ServerFunc_UnfinishedOverview',
  '/yuncut_order_manage_db/SerberFunc_GetOrderListStatistics',
  '/yuncut_order_manage_db/SerberFunc_GetOrderListStatistics_cus',
  '/yuncut_order_manage_db/ServerFunc_GenerateExcelInfo',
  '/yuncut_order_manage_db/ServerFunc_GetDepositToBeConfirmedOrder',
  '/yuncut_order_manage_db/ServerFunc_GetOrderById',
  '/yuncut_order_manage_db/ServerFunc_GetOrderById_Cus',
  '/yuncut_order_manage_db/ServerFunc_GetOrderList',
  '/yuncut_order_manage_db/ServerFunc_GetOrderList_Finance',
  '/yuncut_order_manage_db/ServerFunc_GetOrderList_Scheduling',
  '/yuncut_order_manage_db/ServerFunc_GetOrderTypeStatistics',
  '/yuncut_order_manage_db/ServerFunc_GetOrderTypeStatistics_cus',
  '/yuncut_order_manage_db/ServerFunc_GetPaymentOrder_cus',
  '/yuncut_order_manage_db/ServerFunc_GetPaymentOrder_oper',
  '/yuncut_order_manage_db/ServerFunc_GetWaitPaidOrderList',
  '/yuncut_order_manage_db/ServerFunc_GetOrderList_All',
  '/yuncut_order_manage_db/ServerFunc_GetOrderList_All_V2',
  '/yuncut_order_manage_db/ServerFunc_GetShippableOrder',
  '/yuncut_order_manage_db/ServerFunc_ShippableOrderCodeGetCuspart',
  '/yuncut_order_manage_db/ServerFunc_GetContract',
  '/yuncut_order_manage_db/ServerFunc_ShippableOrderCodesGetCusparts',
  '/yuncut_order_manage_db/ServerFunc_AccrodingOrderIdGetCustomMaterial',
  '/yuncut_order_manage_db/ServerFunc_AccrodingOrderIdGetCustomMaterial_exclude',
  '/yuncut_order_manage_db/ServerFunc_GetAvaibleStockInCustomer',
  '/yuncut_order_manage_db/ServerFunc_GetCustomerDetail',
  '/yuncut_order_manage_db/ServerFunc_GetCustomerList',
  '/yuncut_order_manage_db/ServerFunc_GetCustomerList_2',
  '/yuncut_order_manage_db/ServerFunc_GetCUstomerStockInOrderDetail',
  '/yuncut_order_manage_db/ServerFunc_ArrcordingCodeGetInfo',
  '/yuncut_order_manage_db/ServerFunc_GetCuspartsById',
  '/yuncut_order_manage_db/ServerFunc_GetCuspartsById_V2',
  '/yuncut_order_manage_db/ServerFunc_GetCuspartsByOrderId',
  '/yuncut_order_manage_db/ServerFunc_GetSalOrderDocumentId',
  '/yuncut_order_manage_db/ServerFunc_GetOrderDetailById',
  '/yuncut_order_manage_db/ServerFunc_GetSteelPlateByOrder',
  '/yuncut_order_manage_db/ServerFunc_GetTypeSettingIds',

  '/yuncut_order_manage_db/ServerFunc_SaveOrder',
  '/yuncut_order_manage_db/ServerFunc_SaveAndGenerateOrder',
  '/yuncut_order_manage_db/ServerFunc_SaveAndGenerateOrderNew',
  '/yuncut_order_manage_db/ServerFunc_SaveContract_cus',
  '/yuncut_order_manage_db/ServerFunc_UpdateOrderToFinUnconfirmed',
  '/yuncut_order_manage_db/ServerFunc_UpdateOrderToProducingById',
  '/yuncut_order_manage_db/ServerFunc_UpdateOrderToReceiptById',
  '/yuncut_order_manage_db/ServerFunc_UpdateOrderToSendById',
  '/yuncut_order_manage_db/ServerFunc_UpdateOrderToBalancingById',
  '/yuncut_order_manage_db/ServerFunc_UpdateOrderToCanceledById',
  '/yuncut_order_manage_db/ServerFunc_UpdateOrderToBalUnconfirmedById',
  '/yuncut_order_manage_db/ServerFunc_PayOrder',
  '/yuncut_order_manage_db/ServerFunc_PayOrders',
  '/yuncut_order_manage_db/ServerFunc_PayPaymentPlans',
  '/yuncut_order_manage_db/ServerFunc_PayPaymentPlan',
  '/yuncut_order_manage_db/ServerFunc_GetOrderTypeStatistics_cus',
  '/yuncut_order_manage_db/SerberFunc_GetOrderListStatistics_cus',
  '/yuncut_order_manage_db/ServerFunc_ConfirmReceipt',
  '/yuncut_order_manage_db/ServerFunc_ConfirmReceipt_batch',
  '/yuncut_produce_order_db/ServerFunc_InspectionReport',
  '/yuncut_produce_order_db/ServerFunc_AccordingTaskIdGetTypographyIds',
  '/yuncut_produce_order_db/ServerFunc_WorkBeforeVerifyPlateBatchNumber',
  '/yuncut_produce_order_db/ServerFunc_GetProgramPlateLog',
  '/yuncut_produce_order_db/ServerFunc_GenerateSmtPrintPage',
  '/yuncut_produce_order_db/ServerFunc_GetChidrenCuttingProgram',
  '/yuncut_produce_order_db/ServerFunc_GetCutMethodByProgramId',
  '/yuncut_produce_order_db/ServerFunc_GenerateProduceOrder',
  '/yuncut_produce_order_db/ServerFunc_GenerateProduceOrderBatch',
  '/yuncut_produce_order_db/ServerFunc_GetParentChildrenProgram',
  '/yuncut_produce_order_db/ServerFunc_GetParentCuttingProgram',
  '/yuncut_produce_order_db/ServerFunc_GetPlate',
  '/yuncut_produce_order_db/ServerFunc_GetProgramAnalysis',
  '/yuncut_produce_order_db/ServerFunc_GetProgramById',
  '/yuncut_produce_order_db/ServerFunc_GetProgramByTaskId',
  '/yuncut_produce_order_db/ServerFunc_GetProgramList',
  '/yuncut_produce_order_db/ServerFunc_GetProgramList_All',
  '/yuncut_produce_order_db/ServerFunc_GetProgramListSupplier',
  '/yuncut_produce_order_db/ServerFunc_GetProgramParts',
  '/yuncut_produce_order_db/ServerFunc_GetProgramScrap',
  '/yuncut_produce_order_db/ServerFunc_GetProgramSupplier',
  '/yuncut_produce_order_db/ServerFunc_GetSmt',
  '/yuncut_produce_order_db/ServerFunc_WorkReportStatistics',
  '/yuncut_produce_order_db/ServerFunc_WorkReportSumDetail',
  '/yuncut_produce_order_db/ServerFunc_WorkReportSummary',
  '/yuncut_produce_order_db/ServerFunc_GetApplyedSupplement',
  '/yuncut_produce_order_db/ServerFunc_GetConfirmedSupplement',
  '/yuncut_produce_order_db/ServerFunc_GetRejectedSupplement',
  '/yuncut_produce_order_db/ServerFunc_GetSupplementItem',
  '/yuncut_produce_order_db/ServerFunc_GetProduceOrderById',
  '/yuncut_produce_order_db/ServerFunc_GetProduceOrderList',
  '/yuncut_produce_order_db/ServerFunc_GetProductOrderSupplier',
  '/yuncut_produce_order_db/ServerFunc_ProducePartsBatchDownload',
  '/yuncut_produce_order_db/ServerFunc_ProducePartsBatchDownload_sup',
  '/yuncut_produce_order_db/ServerFunc_TipsMergeOrNot',
  '/yuncut_produce_order_db/ServerFunc_GetDeletedProgramsRecordList_oper',
  '/yuncut_produce_order_db/ServerFunc_GetDeletedProgramsRecordList_supplier',
  '/yuncut_produce_order_db/ServerFunc_GetProduceOrderList_Excel2',
  '/yuncut_produce_order_db/ServerFunc_GetProduceOrderList_Excel',
  '/yuncut_produce_order_db/ServerFunc_GetProduceOrderList_dc',
  '/yuncut_produce_order_db/ServerFunc_GetProgramList_dc',
  '/yuncut_produce_order_db/ServerFunc_GetProduce',
  '/yuncut_produce_order_db/ServerFunc_GetProduce_Excel',
  '/yuncut_produce_order_db/ServerFunc_GetProduceOrderInfo',
  '/yuncut_produce_order_db/ServerFunc_AoKeWarranty',


  '/yuncut_produce_plan_db/ServerFunc_GetAoKeDelivery',
  '/yuncut_produce_plan_db/ServerFunc_GetAoKeDeliveryNote',
  '/yuncut_produce_plan_db/ServerFunc_GetAlloctOrderList_dc',
  '/yuncut_order_manage_db/ServerFunc_GetOrderList_All_dc',
  '/yuncut_delivery_db/ServerFunc_GetDeliveringList_dc',
  '/yuncut_delivery_db/ServerFunc_LogicalPushOfShipmentDocuments',
  '/yuncut_order_manage_db/ServerFunc_GetShippableOrder_dc',
  '/yuncut_balance_db/ServerFunc_SupplementPlanList',
  '/yuncut_balance_db/ServerFunc_SupplementPlanListSupplier',
  '/yuncut_balance_db/ServerFunc_SupplementPlantDetailByPlanId',
  '/yuncut_balance_db/ServerFunc_GetAlloctOrderDetailById',
  '/yuncut_balance_db/ServerFunc_GetAlloctOrderDetailByOrderId',
  '/yuncut_balance_db/ServerFunc_GetAlloctOrderList',
  '/yuncut_balance_db/ServerFunc_GetAlloctPartsByPlanId',
  '/yuncut_balance_db/ServerFunc_GetProducePlanListDelivered',
  '/yuncut_balance_db/ServerFunc_GetProducePlanListFactory',
  '/yuncut_balance_db/ServerFunc_GetProducePlanListProgam',
  '/yuncut_balance_db/ServerFunc_GetProducePlanListTask',
  '/yuncut_balance_db/ServerFunc_GetProducePlanListWarehouse',
  '/yuncut_balance_db/ServerFunc_GetProducePlanListWorkshop',
  '/yuncut_balance_db/ServerFunc_GetLantekProduceTask',
  '/yuncut_balance_db/ServerFunc_GetProducePlanList_BySupplier',
  '/yuncut_balance_db/ServerFunc_GetUnAcceptNum',
  '/yuncut_balance_db/ServerFunc_GetProducePartsByTaskId',
  '/yuncut_balance_db/ServerFunc_GetProduceTask_Operation',
  '/yuncut_balance_db/ServerFunc_GetProduceTaskById',
  '/yuncut_balance_db/ServerFunc_GetInvoice',
  '/yuncut_balance_db/ServerFunc_GetCollectionRecordSummaryDetailList',
  '/yuncut_balance_db/ServerFunc_GetCollectionRecordSummaryList',
  '/yuncut_balance_db/ServerFunc_GetPayRecordById',
  '/yuncut_balance_db/ServerFunc_GetPayRecordByOrderId',
  '/yuncut_balance_db/ServerFunc_GetPayRecordList',
  '/yuncut_balance_db/ServerFunc_GetPayRecod',
  '/yuncut_balance_db/ServerFunc_GetOrderReceivable',
  '/yuncut_balance_db/ServerFunc_GetProduceAllCompletedSettled',
  '/yuncut_balance_db/ServerFunc_GetProduceAllCompletedUnsettled',
  '/yuncut_balance_db/ServerFunc_GetSupplierProduceAllCompletedSettled',
  '/yuncut_balance_db/ServerFunc_GetSupplierProduceAllCompletedUnsettled',
  '/yuncut_material_customer_db/ServerFunc_GetCustomerInfo_Cus',
  '/yuncut_material_customer_db/ServerFunc_GetCustomerInfo_Ope',
  '/yuncut_material_customer_db/ServerFunc_AccordingCustomerIdGetDetail',
  '/yuncut_materialallot_db/ServerFunc_GetMaterialAllotDetail',
  '/yuncut_materialallot_db/ServerFunc_GetMaterialAllotList',
  '/yuncut_materialpurchase_db/ServerFunc_GetDeleteBeforePurchaseInfo',
  '/yuncut_materialpurchase_db/ServerFunc_GetMaterialPurchaseList',
  '/yuncut_materialpurchase_db/ServerFunc_GetMaterialPurchaseList_gys',
  '/yuncut_materialpurchase_db/ServerFunc_GetPurchaseById',
  '/yuncut_materialpurchase_db/ServerFunc_GetPurchaseDetailed',
  '/yuncut_materialpurchase_db/ServerFunc_GetPurchaseInner',
  '/yuncut_materialpurchase_db/ServerFunc_GetSupplierPurchaseInfoList',
  '/yuncut_materialpurchase_db/ServerFunc_UpdateMaterialWarranty',
  '/yuncut_materialsupplier_db/ServerFunc_CheckIsExite',
  '/yuncut_materialsupplier_db/ServerFunc_GetAllMaterialSupplier',
  '/yuncut_materialsupplier_db/ServerFunc_GetMaterialSupplierList',
  '/yuncut_materialwarehouse_db/ServerFunc_GetSpecificationList',
  '/yuncut_materialwarehouse_db/ServerFunc_GetSpecificationsSteelPlate',
  '/yuncut_materialwarehouse_db/ServerFunc_GetSteelPlateBySupplier',
  '/yuncut_materialwarehouse_db/ServerFunc_GetSteelPlateInfo',
  '/yuncut_materialwarehouse_db/ServerFunc_GetSurplusSummaryFactoryList',
  '/yuncut_materialwarehouse_db/ServerFunc_GetSurplusSummarySingleFactoryList',
  '/yuncut_materialwarehouse_db/ServerFunc_GetSteelPlateList',
  '/yuncut_materialwarehouse_db/ServerFunc_MaterialTotal_CustomerSupply',
  '/yuncut_materialwarehouse_db/ServerFunc_MaterialTotal_MT',
  '/yuncut_materialwarehouse_db/ServerFunc_MaterialTotal_Scrap',
  '/yuncut_materialwarehouse_db/ServerFunc_MaterialTotal_Surplus',
  '/yuncut_materialwarehouse_db/ServerFunc_GetMaterialPieChartStatistics',
  '/yuncut_materialwarehouse_db/ServerFunc_AccordingBatchIdGetSteelPlate',
  '/yuncut_materialwarehouse_db/ServerFunc_AccordingPlateIdGetScrapMaterialList',
  '/yuncut_materialwarehouse_db/ServerFunc_ArrcodingWarehouseIdGetPlateBatchList',
  '/yuncut_materialwarehouse_db/ServerFunc_GetAccordingSpecificationsPlateSheet',
  '/yuncut_materialwarehouse_db/ServerFunc_GetAccordingSpecificationsPlateSheet_v2',
  '/yuncut_materialwarehouse_db/ServerFunc_GetAvailableNumber',
  '/yuncut_materialwarehouse_db/ServerFunc_GetCustomerSupplyMaterialList',
  '/yuncut_materialwarehouse_db/ServerFunc_GetDispatchExportAvaiSteelPlate',
  '/yuncut_materialwarehouse_db/ServerFunc_GetScrapMaterialList',
  '/yuncut_materialwarehouse_db/ServerFunc_GetScrapMaterialList_All',
  '/yuncut_materialwarehouse_db/ServerFunc_GetSteelBatchCodeList',
  '/yuncut_materialwarehouse_db/ServerFunc_GetSteelPlateById',
  '/yuncut_materialwarehouse_db/ServerFunc_GetSteelPlateCustomer',
  '/yuncut_materialwarehouse_db/ServerFunc_GetSteelPlateForDetails',
  '/yuncut_materialwarehouse_db/ServerFunc_GetSteelPlateItemByBatchId',
  '/yuncut_materialwarehouse_db/ServerFunc_GetSteelPlateItemByDetailIds',
  '/yuncut_materialwarehouse_db/ServerFunc_GetSteelPlateItemByIdAndSteelPlateById',
  '/yuncut_materialwarehouse_db/ServerFunc_GetSteelPlateMaterial',
  '/yuncut_materialwarehouse_db/ServerFunc_GetSteelPlateMaterial_All',
  '/yuncut_materialwarehouse_db/ServerFunc_GetSteelPlateMaterial_gys',
  '/yuncut_materialwarehouse_db/ServerFunc_GetSteelPlateScrap',
  '/yuncut_materialwarehouse_db/ServerFunc_GetSurplusMaterial',
  '/yuncut_materialwarehouse_db/ServerFunc_GetSurplusMaterial_All',
  '/yuncut_materialwarehouse_db/ServerFunc_GetSurplusMaterial_gys',
  '/yuncut_materialwarehouse_db/ServerFunc_GetBatchCount',
  '/yuncut_materialwarehouse_db/ServerFunc_GetChoiceSteelPlateMaterial',
  '/yuncut_operationrecord_db/ServerFunc_GetOperationRecord',
  '/yuncut_operationrecord_db/ServerFunc_OperationType',
  '/yuncut_partsinout_db/ServerFunc_GetPartsStockInDetail',
  '/yuncut_partsinout_db/ServerFunc_GetPartsStockInItem',
  '/yuncut_partsinout_db/ServerFunc_GetPartsStockInList',
  '/yuncut_partsinout_db/ServerFunc_GetPartsStockOutDetail',
  '/yuncut_partsinout_db/ServerFunc_GetPartsStockOutItem',
  '/yuncut_partsinout_db/ServerFunc_GetPartsStockOutList',
  '/yuncut_partsinout_db/ServerFunc_partsStockOut_deliver',
  '/yuncut_partsinout_db/ServerFunc_InventoryPartsStockIn',
  '/yuncut_partsinout_db/ServerFunc_ProductStockIn_Inventory',
  '/yuncut_partsinout_db/ServerFunc_GetParts',
  '/yuncut_partsinout_db/ServerFunc_GetProductStockInSupplier',
  '/yuncut_partsinout_db/ServerFunc_GetProductStockOutSupplier',
  '/yuncut_partsinout_db/ServerFunc_GetProductStockInSupplier_Excel',
  '/yuncut_partsinout_db/ServerFunc_GetProductStockOutSupplier_Excel',
  '/yuncut_workreport_db/ServerFunc_GetPartsLibraryList',
  '/yuncut_workreport_db/ServerFunc_GetGetBadSparePartsList',
  '/yuncut_workreport_db/ServerFunc_GetWorkReportedInventoryList',
  '/yuncut_workreport_db/ServerFunc_QualityWorkReportedStorage',
  '/yuncut_workreport_db/ServerFunc_AddBatchWorkProcssWorkReport',
  '/yuncut_partswarehouse_db/ServerFunc_AccordingProductIdGetInfo',
  '/yuncut_partswarehouse_db/ServerFunc_GetOrderCode',
  '/yuncut_partswarehouse_db/ServerFunc_GetPartsStockById',
  '/yuncut_partswarehouse_db/ServerFunc_GetPartsStockByIds',
  '/yuncut_stockin_db/ServerFunc_ArrcodingWarehouseIdGetPlateBatchList',
  '/yuncut_stockin_db/ServerFunc_GetMaterialIneventoryData',
  '/yuncut_stockin_db/ServerFunc_GetMaterialStockInById',
  '/yuncut_stockin_db/ServerFunc_GetMaterialStockInItemList',
  '/yuncut_stockin_db/ServerFunc_GetMaterialStockInList',
  '/yuncut_stockin_db/ServerFunc_GetMaterialStockOutItemList',
  '/yuncut_stockin_db/ServerFunc_GetMaterialStockOutList',
  '/yuncut_surplusmaterial_db/ServerFunc_GetSpecificationList',
  '/yuncut_surplusmaterial_db/ServerFunc_GetSteelPlate',
  '/yuncut_surplusmaterial_db/ServerFunc_GetSteelPlateList',
  '/yuncut_surplusmaterial_db/ServerFunc_MaterialTotal',
  '/yuncut_erp_service/ServerFunc_ERP_InsertBOM_xml',
  '/yuncut_erp_service/ServerFunc_ERP_InsertConsignment_xml',
  '/yuncut_erp_service/ServerFunc_ERP_InsertCustomer_xml',
  '/yuncut_erp_service/ServerFunc_ERP_InsertInventory_xml',
  '/yuncut_erp_service/ServerFunc_ERP_InsertRdRecord_CGMtIn_xml',
  '/yuncut_erp_service/ServerFunc_ERP_InsertRdRecord08_xml',
  '/yuncut_erp_service/ServerFunc_ERP_InsertRdRecord08Customer_xml',
  '/yuncut_erp_service/ServerFunc_ERP_InsertRdRecord09_xml',
  '/yuncut_erp_service/ServerFunc_ERP_InsertSO_xml',
  '/yuncut_erp_service/ServerFunc_ERP_ProductChangexml',
  '/yuncut_erp_service/ServerFunc_GetMaterialAllotLog',
  '/yuncut_erp_service/ServerFunc_GetPurchaseInLogList',
  '/yuncut_erp_service/ServerFunc_GetCustomerInLogList',
  '/yuncut_erp_service/ServerFunc_GetMaterialOutLogList',
  '/yuncut_erp_service/ServerFunc_GetOMLogList',
  '/yuncut_erp_service/ServerFunc_GetScrapOutLogList',
  '/yuncut_erp_service/ServerFunc_GetSurplusInLogList',
  '/yuncut_erp_service/ServerFunc_GetSurplusOutLogList',
  '/yuncut_erp_service/ServerFunc_GetBomLogList',
  '/yuncut_erp_service/ServerFunc_GetCustomerLogList',
  '/yuncut_erp_service/ServerFunc_GetInventoryLogList',
  '/yuncut_erp_service/ServerFunc_GetOrderLogList',
  '/yuncut_erp_service/ServerFunc_GetDeliveryLogList',
  '/yuncut_erp_service/ServerFunc_GetProduceInLogList',
  '/yuncut_erp_service/ServerFunc_GetBadProduceInLogList',
  '/yuncut_erp_service/ServerFunc_GetAllWorkReportLog',
  '/yuncut_erp_service/ServerFunc_GetMassWorkReportLog',
  '/yuncut_erp_service/ServerFunc_GetNumberWorkReportLog',
  '/yuncut_erp_service/ServerFunc_NumberReportIdVerifyOM',
  '/yuncut_erp_service/ServerFunc_workReportIdVerifyOM',
  '/yuncut_erp_service/getServer',
  '/yuncut_erp_service/ServerFunc_GetThirdPartyExeList',
  '/yuncut_order_lantek/ServerFunc_GeneraterLanteWorkReport',
  '/yuncut_order_lantek/ServerFunc_GetLanteWorkReport',
  '/yuncut_order_lantek/Serverfunc_lantekDataSync',
  '/yuncut_delivery_db/ServerFunc_NotReceivedList',
  '/yuncut_delivery_db/ServerFunc_GetDeliveryRouteInfo',
  '/yuncut_delivery_db/ServerFunc_GetDeliveryRouteInfoById',
  '/yuncut_delivery_db/ServerFunc_AccordinglogisticsIdGetInfo',
  '/yuncut_delivery_db/ServerFunc_AccordingLogisticsIdGetInvoice',
  '/yuncut_delivery_db/ServerFunc_AccrodingDeliveryCodeGetLogistics',
  '/yuncut_delivery_db/ServerFunc_AccrodingLogisticsCodeGetLogistics',
  '/yuncut_delivery_db/ServerFunc_GetLogisticsInfos',
  '/yuncut_delivery_db/ServerFunc_GetNotLogistcsInfoDeliveryInfo',
  '/yuncut_delivery_db/ServerFunc_GetRouteInfos',
  '/yuncut_delivery_db/ServerFunc_GeneratePrintPage',
  '/yuncut_delivery_db/ServerFunc_GetDeliverByOrderCode',
  '/yuncut_delivery_db/ServerFunc_GetDeliveredCuspart',
  '/yuncut_delivery_db/ServerFunc_GetDeliveringList',
  '/yuncut_delivery_db/ServerFunc_GetDeliveryById',
  '/yuncut_delivery_db/ServerFunc_GetDeliveryById_Supplier',
  '/yuncut_delivery_db/ServerFunc_GetDeliveryDetail',
  '/yuncut_delivery_db/ServerFunc_GetDeliveryDetail_Supplier',
  '/yuncut_delivery_db/ServerFunc_GetDeliveryList',
  '/yuncut_delivery_db/ServerFunc_GetDeliveryList_Supplier',
  '/yuncut_delivery_db/ServerFunc_GetDeliveryListById',
  '/yuncut_delivery_db/ServerFunc_GetLogisticsInfo',
  '/yuncut_delivery_db/ServerFunc_GetReceivedList',
  '/yuncut_delivery_db/ServerFunc_GetReceivedListById',
  '/yuncut_delivery_db/ServerFunc_GetLogistics',
  '/yuncut_delivery_db/ServerFunc_GetLogisticsdetail',
  '/yuncut_delivery_db/ServerFunc_GetLogisticsDeliveryInfo',
  '/yuncut_delivery_db/ServerFunc_AddLogistics',
  '/yuncut_delivery_db/ServerFunc_GetTransport',
  '/yuncut_delivery_db/ServerFunc_AddTransport',
  '/yuncut_delivery_db/ServerFunc_DeleteTransport',
  '/yuncut_delivery_db/ServerFunc_UpdateTransport',
  '/yuncut_delivery_db/ServerFunc_GetTransportById',
  '/yuncut_supplier_db/ServerFunc_GetContact',
  '/yuncut_supplier_db/ServerFunc_GetSupplierShelfList',
  '/yuncut_supplier_db/ServerFunc_GetSupplierWarehouseList',
  '/yuncut_supplier_db/ServerFunc_GetWarehouseInfoByCode',
  '/yuncut_supplier_db/ServerFunc_GetWarehouseType',
  '/yuncut_supplier_db/ServerFunc_CanDistributeSupplier',
  '/yuncut_supplier_db/ServerFunc_CanDistributeSupplier_v2',
  '/yuncut_supplier_db/ServerFunc_CanDistributeSupplier_1',
  '/yuncut_supplier_db/ServerFunc_FilterCanDistributeSupplier_2',
  '/yuncut_supplier_db/ServerFunc_GetAllSupplier',
  '/yuncut_supplier_db/ServerFunc_GetAllSupplier_sup',
  '/yuncut_supplier_db/ServerFunc_GetSupplier',
  '/yuncut_supplier_db/ServerFunc_GetSupplierList',
  '/yuncut_supplier_db/ServerFunc_GetFactory',
  '/yuncut_supplier_db/ServerFunc_CheckIsExitePrice',
  '/yuncut_supplier_db/ServerFunc_GetSupplierCutUnitPrice',
  '/yuncut_supplier_db/ServerFunc_CheckIsExiteCutMethod',
  '/yuncut_supplier_db/ServerFunc_GetSupplierCutMethod',
  '/yuncut_supplier_db/ServerFunc_CanDistributeEquip',
  '/yuncut_supplier_db/ServerFunc_GetCurrentSupplierEquip',
  '/yuncut_supplier_db/ServerFunc_GetSupplierPayterm',
  '/yuncut_supplier_db/ServerFunc_GetEquip',
  '/yuncut_supplier_db/ServerFunc_GetSupplierAllInfo',
  '/yuncut_supplier_db/ServerFunc_CheckSupAccount',
  '/yuncut_supplier_db/ServerFunc_GetSupAccountByAccount',
  '/yuncut_supplier_db/ServerFunc_GetSupplierAccountInfo',
  '/yuncut_supplier_db/ServerFunc_GetSupAccountById',
  '/yuncut_supplier_db/ServerFunc_GetAccountContact',
  '/yuncut_supplier_db/ServerFunc_GetSupSonAccount',
  '/yuncut_supplier_db/ServerFunc_GetAllSupplierOption',
  '/yuncut_supplier_login/ServerFunc_Supplier_GetLoginAccountInfo',
  '/yuncut_supplier_login/ServerFunc_Supplier_GetLoginCustomerInfo',
  '/yuncut_supplier_login/ServerFunc_Supplier_GetLoginInfo',
  '/yuncut_workreport_db/ServerFunc_GetAvailableSteelPlate',
  '/yuncut_workreport_db/ServerFunc_GetMassWorkReportedByCodes',
  '/yuncut_workreport_db/ServerFunc_GetMassWorkReportedById',
  '/yuncut_workreport_db/ServerFunc_GetMassWorkReportedDetailed',
  '/yuncut_workreport_db/ServerFunc_GetMassWorkReportedList',
  '/yuncut_workreport_db/ServerFunc_GetOrderInfo',
  '/yuncut_workreport_db/ServerFunc_GetWorkReportedInfo',
  '/yuncut_workreport_db/ServerFunc_GetWorkReportedList',
  '/yuncut_workreport_db/ServerFunc_GetWorkReportedPartsById',
  '/yuncut_workreport_db/ServerFunc_GetProcessReportPartsByOrderIds',
  '/yuncut_workreport_db/ServerFunc_GetGetBadSparePartsList',
  '/yuncut_workreport_db/ServerFunc_GetPartsLibraryList',
  '/yuncut_workreport_db/ServerFunc_GetPartsLibraryList_All',
  '/yuncut_workreport_db/ServerFunc_GetPartsLibraryListSupplier',
  '/yuncut_workreport_db/ServerFunc_GetPartsTotal',
  '/yuncut_workreport_db/ServerFunc_GetProductsList',
  '/yuncut_workreport_db/ServerFunc_GetSalesOrderExecution',
  '/yuncut_workreport_db/ServerFunc_GetSalesOrderExecution_Excel',
  '/yuncut_workreport_db/ServerFunc_GetScrapPurchase_Excel',
  '/yuncut_workreport_db/ServerFunc_GetScrapPurchaseDetailed',
  '/yuncut_workreport_db/ServerFunc_GetScrapPurchase',
  '/yuncut_base_settings_db/ServerFunc_GetInterfaceList',
  '/yuncut_base_settings_db/ServerFunc_GetGlobalQueryTimeInterval',
  '/yuncut_base_settings_db/ServerFunc_GetGlobalSetting',
  '/yuncut_base_settings_db/ServerFunc_GetSystemOperationLogList',
  '/yuncut_base_settings_db/ServerFunc_GetSystemAnnouncementById',
  '/yuncut_base_settings_db/ServerFunc_GetSystemAnnouncementList',
  '/yuncut_base_settings_db/ServerFunc_GetSystemAnnouncementList_Oper',
  '/yuncut_base_settings_db/ServerFunc_GetSystemAnnouncementList_Supplier',
  '/yuncut_base_settings_db/ServerFunc_GetSystemAnnouncementList_User',
  '/yuncut_base_settings_db/ServerFunc_GetDefaultPageSize',
  '/yuncut_base_settings_db/ServerFunc_GetDefaultPassword',
  '/yuncut_base_settings_db/ServerFunc_GetDefaultTaxRate',
  '/common_certcode_service/ServerFunc_IsNeedImageCertCode',
  '/common_certcode_service/ServerFunc_CheckCertCode',
  '/common_certcode_service/ServerFunc_CheckImageCertCode',
  '/common_certcode_service/ServerFunc_GetImageCertCodeUrl',
  '/common_formula_calc_default/ServerFunc_SyntaxTreeParse',
  '/common_formula_calc_default/ServerFunc_ExportFactorExcel',
  '/common_formula_calc_default/ServerFunc_ExportFormulaDefineTreeExcel',
  '/common_formula_calc_default/ServerFunc_ExportFormulaDefineExcel',
  '/common_formula_calc_default/ServerFunc_GetFactorDefine',
  '/common_formula_calc_default/ServerFunc_GetFactorDefineById',
  '/common_formula_calc_default/ServerFunc_GetFormulaDefine',
  '/common_formula_calc_default/ServerFunc_GetFormulaDefineTree',
  '/common_formula_calc_default/ServerFunc_GetFormulaDefineTreeById',
  '/common_map_service/ServerFunc_GetDistrictByIP',
  '/common_map_service/ServerFunc_GetChinaDistricts',
  '/common_map_service/ServerFunc_GetDistrictPoint',
  '/common_map_service/ServerFunc_GetDistanceTrucks',
  '/common_map_service/ServerFunc_GetDistanceTrucks_assignPoint',
  '/iems_oss_aliyun_record_db/ServerFunc_ModifyRecordFileType',
  '/iems_oss_aliyun_record_db/ServerFunc_ModifyRecordFileTypeSingle',
  '/yuncut_administrativeareas_db/ServerFunc_GetAdministrativeAreas_City',
  '/yuncut_administrativeareas_db/ServerFunc_GetAdministrativeAreas_Distric',
  '/yuncut_administrativeareas_db/ServerFunc_GetAdministrativeAreas_Province',
  '/yuncut_administrativeareas_db/ServerFunc_GetDistricCenter',
  '/yuncut_calc_service/ServerFunc_CalcByAfterTaxMoney',
  '/yuncut_customer_login/ServerFunc_Customer_GetLoginAccountInfo',
  '/yuncut_customer_login/ServerFunc_Customer_GetLoginCustomerInfo',
  '/yuncut_customer_login/ServerFunc_Customer_GetLoginInfo',
  '/yuncut_customer_permission_setting_db/ServerFunc_GetCusPermissionSettingsDetail',
  '/yuncut_customer_permission_setting_db/ServerFunc_GetDefaultRole',
  '/yuncut_customer_permission_setting_db/ServerFunc_GetRolePermissions',
  '/yuncut_operation_permission_setting_db/ServerFunc_GetRoleList',
  '/yuncut_operation_permission_setting_db/ServerFunc_GetPermissionById',
  '/yuncut_operation_permission_setting_db/ServerFunc_GetPermissionList_ExcludeRole',
  '/yuncut_operation_permission_setting_db/ServerFunc_GetPermissionList',
  '/yuncut_operation_permission_setting_db/ServerFunc_GetRolePermissions',
  '/yuncut_operation_permission_setting_db/ServerFunc_GetPermissionDetail',
  '/yuncut_operation_permission_setting_db/ServerFunc_GetPermissionDetailById',
  '/yuncut_operation_permission_setting_db/ServerFunc_GetPermissionDetailById_ExcludePer',
  '/yuncut_operation_permission_setting_db/ServerFunc_GetPermissionDetailById_ExcludeRole',
  '/yuncut_operation_permission_setting_db/ServerFunc_GetRolePermissionsDetailById',
  '/yuncut_operation_permission_setting_db/ServerFunc_GetRolePermissionsDetail',
  '/yuncut_supplier_permission_setting_db/ServerFunc_GetDefaultRole',
  '/yuncut_sys_headportrait_db/ServerFunc_GetSysDefaultHeadPortrait',
  '/yuncut_weight_setting/ServerFunc_GetWeightDefine',
  '/yuncut_weight_setting/ServerFunc_GetWeightTypeDefine',
  '/yuncut_operation_db/ServerFunc_CheckOpeAccount',
  '/yuncut_operation_db/ServerFunc_GetExclusiveManagerInfo',
  '/yuncut_operation_db/ServerFunc_GetOpeAccount',
  '/yuncut_operation_db/ServerFunc_GetOpeAccountByAccount',
  '/yuncut_operation_db/ServerFunc_GetOpeAccountById',
  '/yuncut_operation_login/ServerFunc_Operation_GetLoginInfo',
  '/yuncut_statistics_db/ServerFunc_ExecuteDictionCodeGetJson',
  '/yuncut_statistics_db/ServerFunc_ExecuteTemplteSqlGetJson',
  '/yuncut_statistics_db/ServerFunc_GetDictionarieByCateGory',
  '/yuncut_statistics_db/ServerFunc_GetDictionaries',
  '/yuncut_statistics_db/ServerFunc_GetDictionariesByTemplateId',
  '/yuncut_statistics_db/ServerFunc_GetRelationByTemplate',
  '/yuncut_statistics_db/ServerFunc_GetStatisticalTemplate',
  '/yuncut_statistics_db/ServerFunc_GetTemplateCategory',
  '/yuncut_statistics_db/ServerFunc_GetTemplateBySupplierOverview',
  '/yuncut_statistics_db/ServerFunc_GetTemplateList',
  '/module-operation!executeOperation',
  '/module-operation!executeOperation',
  '/yuncut_partspurchase_db/ServerFunc_GetOrderParts',
  '/yuncut_partspurchase_db/ServerFunc_GetPartsStock',
  '/yuncut_partspurchase_db/ServerFunc_DeletePartspurchase',
  '/yuncut_partspurchase_db/ServerFunc_GetPurchaseDetailed',
  '/yuncut_partspurchase_db/ServerFunc_GetPartsPurchaseList',
  '/yuncut_partspurchase_db/ServerFunc_GetPartsPurchaseInner',
  '/yuncut_partspurchase_db/ServerFunc_AddPartsPurchase',
  '/yuncut_partspurchase_db/ServerFunc_GetPartsPurchaseListByOrderCode',
  '/yuncut_materialwarehouse_db/ServerFunc_GetSteelPlateScrap_gys_V2',
   //平台二期接口重复
  '/yuncut_produce_plan_db/ServerFunc_SupplementPlanList',
  '/yuncut_produce_plan_db/ServerFunc_GetUnAcceptNum',
  '/yuncut_produce_plan_db/ServerFunc_GetReviewedtNum',
  '/yuncut_produce_plan_db/ServerFunc_SupplementPlanListSupplier',
  '/yuncut_produce_plan_db/ServerFunc_SupplementPlantDetailByPlanId',
  '/yuncut_produce_plan_db/ServerFunc_GetAlloctOrderDetailById',
  '/yuncut_produce_plan_db/ServerFunc_GetAlloctOrderDetailByOrderId',
  '/yuncut_produce_plan_db/ServerFunc_GetAlloctOrderList',
  '/yuncut_produce_plan_db/ServerFunc_GetAlloctPartsByPlanId',
  '/yuncut_produce_plan_db/ServerFunc_GetProducePlanListDelivered',
  '/yuncut_produce_plan_db/ServerFunc_GetProducePlanListFactory',
  '/yuncut_produce_plan_db/ServerFunc_GetProducePlanListProgam',
  '/yuncut_produce_plan_db/ServerFunc_GetProducePlanListTask',
  '/yuncut_produce_plan_db/ServerFunc_GetProducePlanListWarehouse',
  '/yuncut_produce_plan_db/ServerFunc_GetProducePlanListWorkshop',
  '/yuncut_produce_plan_db/ServerFunc_GetLantekProduceTask',
  '/yuncut_produce_plan_db/ServerFunc_GetProducePlanList_BySupplier',
  '/yuncut_produce_plan_db/ServerFunc_GetUnAcceptNum',
  '/yuncut_produce_plan_db/ServerFunc_GetProducePartsByTaskId',
  '/yuncut_produce_plan_db/ServerFunc_GetProduceTask_Operation',
  '/yuncut_invoice_manage_db/ServerFunc_GetInvoice',
  '/yuncut_invoice_manage_db/ServerFunc_GetInvoice',
  '/yuncut_customer_db/ServerFunc_GetCustomer_Ope',
  '/pdf/practice/',
  '/pdf/practice2',
  '/api/CommonFile',
  '/api/nest',
  '/yuncut_workreport_db/ServerFunc_GetEnclosureByProgramId',
  '/yuncut_stockin_db/ServerFunc_GetMaterialStockInOutRecord',
  '/yuncut_stockin_db ServerFunc_GetMaterialStockOutItemList',
  '/yuncut_order_manage_db ServerFunc_GetOrderList_All_dc',
  '/yuncut_customer_db/ServerFunc_GetInvoice',
  '/yuncut_produce_plan_db/ServerFunc_GetPartsPlanDetail',
  '/yuncut_delivery_db/ServerFunc_GetReturnableParts',
  '/yuncut_delivery_db/ServerFunc_GetReturnGoodsInfoList',
  '/yuncut_delivery_db/ServerFunc_GetAllDelivery',
  '/yuncut_delivery_db/ServerFunc_GetMergeDocuments',
  '/yuncut_delivery_db/ServerFunc_GetReturnGoodsInfoDetail',
  '/yuncut_delivery_db/ServerFunc_GetReturnableOrder',
  '/yuncut_delivery_db/ServerFunc_GetMergeDocuments',
  '/yuncut_delivery_db/ServerFunc_GetMergeDocumentsExcel',
  '/yuncut_produce_order_db/ServerFunc_UpdateProgram',
  '/yuncut_produce_plan_db/ServerFunc_GetProduceTaskById',
  '/yuncut_pricemgr_db/ServerFunc_SaveMaterialPrice',
  '/yuncut_customer_db/ServerFunc_GetDefaultAddr',
  '/yuncut_customer_db/ServerFunc_GetCustomer',
  '/yuncut_customer_db/ServerFunc_GetAddr',
  '/yuncut_customer_period/ServerFunc_GetCusAgreementList',
  '/yuncut_customer_period/ServerFunc_GetErpAgreementList',
  '/yuncut_invoice_manage_db/ServerFunc_ERP_Sync_Invoice',
  '/yuncut_invoice_manage_db/ServerFunc_GetInvoiceDetailed',
  '/yuncut_invoice_manage_db/ServerFunc_GetInvoiceList',
  '/yuncut_invoice_manage_db/ServerFunc_GetInvoiceDetailedOperate',
  '/yuncut_invoice_manage_db/ServerFunc_GetInvoiceListOperate',
  '/yuncut_finance_db/ServerFunc_AddPayment',
  '/yuncut_finance_db/ServerFunc_CancelPayment',
  '/yuncut_finance_db/ServerFunc_GetPaymentPlanInfo',
  '/yuncut_finance_db/ServerFunc_GetPaymentPlanList',
  '/yuncut_finance_db/ServerFunc_PaymentDetailed',
  '/yuncut_finance_db/ServerFunc_PaymentStatistics',
  '/yuncut_finance_db/ServerFunc_SavePaymentOrder',
  '/yuncut_finance_db/ServerFunc_UpdatePaymentMoney',
  '/yuncut_finance_db/ServerFunc_UpdatePaymentRemark',
  '/yuncut_finance_db/ServerFunc_GetAdvanceMoney',
  '/yuncut_finance_db/ServerFunc_CheckIsOffset',
  '/yuncut_finance_db/ServerFunc_GetBeforehandMoneyInfo',
  '/yuncut_finance_db/ServerFunc_RevokeOffset',
  '/yuncut_finance_db/ServerFunc_ConfirmPayment',
  '/yuncut_finance_db/ServerFunc_OfflinePayment',
  '/yuncut_finance_db/ServerFunc_GetYmambGrossProfit',
  '/yuncut_finance_db/ServerFunc_GetYmambGrossProfitExcel',
  '/yuncut_finance_db/ServerFunc_UpdateGrossProfit',
  '/yuncut_finance_db/ServerFunc_GetYmambGrossProfit_Year',
  '/yuncut_finance_db/ServerFunc_GetYmAmbcDepName',
  '/yuncut_finance_db/ServerFunc_GetYmambGrossProfit_Department',
  '/yuncut_finance_db/ServerFunc_GetYmambGrossProfitById',
  '/yuncut_finance_db/ServerFunc_GetYMambReportChart',
  '/yuncut_order_manage_db/ServerFunc_GetPaidOrderList_Cus',
  '/yuncut_order_manage_db/ServerFunc_UpdateOrderDeposit',
  '/yuncut_order_manage_db/ServerFunc_GetOrderBalancePayable',
  '/yuncut_order_manage_db/ServerFunc_CheckSurplusPaymentPayable',
  '/yuncut_order_manage_db/ServerFunc_GenerateOrdersDeposit',
  '/yuncut_order_manage_db/ServerFunc_NewProgressPayment',
  '/yuncut_pay_db/ServerFunc_GetPaySite',
  '/yuncut_pay_db/ServerFunc_SetPayService',
  '/yuncut_pay_db/ServerFunc_Pay_Query',
  '/yuncut_pay_db/ServerFunc_PaymentOrder_Query',
  '/yuncut_pay_db/ServerFunc_GetPaymentOrderResult',
  '/yuncut_pay_db/ServerFunc_GetPaymentPlanResult',
  '/yuncut_pay_db/ServerFunc_PayOrder',
  '/yuncut_pay_db/ServerFunc_PayPaymentPlan',
  '/yuncut_pay_db/ServerFunc_Refund',
  '/yuncut_customer_period/ServerFunc_GetCusAgreementList',
  '/yuncut_customer_period/ServerFunc_GetAllAgreement',
  '/yuncut_customer_period/ServerFunc_SyncErpAgreementData',
  '/yuncut_customer_period/ServerFunc_GetCusAgreementInfo',
  '/yuncut_customer_period/ServerFunc_GetErpAgreementList',
  '/yuncut_balance_db/ServerFunc_GetSettlementDetailed',
  '/yuncut_balance_db/ServerFunc_GetSettlementList',
  '/yuncut_customer_db/ServerFunc_UpdateDepositProportion',
  '/yuncut_customer_ledger_db/ServerFunc_GetCusAccountBalance',
  '/yuncut_customer_ledger_db/ServerFunc_UpdateCusAccountBalance',
  '/yuncut_erp_service/ServerFunc_ERP_InsertReceipt',
  '/yuncut_erp_service/ServerFunc_Sync_InsertReceipt',
  '/yuncut_receipt_db/ServerFunc_SaveReceipt',
  '/yuncut_order_manage_db/ServerFunc_GetOrder_WorkStage',
  '/yuncut_order_manage_db/ServerFunc_GetParts_WorkStage',
  '/yuncut_order_manage_db/ServerFunc_GetPartsWortStage',
  '/yuncut_workstage_order_db/ServerFunc_AddWorkStageOrder',
  '/yuncut_workstage_order_db/ServerFunc_RenewWorkStageOrder',
  '/yuncut_workstage_order_db/ServerFunc_UpdateWorkStageOrderStatus',
  '/yuncut_workstage_order_db/ServerFunc_GetWorkStageOrderList',
  '/yuncut_workstage_order_db/ServerFunc_GetWorkStageOrderDetail',
  '/yuncut_workstage_order_db/ServerFunc_GetWorkStageOrderListExport',
  '/yuncut_workstage_order_db/ServerFunc_BatchAllocationWorkStageOrder',
  '/yuncut_workstage_order_db/ServerFunc_BatchAllocationWorkStageOrderParts',
  '/yuncut_inquire_db/ServerFunc_OfferProcedure',
  '/yuncut_inquire_db/ServerFunc_GetProcedureMoney',
  'yuncut_inquire_db/ServerFunc_InquiryDetails',
  '/yuncut_administrativeareas_db/ServerFunc_GetAdministrativeAreas_Province',
  '/yuncut_inquire_db/ServerFunc_InquiryDetails',
  '/yuncut_centralwarehouse_inventory_db/ServerFunc_GetMaterialStockList_Guest',
  '/yuncut_centralwarehouse_inventory_db/ServerFunc_MaterialStock_Statistics_Guest',
  '/yuncut_centralwarehouse_inventory_db/ServerFunc_GetMaterialStockList_YunC',
  '/yuncut_centralwarehouse_inventory_db/ServerFunc_MaterialStock_Statistics_YunC',
  '/yuncut_centralwarehouse_inventory_db/ServerFunc_MaterialStock_Print_YunC',
  '/yuncut_centralwarehouse_inventory_db/ServerFunc_MaterialStock_Print_Guest',
  '/yuncut_centralwarehouse_inventory_db/ServerFunc_MaterialStock_Export_YunC',
  '/yuncut_centralwarehouse_inventory_db/ServerFunc_MaterialStock_Export_Guest',
  '/yuncut_centralwarehouse_inventory_db/ServerFunc_GetMaterialStock_Mark',
  '/yuncut_materialpurchase_db/ServerFunc_CheckContractNo',
  '/yuncut_centralwarehouse_inventory_db/ServerFunc_GetStockDetail',
  '/yuncut_centralwarehouse_inventory_db/ServerFunc_GetSteelPlateBySupplier',
  '/yuncut_centralwarehouse_inventory_db/ServerFunc_GetStockByWarehouseId',
  '/yuncut_centralwarehouse_inventory_db/ServerFunc_MaterialSplit',
  '/yuncut_centralwarehouse_inventory_db/ServerFunc_MaterialStock_Freed',
  '/yuncut_centralwarehouse_inventory_db/ServerFunc_MaterialStock_Input',
  '/yuncut_centralwarehouse_inventory_db/ServerFunc_MaterialStock_Mark',
  '/yuncut_centralwarehouse_inventory_db/ServerFunc_MaterialStock_Occupy',
  '/yuncut_centralwarehouse_inventory_db/ServerFunc_MaterialStock_Out',
  '/yuncut_centralwarehouse_inventory_db/ServerFunc_MaterialStock_Receiving',
  '/yuncut_centralwarehouse_inventory_db/ServerFunc_MaterialStock_Transfer',
  '/yuncut_centralwarehouse_inventory_db/ServerFunc_SaveStockPeriod',
  '/yuncut_centralwarehouse_inventory_db/ServerFunc_WorkBeforeVerifyPlateBatchNumber',
  '/yuncut_centralwarehouse_stockrecord_db/ServerFunc_GetStockOutList',
  '/yuncut_centralwarehouse_stockrecord_db/ServerFunc_GetStockOutInfo',
  '/yuncut_centralwarehouse_stockrecord_db/ServerFunc_GetStockOut',
  '/yuncut_centralwarehouse_stockrecord_db/ServerFunc_GetStockOut_Dc',
  '/yuncut_centralwarehouse_stockrecord_db/ServerFunc_GetStockIn',
  '/yuncut_centralwarehouse_stockrecord_db/ServerFunc_GetStockInInfo',
  '/yuncut_centralwarehouse_stockrecord_db/ServerFunc_GetStockInList',
  '/yuncut_centralwarehouse_stockrecord_db/ServerFunc_GetStockIn_Dc',
  '/yuncut_centralwarehouse_stockrecord_db/ServerFunc_GetMaterialInOutList',
  '/yuncut_centralwarehouse_stockrecord_db/ServerFunc_GetMaterianInOutInfo',
  '/yuncut_centralwarehouse_stockrecord_db/ServerFunc_GetMaterialInOut_Dc',
  '/yuncut_centralwarehouse_stockrecord_db/ServerFunc_GetMaterialIneventoryData',
  '/yuncut_centralwarehouse_stockrecord_db/ServerFunc_materialStockInInventory',
  '/yuncut_centralwarehouse_stockrecord_db/ServerFunc_steelPlateStockOut_Inventory',
  '/yuncut_centralwarehouse_stockrecord_db/ServerFunc_materialStockInInventoryNotStock',
  '/yuncut_centralwarehouse_purchase_db/ServerFunc_AddPurchase',
  '/yuncut_centralwarehouse_purchase_db/ServerFunc_DeletePurchase',
  '/yuncut_centralwarehouse_purchase_db/ServerFunc_GetPurchaseList',
  '/yuncut_centralwarehouse_purchase_db/ServerFunc_GetPurchaseInfo',
  '/yuncut_centralwarehouse_purchase_db/ServerFunc_PurchaseStockIn',
  '/yuncut_centralwarehouse_purchase_db/ServerFunc_AddStockInCustomer',
  '/yuncut_centralwarehouse_purchase_db/ServerFunc_GetPurchaseList_Dc',
  '/yuncut_centralwarehouse_purchase_db/ServerFunc_GetPurchase',
  '/yuncut_centralwarehouse_db/ServerFunc_AddCentralWarehouse',
  '/yuncut_centralwarehouse_db/ServerFunc_DeleteCentralWarehouse',
  '/yuncut_centralwarehouse_db/ServerFunc_AddSaveFactory',
  '/yuncut_centralwarehouse_db/ServerFunc_DeleteCentralFactory',
  '/yuncut_centralwarehouse_db/ServerFunc_GetAllSuppliers',
  '/yuncut_centralwarehouse_db/ServerFunc_GetFactory',
  '/yuncut_centralwarehouse_db/ServerFunc_GetWarehouseInfo',
  '/yuncut_centralwarehouse_db/ServerFunc_GetCentralWarehouse',
  '/yuncut_centralwarehouse_db/ServerFunc_GetCentralFactory',
  '/yuncut_centralwarehouse_report_db/ServerFunc_GetCentralInventoryReport',
  '/yuncut_centralwarehouse_report_db/ServerFunc_GetCentralInventoryReport_Excel',
  '/yuncut_centralwarehouse_db/ServerFunc_GetCentralFactory',
  '/yuncut_centralwarehouse_allot_db/ServerFunc_GetCentralAllotList',
  '/yuncut_centralwarehouse_allot_db/ServerFunc_GetCentralAllotDetail',
  '/yuncut_centralwarehouse_allot_db/ServerFunc_AddCentralAllot',
  '/yuncut_materialpurchase_db/ServerFunc_GetSalesOrderDetail',
  // 三期接口
  '/yuncut_knowledge_db/ServerFunc_GetKnowledgeArticleList',
  '/yuncut_knowledge_db/ServerFunc_GetKnowledgeType',
  '/yuncut_knowledge_db/ServerFunc_GetTrain',
  '/yuncut_quality_db/ServerFunc_GetSpotCheck',
  '/yuncut_knowledge_db/ServerFunc_GetKnowledgeArticleSupplier',
  '/yuncut_quality_db/ServerFunc_GetSpotCheckSupplier',
  '/yuncut_knowledge_db/ServerFunc_GetTrainSupplier',
  '/yuncut_produce_plan_db/ServerFunc_GetAlloctOrderListControl',
  '/yuncut_customer_db/ServerFunc_SetMessage',
  '/yuncut_customer_db/ServerFunc_GetCustomer_Ope_List',
  '/yuncut_knowledge_db/ServerFunc_GetKnowledgeArticleCustomer',
  '/yuncut_knowledge_db/ServerFunc_GetKnowledgeArticleById',
  '/yuncut_knowledge_db/ServerFunc_GetRelevantArticle',
  '/yuncut_weixin_api/ServerFunc_GetQRCode_Configure',
  '/yuncut_weixin_api/ServerFunc_ScanCodeLogin_Cus',
  '/yuncut_weixin_api/ServerFunc_RegisterBySCode_Cus',
  '/yuncut_knowledge_db/ServerFunc_GetHotSearchArticles',
  '/yuncut_knowledge_db/ServerFunc_IsLoginShow',
  '/yuncut_knowledge_db/ServerFunc_GetArticleByIdCus',
  '/yuncut_workreport_db/ServerFunc_GetScrapPurchase',
  '/yuncut_workreport_db/ServerFunc_GetScrapPurchase_Excel',
  '/yuncut_workreport_db/ServerFunc_GetScrapPurchaseDetailed',

  //售后
  '/yuncut_postsale_db/ServerFunc_GetPostSaleList',
  '/yuncut_claim_db/ServerFunc_GetAllClaimsList',
  '/yuncut_postsale_db/ServerFunc_GetPostSaleList_Supplier',
  '/yuncut_claim_db/ServerFunc_GetClaimsListSupplier',
  '/yuncut_order_manage_db/ServerFunc_GetRepairablePart_gys',
  '/yuncut_claim_db/ServerFunc_GetIssueReport',
  '/yuncut_postsale_db/ServerFunc_GetPostSaleItem',
  '/yuncut_postsale_db/ServerFunc_GetPostSaleInfo',
  '/yuncut_postsale_db/ServerFunc_GetPostSaleParts',
  '/yuncut_postsale_db/ServerFunc_GetStateQty_sp',
  '/yuncut_postsale_db/ServerFunc_GetStateQty_cus',
  '/yuncut_postsale_db/ServerFunc_GetStateQty',
  '/yuncut_postsale_db/ServerFunc_SupplierAccept',
  '/yuncut_postsale_db/ServerFunc_GetStateQty_PostSaleList',
  '/yuncut_claim_db/ServerFunc_GetIssueReportList',
  '/yuncut_claim_db/ServerFunc_GetIssueReport_gys',
  '/yuncut_claim_db/ServerFunc_UpdateIssueReport',
  '/yuncut_postsale_db/ServerFunc_GetPostSaleList_Customer',
  '/yuncut_claim_db/ServerFunc_GetIssueReportList_op',
  '/yuncut_claim_db/ServerFunc_GetIssueReportListDetail_op',
  '/yuncut_finance_db/ServerFunc_GetCustomer',
  '/yuncut_postsale_db/ServerFunc_GetPostSaleBadStandingBook',
  '/yuncut_postsale_db/ServerFunc_GetPostSaleBadStandingBookdc',
  '/yuncut_postsale_db/ServerFunc_DeletePostSaleBadStandingBook',
  '/yuncut_postsale_db/ServerFunc_AddPostSaleBadStandingBook',
  '/yuncut_postsale_db/ServerFunc_GetPostSaleBadRate',
  '/yuncut_postsale_db/ServerFunc_AddPostSaleBadRate',
  '/yuncut_postsale_db/ServerFunc_UpdatePostSaleBadRate',
  '/yuncut_postsale_db/ServerFunc_GetPostSaleBadRate_Excel',
  '/yuncut_postsale_db/ServerFunc_UpdatePostSaleBadStandingBook',
  '/yuncut_postsale_db/ServerFunc_GetPostSaleBadRateFactory',
  '/yuncut_postsale_db/ServerFunc_AddPostSaleBadRateFactory',
  '/yuncut_postsale_db/ServerFunc_UpdatePostSaleBadRateFactory',
  '/yuncut_postsale_db/ServerFunc_GetPostSaleBadRateFactoryId',
  '/yuncut_postsale_db/ServerFunc_GetPostSaleBadRateFactoryIdAll',
  '/yuncut_base_defectcategory/ServerFunc_GetDefectCategory',
  '/yuncut_base_defectcategory/ServerFunc_GetDefectCategoryPage',
  '/yuncut_base_defectcategory/ServerFunc_UpdateDefectCategoryEnable',

  //分组
  '/yuncut_supplier_permission_setting_db/ServerFunc_GetGroup',
  '/yuncut_supplier_permission_setting_db/ServerFunc_GetPermissionSetting',
  '/yuncut_supplier_permission_setting_db/ServerFunc_GetGroupPermission',
  '/yuncut_supplier_permission_setting_db/ServerFunc_GetOption_SupplierRole',
  '/yuncut_supplier_permission_setting_db/ServerFunc_GetPermissionSetting_Sup',
  '/yuncut_supplier_permission_setting_db/ServerFunc_GetRolePermission',
  '/yuncut_supplier_permission_setting_db/ServerFunc_GetRoleMember',
  '/yuncut_supplier_permission_setting_db/ServerFunc_GetGroupMember',
  '/yuncut_supplier_permission_setting_db/ServerFunc_GetSupPermission',
  '/yuncut_supplier_permission_setting_db/ServerFunc_CopyGroup',
  '/yuncut_supplier_permission_setting_db/ServerFunc_UpdateGroup',
  '/yuncut_supplier_permission_setting_db/ServerFunc_InsertGroup',
  '/ydg_open_ys7_db/ServerFunc_OpenYs7_GetCameraInfo',
  '/yuncut_supplier_db/ServerFunc_GetSupplierV2',

  //订单变更
  '/yuncut_order_change_db/getOrderChangePart',
  '/yuncut_order_change_db/getOrderChange',
  '/yuncut_order_change_db/getApplyOrderChange',
  '/yuncut_order_change_db/getApplyOrderChange_Excel',
  '/yuncut_order_change_db/getOrderChangeQuotedPrice',
  '/yuncut_order_change_db/getApplyOrderChangePart',
  '/yuncut_order_change_db/getOrderChangePartOrigin',
  '/yuncut_order_change_db/getOrderChangePartPlan',
  '/yuncut_order_change_db/getOrderChangeStateNumber',
  '/yuncut_order_change_db/getOrderChangeApproval',
  '/yuncut_order_change_db/getOrderChangeStateNumber_cus',
  '/yuncut_order_change_db/ServerFunc_GetMaterialChangeReport',

  '/yuncut_factorywarehouse_db/ServerFunc_GetFactoryWarehouse_DropDown',
  '/yuncut_order_change_db/getOrderChangeInfo',
  '/yuncut_cusparts_datum_db/ServerFunc_GetDatumVersionByCode',

  '/yuncut_delivery_db/ServerFunc_GetDeliveryListByIdOrCode',
  '/yuncut_finance_db/getoffsetInvoiceInfo',
  '/yuncut_finance_db/getOffsetInvoicePaymentInfo',
  '/yuncut_order_change_db/updateOrderChangePartsPriceOriginal',

  //工厂自营查询方法
  '/yuncut_factory_base/ServerFunc_UpDocmenNo_Auto',
  '/yuncut_factory_base/ServerFunc_GetOrderSales',
  '/yuncut_factory_base/ServerFunc_GetINtoTypeOption',
  '/yuncut_factoryspareparts_db/ServerFunc_IfPartsNameExist',
  '/yuncut_factoryspareparts_db/ServerFunc_IfPartsVersionExist',
  '/yuncut_factory_customer/ServerFunc_checkNameIsExist',
  '/yuncut_factoryspareparts_db/ServerFunc_GetFactorySparePartsList',//获取工厂基础档案
  '/yuncut_factory_customer/ServerFunc_GetCustomerSupplier',//获取工厂供应商信息
  '/yuncut_factory_customer/ServerFunc_GetFactoryCustomer',//获取客户档案信息
  '/yuncut_factorywarehouse_db/ServerFunc_GetFactoryWarehouse',//获取工厂仓库档案
  '/yuncut_factory_base/ServerFunc_GetScrapPrice',//获取工厂废钢当月单价
  '/yuncut_factory_base/ServerFunc_GetIntoType',//获取工厂收发类别\
  '/yuncut_factorywarehouse_db/ServerFunc_GetTransferOrder_Warning',//调拨单预警
  //工厂销售订单
  '/yuncut_factoryorder_db/ServerFunc_Qfactoryorder',//获取工厂销售订单
  '/yuncut_factoryorder_db/ServerFunc_Qfactoryorder_Excel',//工厂销售订单导出Excel
  '/yuncut_factory_base/ServerFunc_GetOrderSales',//选择客户
  '/yuncut_factory_customer/ServerFunc_GetCusBillAddress',//获取客户收货地址和开票信息
  '/yuncut_factoryorder_db/ServerFunc_GetMaterialOrder',//获取工厂材料销售订单
  '/yuncut_factoryorder_db/ServerFunc_GetOrderInfoList',//零件销售明细报表
  '/yuncut_factoryorder_db/ServerFunc_MaterialOrderInfoList',//材料销售明细报表
  '/yuncut_factorydelivery_db/ServerFunc_GetMaterialDelivery_List',//材料销售发货单
  '/yuncut_factory_finance_db/ServerFunc_GetSalesReceiptParts_list',//零件销售发票申请
  '/yuncut_factoryorder_db/ServerFunc_GetfactoryorderInfo',//零件销售订单展开
  '/yuncut_factoryorder_db/ServerFunc_GetMaterialOrder_expand',//材料销售订单展开

  //工厂采购管理
  '/yuncut_factory_finance_db/ServerFunc_GetPurchaseMaterial_List',//材料采购发票申请
  '/yuncut_factory_finance_db/ServerFunc_GetPurchaseParts_List',//零件采购发票申请
  //工厂生产订单
  '/yuncut_factoryprodorder_db/ServerFunc_Qfactoryprodorder',//获取工厂生产订单
  '/yuncut_factoryprodorder_db/ServerFunc_Qfactoryprodorder_Excel',//工厂生产订单导出Excel
  '/yuncut_factoryprodorder_db/ServerFunc_GetUnWorkPartsByPoId',//重新加载零件

  //工厂发货订单
  '/yuncut_factorydelivery_db/ServerFunc_GetFactoryDeliveryList',//获取工厂发货订单
  '/yuncut_factorydelivery_db/ServerFunc_GetFactoryDeliveryList_Excel',//工厂发货订单导出Excel
  '/yuncut_factoryprodorder_db/ServerFunc_GetUnassignedParts',
  '/yuncut_factorydelivery_db/ServerFunc_GetUnDeliveryOrderCode',
  '/yuncut_factorydelivery_db/ServerFunc_GetMaterialDelivery_Info',//材料发货单展开
  '/yuncut_factorydelivery_db/ServerFunc_GetFactoryDeliveryList',//零件发货单展开
  '/yuncut_factorydelivery_db/ServerFunc_SalesInvoiceList',//零件发货单展开
  //工厂库存管理
  '/yuncut_factory_productinout_db/ServerFunc_GetProductInList', //获取产品入库单
  '/yuncut_factory_productinout_db/ServerFunc_GetProductInList_dc', //产品入库单导出Excel
  '/yuncut_factory_productinout_db/ServerFunc_GetProductOutList', //获取产品出库单
  '/yuncut_factory_productinout_db/ServerFunc_GetProductOutList_db', //产品出库单导出Excel
  '/yuncut_factorymaterialinout_db/ServerFunc_GetMaterialInList', //获取材料入库单
  '/yuncut_factorymaterialinout_db/ServerFunc_GetMaterialInList_dc', //材料入库单导出Excel
  '/yuncut_factorymaterialinout_db/ServerFunc_GetMaterialOutList', //获取材料出库单
  '/yuncut_factorymaterialinout_db/ServerFunc_GetMaterialOutList_dc', //材料出库单导出Excel
  '/yuncut_factorywarehouse_db/ServerFunc_GetTransferOrder_list', //获取所有调拨单
  '/yuncut_factorywarehouse_db/ServerFunc_GetTransferOrder_list', //获取产品调拨单
  '/yuncut_factorywarehouse_db/ServerFunc_GetTransferOrder_list', //获取材料调拨单
  //工厂库存报表
  '/yuncut_factorystock_db/Q_GetFactoryProductStock', //获取产品库存表
  '/yuncut_factorystock_db/Q_GetFactoryProductStock_dc', //产品库存表导出Excel
  '/yuncut_factorystock_db/Q_GetFactoryMaterialStock', //获取材料库存表
  '/yuncut_factorystock_db/Q_GetFactoryMaterialStock_dc', //材料库存表导出Excel
  '/yuncut_factorymaterialinout_db/ServerFunc_GetMaterialInOutRecord', //获取材料出入库明细表
  '/yuncut_factorymaterialinout_db/ServerFunc_GetMaterialInOutRecord_dc', //材料出入库明细导出Excel
  '/yuncut_factory_productinout_db/ServerFunc_GetProductInOutRecord', //获取产品出入库明细表
  '/yuncut_factory_productinout_db/ServerFunc_GetProductInOutRecord_dc', //产品出入库明细表导出Excel
  '/yuncut_factorystock_db/ServerFunc_GetMaterialStockOrder',
  '/yuncut_factory_customer/ServerFunc_Serlect_Customer',
  '/yuncut_factoryspareparts_db/ServerFunc_BatchCheckParts',//自营导入零件校验
  //工厂财务管理
  '/yuncut_factory_finance_db/ServerFunc_GetSalesReceiptMaterialList', //获取材料销售发票列表
  '/yuncut_factory_finance_db/ServerFunc_GetConfirmCollectionAndPayment_list', //获取收付款列表
  '/yuncut_factory_finance_db/ServerFunc_GetCarriage_List',//获取运费单列表
  '/yuncut_factory_finance_db/ServerFunc_GetSalesReceiptMaterial_expand',//获取材料销售发票展开列表
  '/yuncut_factory_finance_db/ServerFunc_CollectionAndPayment_report',//收付款报表
  '/yuncut_factory_base/ServerFunc_GetOperating_Log',//工厂系统操作日志列表,1

  //预警
  '/yuncut_factory_finance_db/ServerFunc_GetSalesReceiptMaterial_Warning',//发票确认待审核预警
  '/yuncut_factorymaterialinout_db/ServerFunc_GetMaterialInList_Warning',//材料入库单审核预警
  '/yuncut_factory_productinout_db/ServerFunc_GetProductInList_Warning',//产品入库单预警
  '/yuncut_factoryorder_db/ServerFunc_GetPartsOrderWarning',//产品销售订单审核预警
  '/yuncut_factoryorder_db/ServerFunc_GetMaterialOrderWarning',//材料销售订单审核预警
  '/yuncut_factorydelivery_db/ServerFunc_SalesInvoiceList_WarnIng',//零件销售发货单审核预警
  '/yuncut_factorydelivery_db/ServerFunc_GetMaterialDelivery_Info_WranIng',//材料销售发货单审核预警
  '/yuncut_factorywarehouse_db/ServerFunc_GetTransferOrder_Warning',//调拨单审核预警
  '/yuncut_factory_finance_db/ServerFunc_GetSalesReceiptMaterial_Warning',//材料销售发票确认预警
  '/yuncut_factory_finance_db/ServerFunc_GetSalesReceiptParts_Warning',//产品销售发票确认预警
  '/yuncut_factory_finance_db/ServerFunc_GetPurchaseMaterial_Warning',//材料采购销售发票确认预警
  '/yuncut_factory_finance_db/ServerFunc_GetPurchaseParts_Warning',//产品采购销售发票确认预警
  '/common_sms_service/ServerFunc_SendWeixinMessagePdfInfo',//材质板厚不一致预警
  '/common_sms_service/ServerFunc_SendWeixinMessageReportQuality',//供应商质量报工完成提示

  //后工序
  '/yuncut_order_manage_db/ServerFunc_DistributablePartsProcess',
  '/yuncut_order_manage_db/ServerFunc_GetDistributablePartsProcess',
  '/yuncut_order_manage_db/ServerFunc_GetWorkprocess',
  '/yuncut_order_manage_db/ServerFunc_GetWorkprocess_detail',
  '/yuncut_workreport_db/ServerFunc_GetWorkprocessForSupplier',
  '/yuncut_invoice_manage_db/ServerFunc_GetProcedureInvoiceDetail',
  '/yuncut_invoice_manage_db/ServerFunc_GetProcedureInvoiceList',
  '/yuncut_invoice_manage_db/ServerFunc_GetWorkprocess',
  //漏加的
  '/yuncut_factory_base/ServerFunc_GetTeamWorkSupplier',//获取云切零件采购到自营的供应商
  '/yuncut_factorymaterialinout_db/ServerFunc_RealTimeStock_YunCut',//获取云切寄存工厂废钢库存
  '/yuncut_factory_base/ServerFunc_GetSetType',//获取结算方式
  '/yuncut_factory_base/ServerFunc_GetPayType',//获取款项类型
  '/yuncut_produce_order_db/ServerFunc_ModifProgramCuttingTimes',//程序列表 修改程序切割次数
  '/common_sms_service/ServerFunc_GetSend_Operation',

  //供应商端-发票信息管理、收货信息管理
  '/yuncut_supplier_db/ServerFunc_GetInvoice',
  '/yuncut_supplier_db/ServerFunc_GetAddr',
  '/yuncut_supplier_db/ServerFunc_DeleteInvoice',
  '/yuncut_supplier_db/ServerFunc_DeleteAddr',

  //供应商结算
  '/yuncut_material_order_db/getMaterialOrderList',
  '/yuncut_material_order_db/getMaterialOrderList_Sup',
  '/yuncut_material_order_db/getMaterialOrderItem',
  '/yuncut_material_order_db/getMaterialOrderDetail',
  '/yuncut_material_order_db/getMaterialStock',
  '/yuncut_material_order_db/getMaterialOrderDeliverInfo',
  '/yuncut_material_order_db/getSupplierInfo',
  '/yuncut_material_order_db/getSupplierList',
  '/yuncut_material_order_db/getWarehouseList',
  '/yuncut_factory_base/ServerFunc_GetOrderSales',
  '/yuncut_delivery_db/ServerFunc_GetDeliveryInfoByMaterial',
  '/yuncut_finance_db/getSupPayment',
  '/yuncut_finance_db/getStatementPayment',
  '/yuncut_balance_db/getSupStatementList',
  '/yuncut_balance_db/getSupStatementItem',
  '/yuncut_balance_db/getSupPaymentItem',
  '/yuncut_balance_db/getSupPaymentList',
  '/yuncut_finance_db/getSupPaymentForSupplier',
  '/yuncut_invoice_manage_db/getSupInvoice',
  '/yuncut_invoice_manage_db/getSupInvoiceItem',
  '/yuncut_balance_db/getSupStatementListSup',
  '/yuncut_balance_db/getSupPaymentListSup',
  '/yuncut_finance_db/getSupPaymentItem',
  '/yuncut_material_order_db/getMaterialOrderStatusNum_Sup',
  '/yuncut_material_order_db/getMaterialOrderStatusNum',
  '/yuncut_material_order_db/getMaterialOrderItem_Excel',
  '/yuncut_material_order_db/exportMaterialOrderList',

  '/yuncut_workstage_order_db/ServerFunc_GetWorkStageOrderList_Synchronized',
  '/yuncut_centralwarehouse_inventory_db/ServerFunc_GetMaterialStockList_Surplus',
  '/yuncut_centralwarehouse_inventory_db/ServerFunc_GetStockDetailByStockId',
  '/yuncut_material_order_db/getMaterialStock_Excel',


  //在线排产
  '/yuncut_produce_order_db/ServerFunc_GetMachineScheduleInDayList',
  '/yuncut_produce_order_db/ServerFunc_GetMachineScheduleList',
  '/yuncut_produce_order_db/ServerFunc_GetCutProgram',

  //材料形态转换
  '/yuncut_material_order_db/getMaterialTransformation',
  '/yuncut_material_order_db/getMaterialTransformation_item',

  '/yuncut_produce_order_db/ServerFunc_UpdateProgram_Bach'
]

// if(window['ENDPOINT']==='operation'){
//
// }

let requestMap = {}

/**
 * 配置request请求时的默认参数
 */
const umiRequest=extend({
  errorHandler, // 默认错误处理
  credentials: 'include', // 默认请求是否带上cookie
  prefix: '/api', //前缀
  timeout:300000//超时时长
});

const expireTime=3000;

const request = (url:any,options:any)=>{
  const key = url + JSON.stringify(options);
  const allowAccess=allowAccessRequestList.includes(url);
  if(!allowAccess){
    //防重复提交,没根据时间戳判断
    const now=new Date().getTime();
    if (requestMap[key] && ((now - requestMap[key]) < expireTime)){
      console.log('重复提交',url);
      message.error('重复提交');
      return new Promise((resolve)=>{
        resolve({
          success:false
        })
      });
    }else{
      requestMap[key]=now;
    }
  }
  return umiRequest(url,options).then(response=>{
    if(!allowAccess){
      const now=new Date().getTime();
      let timeout=expireTime-(now-requestMap[key]);
      if(timeout<0){
        timeout=0;
      }
      setTimeout(()=>{
        delete requestMap[key];
      },timeout)
    }
    return response
  })
};

export default request;


//
