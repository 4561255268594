import request from '@/utils/request';
import {UserAccountType} from "@/types/userAccount";
import {RegisterInfo} from "@/services/user/register";


export async function query(params: any): Promise<any> {//获取客户账号信息
  return request('/yuncut_customer_db/ServerFunc_GetAllAccountMainInfo',{
    method:'POST',
    data:params
  });
}

export async function querySubAccount(customerId?:string): Promise<any> {//按照id获取子账号信息
  return request('/yuncut_customer_db/ServerFunc_GetAccountSonInfo',{
    method:'POST',
    data:{
      customerId:customerId
    }
  });
}

export async function addPrimaryAccount(payload:any): Promise<any> {//注册主账户信息
  return request("/yuncut_customer_db/ServerFunc_AddMainAccount",{
    method: 'POST',
    data:{
      name:payload.name,
      phone:payload.phone,
      company:payload.company,
      email:payload.email,
      pwd:payload.pwd,
      isEnable:payload.isEnable,
      particular:payload.particular,
    }
  });
}
export async function setAccountEnable(params:{ accountId?:string,isEnable?:boolean}): Promise<any> {//更新账户的使用情况
  return request('/yuncut_customer_db/ServerFunc_UpdateAccountState',{
    method:'POST',
    data:params
  });
}
export async function setSonAccountEnable(params:{ bizId?:string,isUser?:boolean}): Promise<any> {//更新子账户的使用情况
  return request('/yuncut_customer_db/ServerFunc_SetSonAccountEnable',{
    method:'POST',
    data:params
  });
}

export async function setAccount(params:UserAccountType): Promise<any> {// 更新账户信息
  return request('/yuncut_customer_db/ServerFunc_SaveAccountInfo',{
    method:'POST',
    data:params
  });
}


export async function setAccountPwd(params:{account?:string,newPassword?:string}): Promise<any> {// 更新账户密码
  return request('/yuncut_customer_db/ServerFunc_UpdateAccountPassword',{
    method:'POST',
    data:{account:params.account,newPassword:params.newPassword,}
  });
}

export async function setAccountDeposit(params:{customerId?:string,depositProportion?:string,agreementCode?:string}): Promise<any> {// 修改定金比例   --yzl
  return request('/yuncut_customer_db/ServerFunc_UpdateDepositProportion',{
    method:'POST',
    data:{cusId:params.customerId,depositProportion:params.depositProportion,agreementCode:params.agreementCode}
  });
}

export async function queryAgreement(params: any): Promise<any> {//获取所有客户协议档案
  return request('/yuncut_customer_period/ServerFunc_GetAllAgreement',{
    method:'POST',
    data:params
  });
}

export async function queryAllCustomer(params: any): Promise<any> {//获取所有客户档案信息
  return request('/yuncut_customer_db/ServerFunc_GetAllCustomer',{
    method:'POST',
    data:params
  });
}

export async function addSonAccount(payload:any): Promise<any> {//注册子账号信息
  return request("/yuncut_customer_db/ServerFunc_RegisterSonAccount",{
    method: 'POST',
    data:payload
  });
}

export async function remove(params:{bizId?:string}): Promise<any> {//删除
  return request('/yuncut_customer_db/ServerFunc_DeleteSonAccount',{
    method:'POST',
    data:params
  });
}

export async function isExist(params: RegisterInfo) {
  return request('/yuncut_customer_db/ServerFunc_CheckCusAccount', {
    method: 'POST',
    data: {
      account:params.account
    },
  });
}

//设置专属经理
export async function settingExclusiveManager(params:any): Promise<any> {
  return request('/yuncut_customer_db/ServerFunc_SettingExclusiveManager',{
    method:'POST',
    data:{
      customerId:params.accountId,
      operId:params.operId
    }
  });
}
