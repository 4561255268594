import component from './ja-JP/component';
import globalHeader from './ja-JP/globalHeader';
import menu from './ja-JP/menu';
import pwa from './ja-JP/pwa';
import settingDrawer from './ja-JP/settingDrawer';
import settings from './ja-JP/settings';

export default {
  'navBar.lang': '言語',
  'layout.user.link.help': 'ヘルプ',
  'layout.user.link.privacy': 'プライバシー',
  'layout.user.link.terms': '条項',
  'app.preview.down.block': 'このページをローカル プロジェクトにダウンロードします',
  'app.welcome.link.fetch-blocks': 'すべてのブロックを取得する',
  'app.welcome.link.block-list': 'ブロック開発をベースに標準ページを素早く構築',
  ...globalHeader,
  ...menu,
  ...settingDrawer,
  ...settings,
  ...pwa,
  ...component,
};
