export default {
  'app.setting.pagestyle': '全体スタイル設定',
  'app.setting.pagestyle.dark': 'ダークメニュースタイル',
  'app.setting.pagestyle.light': 'ライトメニュースタイル',
  'app.setting.content-width': 'コンテンツエリアの幅',
  'app.setting.content-width.fixed': '固定幅',
  'app.setting.content-width.fluid': 'フルード',
  'app.setting.themecolor': 'テーマカラー',
  'app.setting.themecolor.dust': 'ダスク',
  'app.setting.themecolor.volcano': 'ボルケーノ',
  'app.setting.themecolor.sunset': 'サンセット',
  'app.setting.themecolor.cyan': 'シアン',
  'app.setting.themecolor.green': 'オーロラグリーン',
  'app.setting.themecolor.daybreak': 'ドーンブルー（デフォルト）',
  'app.setting.themecolor.geekblue': 'ギークブルー',
  'app.setting.themecolor.purple': 'パープル',
  'app.setting.navigationmode': 'ナビゲーションモード',
  'app.setting.sidemenu': 'サイドメニューレイアウト',
  'app.setting.topmenu': 'トップメニューレイアウト',
  'app.setting.fixedheader': '固定ヘッダー',
  'app.setting.fixedsidebar': '固定サイドバー',
  'app.setting.fixedsidebar.hint': 'サイドメニューレイアウトで設定可能',
  'app.setting.hideheader': 'スクロール時にヘッダーを隠す',
  'app.setting.hideheader.hint': '固定ヘッダー時に設定可能',
  'app.setting.othersettings': 'その他の設定',
  'app.setting.weakmode': '色弱モード',
  'app.setting.copy': '設定をコピー',
  'app.setting.copyinfo': 'コピー成功、src/defaultSettings.js にデフォルト設定を置き換えてください',
  'app.setting.production.hint':
    '設定バーは開発環境でのプレビュー用であり、本番環境では表示されません。コピー後に手動で設定ファイルを変更してください',
};
