import request from "@/utils/request";

//获取工厂零件档案列表
export async function querySparePartsList(params:{supplierId?:string}): Promise<any> {
  return request('/yuncut_factoryspareparts_db/ServerFunc_GetFactorySparePartsList',{
    method: 'POST',
    data:params
  });
}

//新增工厂零件档案列表
export async function saveSparePartsList(params:string): Promise<any> {
  return request('/yuncut_factoryspareparts_db/ServerFunc_AddFactorySparePartsList',{
    method:'POST',
    data: params
  });
}
//批量新增工厂零件档案列表
export async function saveSparePartsList_Batch(params:string): Promise<any> {
  return request('/yuncut_factoryspareparts_db/ServerFunc_AddFactorypartsList_Batch',{
    method:'POST',
    data: params
  });
}

//获取云切采购零件是否加入自营系统
export async function GetTeamWorkSupplier(params:string): Promise<any> {
  return request('/yuncut_factory_base/ServerFunc_GetTeamWorkSupplier',{
    method:'POST',
    data: params
  });
}


//工厂零件档案设置是否启用
export async function setEnable(params:{ bizId?:string,isEnable?:boolean}): Promise<any> {//更新状态
  return request('/yuncut_factoryspareparts_db/ServerFunc_SetEnable_Status',{
    method:'POST',
    data:params
  });
}

//导入批量校验
export async function batchCheckParts(params:{ bizId?:string,isEnable?:boolean}): Promise<any> {//更新状态
  return request('/yuncut_factoryspareparts_db/ServerFunc_BatchCheckParts',{
    method:'POST',
    data:params
  });
}

// 校验工厂零件名称
export async function checkPartsNameIsExist(params:any) {
  return request('/yuncut_factoryspareparts_db/ServerFunc_IfPartsNameExist', {
    method: 'POST',
    data: params,
  });
}

// 校验工厂零件规格型号
export async function checkPartsVersionIsExist(params:any) {
  return request('/yuncut_factoryspareparts_db/ServerFunc_IfPartsVersionExist', {
    method: 'POST',
    data: params,
  });
}



//获取供应商所有零件
export async function querySupplierParts(params:{supplierId?:string}): Promise<any> {//获取工厂零件档案列表
  return request('/yuncut_factoryspareparts_db/ServerFunc_GetSupplierParts',{
    method: 'POST',
    data:params
  });
}

//获取工厂客户档案
export async function queryFactoryCustomer(params:{supplierId?:string}): Promise<any> {
  return request('/yuncut_factory_customer/ServerFunc_GetFactoryCustomer',{
    method: 'POST',
    data:params
  });
}

//新增客户档案
export async function insertCustomer(params:string): Promise<any> {
  return request('/yuncut_factory_customer/ServerFunc_InsertCustomer',{
    method:'POST',
    data: params
  });
}

//工厂客户档案设置是否启用
export async function setFactoryCustomerEnable(params:{ bizId?:string,isEnable?:boolean}): Promise<any> {//更新状态
  return request('/yuncut_factory_customer/ServerFunc_SetEnable_Status',{
    method:'POST',
    data:params
  });
}

//根据客户id获取所有收货地址和开票地址
export async function queryCusBillAddress(params:{ customerId?:string,orderSelesId?:string}): Promise<any> {
  return request('/yuncut_factory_customer/ServerFunc_GetCusBillAddress',{
    method:'POST',
    data:params
  });
}

//新增工厂开票地址
export async function insertBilling(params:string): Promise<any> {
  return request('/yuncut_factory_customer/ServerFunc_InsertBilling',{
    method:'POST',
    data: params
  });
}

//新增工厂收货地址
export async function insertAddress(params:string): Promise<any> {
  return request('/yuncut_factory_customer/ServerFunc_InsertAddress',{
    method:'POST',
    data: params
  });
}


//获取工厂供应商档案
export async function queryCustomerSupplier(params:{supplierId?:string}): Promise<any> {
    return request('/yuncut_factory_customer/ServerFunc_GetCustomerSupplier',{
    method: 'POST',
    data:params
  });
}

//工厂供应商档案设置是否启用
export async function setSupplierEnable(params:{ bizId?:string,isEnable?:boolean}): Promise<any> {//更新状态
  return request('/yuncut_factory_customer/ServerFunc_SetSupplierEnable_Status',{
    method:'POST',
    data:params
  });
}

//新增工厂零件档案列表
export async function insertSupplier(params:string): Promise<any> {
  return request('/yuncut_factory_customer/ServerFunc_InsertSupplier',{
    method:'POST',
    data: params
  });
}

//修改工厂供应商档案
export async function updateCustomerSupplier(params:string): Promise<any> {
  return request('/yuncut_factory_customer/ServerFunc_UpdateCustomerSupplier',{
    method:'POST',
    data: params
  });
}



//获取工厂仓库档案
export async function queryFactoryWarehouse(params:{supplierId?:string}): Promise<any> {
  return request('/yuncut_factorywarehouse_db/ServerFunc_GetFactoryWarehouse',{
    method: 'POST',
    data:params
  });
}

//修改工厂仓库档案
export async function updateFactoryWarhouse(params:string): Promise<any> {
  return request('/yuncut_factorywarehouse_db/ServerFunc_UpdateFactoryWarhouse',{
    method:'POST',
    data: params
  });
}


//获取供应商当月废钢单价
export async function GetScrapPrice(params:string): Promise<any> {
  return request('/yuncut_factory_base/ServerFunc_GetScrapPrice',{
    method:'POST',
    data: params
  });
}

//维护废钢单价
export async function updateScrapPrice(params:string): Promise<any> {
  return request('/yuncut_factory_base/ServerFunc_updateScrapPrice',{
    method:'POST',
    data: params
  });
}


//获取工厂仓库档案下拉列表
export async function queryFactoryWarehouseDropDown(params:any): Promise<any> {
  return request('/yuncut_factorywarehouse_db/ServerFunc_GetFactoryWarehouse_DropDown',{
    method: 'POST',
    data:params
  });
}


//获取工厂客户档案下拉列表
export async function queryFactoryCustomerOption(params:any): Promise<any> {
  return request('/yuncut_factory_customer/ServerFunc_Serlect_Customer',{
    method: 'POST',
    data:params
  });
}

//获取工厂收发类别档案
export async function queryIntoType(params:{supplierId?:string}): Promise<any> {
  return request('/yuncut_factory_base/ServerFunc_GetIntoType',{
    method: 'POST',
    data:params
  });
}


//修改工厂仓库档案
export async function updateFactoryInfoType(params:string): Promise<any> {
  return request('/yuncut_factory_base/ServerFunc_UpdateFactoryInfoType',{
    method:'POST',
    data: params
  });
}

//校验客户名称
export async function checkNameIsExist(params:string): Promise<any> {
  return request('/yuncut_factory_customer/ServerFunc_checkNameIsExist',{
    method:'POST',
    data: params
  });
}


//根据id修改供应商零件
export async function UpdateFactoryPartById(params:{supplierId?:string}): Promise<any> {//获取工厂零件档案列表
  return request('/yuncut_factoryspareparts_db/ServerFunc_UpdateFactoryPartById',{
    method: 'POST',
    data:params
  });
}


//修改客户档案信息
export async function UpCustomerDetail (params:{supplierId?:string}): Promise<any> {//获取工厂零件档案列表
  return request('/yuncut_factory_customer/ServerFunc_UpdataCustomerDetail',{
    method: 'POST',
    data:params
  });
}


export async function queryOperatingLog(params:string) :Promise<any>{//.获取工厂系统操作日志
  return request('/yuncut_factory_base/ServerFunc_GetOperating_Log',{
    method:'POST',
    data:params
  });
}
