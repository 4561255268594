"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.patchRoutes = patchRoutes;

var react = require('react');

var allIcons = require('@ant-design/icons/es/icons');

function toHump(name) {
  return name.replace(/\-(\w)/g, function (all, letter) {
    return letter.toUpperCase();
  });
}

function formatter(data) {
  data.forEach(function () {
    var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
      path: '/'
    };

    if (item.icon) {
      var icon = item.icon;
      var v4IconName = toHump(icon.replace(icon[0], icon[0].toUpperCase()));
      var NewIcon = allIcons[icon] || allIcons["".concat(v4IconName, "Outlined")];

      if (NewIcon) {
        try {
          item.icon = react.createElement(NewIcon);
        } catch (error) {
          console.log(error);
        }
      }
    }

    if (item.routes || item.children) {
      var children = formatter(item.routes || item.children); // Reduce memory usage

      item.children = children;
    }
  });
  return data;
}

function patchRoutes(routes) {
  formatter(routes);
}