import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from 'D:/yuncut_q/src/pages/.umi-production/LocaleWrapper.jsx';
import { routerRedux, dynamic as _dvaDynamic } from 'dva';

const Router = routerRedux.ConnectedRouter;

const routes = [
  {
    name: 'login',
    path: '/login',
    component: __IS_BROWSER
      ? _dvaDynamic({
          app: require('@tmp/dva').getApp(),
          models: () => [
            import(/* webpackChunkName: 'p__user__Login__model.ts' */ 'D:/yuncut_q/src/pages/user/Login/model.ts').then(
              m => {
                return { namespace: 'model', ...m.default };
              },
            ),
          ],
          component: () =>
            import(/* webpackChunkName: "p__user__Login" */ '../user/Login'),
          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
            .default,
        })
      : require('../user/Login').default,
    hideInMenu: true,
    exact: true,
  },
  {
    name: 'scanCodeLogin',
    path: '/scanCodeLogin',
    component: __IS_BROWSER
      ? _dvaDynamic({
          app: require('@tmp/dva').getApp(),
          models: () => [
            import(/* webpackChunkName: 'p__user__Login__model.ts' */ 'D:/yuncut_q/src/pages/user/Login/model.ts').then(
              m => {
                return { namespace: 'model', ...m.default };
              },
            ),
          ],
          component: () =>
            import(/* webpackChunkName: "p__user__Login__ScanCode" */ '../user/Login/ScanCode'),
          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
            .default,
        })
      : require('../user/Login/ScanCode').default,
    hideInMenu: true,
    exact: true,
  },
  {
    name: 'Detail',
    path: '/resource-center/Detail',
    component: __IS_BROWSER
      ? _dvaDynamic({
          app: require('@tmp/dva').getApp(),
          models: () => [
            import(/* webpackChunkName: 'p__user__resourceCenter__model.ts' */ 'D:/yuncut_q/src/pages/user/resourceCenter/model.ts').then(
              m => {
                return { namespace: 'model', ...m.default };
              },
            ),
          ],
          component: () =>
            import(/* webpackChunkName: "p__user__resourceCenter__Detail" */ '../user/resourceCenter/Detail'),
          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
            .default,
        })
      : require('../user/resourceCenter/Detail').default,
    hideInMenu: true,
    showBack: true,
    exact: true,
  },
  {
    name: 'print',
    path: '/print/contract-print',
    component: __IS_BROWSER
      ? _dvaDynamic({
          app: require('@tmp/dva').getApp(),
          models: () => [
            import(/* webpackChunkName: 'p__operation__order__salesOrder__model.ts' */ 'D:/yuncut_q/src/pages/operation/order/salesOrder/model.ts').then(
              m => {
                return { namespace: 'model', ...m.default };
              },
            ),
          ],
          component: () =>
            import(/* webpackChunkName: "p__operation__order__salesOrder__Contract" */ '../operation/order/salesOrder/Contract'),
          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
            .default,
        })
      : require('../operation/order/salesOrder/Contract').default,
    hideInMenu: true,
    exact: true,
  },
  {
    name: 'print',
    path: '/my/order/print',
    component: __IS_BROWSER
      ? _dvaDynamic({
          app: require('@tmp/dva').getApp(),
          models: () => [
            import(/* webpackChunkName: 'p__user__Order__model.ts' */ 'D:/yuncut_q/src/pages/user/Order/model.ts').then(
              m => {
                return { namespace: 'model', ...m.default };
              },
            ),
          ],
          component: () =>
            import(/* webpackChunkName: "p__user__Order__Print" */ '../user/Order/Print'),
          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
            .default,
        })
      : require('../user/Order/Print').default,
    hideInMenu: true,
    exact: true,
  },
  {
    name: 'chose-register',
    path: '/chose-register-identity',
    component: __IS_BROWSER
      ? _dvaDynamic({
          app: require('@tmp/dva').getApp(),
          models: () => [
            import(/* webpackChunkName: 'p__user__Register__model.ts' */ 'D:/yuncut_q/src/pages/user/Register/model.ts').then(
              m => {
                return { namespace: 'model', ...m.default };
              },
            ),
          ],
          component: () =>
            import(/* webpackChunkName: "p__user__Register__ChoseRegisterIdentity" */ '../user/Register/ChoseRegisterIdentity'),
          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
            .default,
        })
      : require('../user/Register/ChoseRegisterIdentity').default,
    hideInMenu: true,
    exact: true,
  },
  {
    name: 'change-password',
    path: '/chose-register-password',
    component: __IS_BROWSER
      ? _dvaDynamic({
          app: require('@tmp/dva').getApp(),
          models: () => [
            import(/* webpackChunkName: 'p__user__Register__model.ts' */ 'D:/yuncut_q/src/pages/user/Register/model.ts').then(
              m => {
                return { namespace: 'model', ...m.default };
              },
            ),
          ],
          component: () =>
            import(/* webpackChunkName: "p__user__Register__ChangePassword" */ '../user/Register/ChangePassword'),
          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
            .default,
        })
      : require('../user/Register/ChangePassword').default,
    hideInMenu: true,
    exact: true,
  },
  {
    name: 'user-register',
    path: '/user/register',
    component: __IS_BROWSER
      ? _dvaDynamic({
          app: require('@tmp/dva').getApp(),
          models: () => [
            import(/* webpackChunkName: 'p__user__Register__model.ts' */ 'D:/yuncut_q/src/pages/user/Register/model.ts').then(
              m => {
                return { namespace: 'model', ...m.default };
              },
            ),
          ],
          component: () =>
            import(/* webpackChunkName: "p__user__Register" */ '../user/Register'),
          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
            .default,
        })
      : require('../user/Register').default,
    hideInMenu: true,
    exact: true,
  },
  {
    name: 'wechat-register',
    path: '/wechatRegister',
    component: __IS_BROWSER
      ? _dvaDynamic({
          app: require('@tmp/dva').getApp(),
          models: () => [
            import(/* webpackChunkName: 'p__user__Register__model.ts' */ 'D:/yuncut_q/src/pages/user/Register/model.ts').then(
              m => {
                return { namespace: 'model', ...m.default };
              },
            ),
          ],
          component: () =>
            import(/* webpackChunkName: "p__user__Register__WechatRegister" */ '../user/Register/WechatRegister'),
          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
            .default,
        })
      : require('../user/Register/WechatRegister').default,
    hideInMenu: true,
    exact: true,
  },
  {
    name: 'supplier-register',
    path: '/supplier/register',
    component: __IS_BROWSER
      ? _dvaDynamic({
          app: require('@tmp/dva').getApp(),
          models: () => [
            import(/* webpackChunkName: 'p__user__Register__model.ts' */ 'D:/yuncut_q/src/pages/user/Register/model.ts').then(
              m => {
                return { namespace: 'model', ...m.default };
              },
            ),
          ],
          component: () =>
            import(/* webpackChunkName: "p__user__Register__SupplierRegister" */ '../user/Register/SupplierRegister'),
          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
            .default,
        })
      : require('../user/Register/SupplierRegister').default,
    hideInMenu: true,
    exact: true,
  },
  {
    path: '/JP',
    component: __IS_BROWSER
      ? _dvaDynamic({
          app: require('@tmp/dva').getApp(),
          models: () => [
            import(/* webpackChunkName: 'layouts__user__model.ts' */ 'D:/yuncut_q/src/layouts/user/model.ts').then(
              m => {
                return { namespace: 'model', ...m.default };
              },
            ),
          ],
          component: () =>
            import(/* webpackChunkName: "layouts__user__PhonePortalLayout" */ '../../layouts/user/PhonePortalLayout'),
          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/user/PhonePortalLayout').default,
    hideInMenu: true,
    routes: [
      {
        name: 'mobileHome',
        path: '/JP',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__user__HomeV2__model.ts' */ 'D:/yuncut_q/src/pages/user/HomeV2/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__user__HomeV2__MobileHomeJP" */ '../user/HomeV2/MobileHomeJP'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../user/HomeV2/MobileHomeJP').default,
        exact: true,
      },
      {
        name: 'founder',
        path: '/JP/personalIntro',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__user__HomeV2__model.ts' */ 'D:/yuncut_q/src/pages/user/HomeV2/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__user__HomeV2__mobileHomeJP__Founder" */ '../user/HomeV2/mobileHomeJP/Founder'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../user/HomeV2/mobileHomeJP/Founder').default,
        exact: true,
      },
      {
        name: 'product',
        path: '/JP/product',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__user__HomeV2__model.ts' */ 'D:/yuncut_q/src/pages/user/HomeV2/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__user__HomeV2__ProductJP" */ '../user/HomeV2/ProductJP'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../user/HomeV2/ProductJP').default,
        exact: true,
      },
      {
        name: 'device',
        path: '/JP/device',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__user__HomeV2__model.ts' */ 'D:/yuncut_q/src/pages/user/HomeV2/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__user__HomeV2__DeviceJP" */ '../user/HomeV2/DeviceJP'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../user/HomeV2/DeviceJP').default,
        exact: true,
      },
      {
        name: 'contract',
        path: '/JP/contractUs',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__user__HomeV2__model.ts' */ 'D:/yuncut_q/src/pages/user/HomeV2/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__user__HomeV2__mobileHomeJP__ContractUs" */ '../user/HomeV2/mobileHomeJP/ContractUs'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../user/HomeV2/mobileHomeJP/ContractUs').default,
        exact: true,
      },
      {
        name: 'consult',
        path: '/JP/consultService',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__user__HomeV2__model.ts' */ 'D:/yuncut_q/src/pages/user/HomeV2/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__user__HomeV2__mobileHomeJP__ConsultService" */ '../user/HomeV2/mobileHomeJP/ConsultService'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../user/HomeV2/mobileHomeJP/ConsultService').default,
        exact: true,
      },
    ],
  },
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          app: require('@tmp/dva').getApp(),
          models: () => [
            import(/* webpackChunkName: 'layouts__user__model.ts' */ 'D:/yuncut_q/src/layouts/user/model.ts').then(
              m => {
                return { namespace: 'model', ...m.default };
              },
            ),
          ],
          component: () =>
            import(/* webpackChunkName: "layouts__user__PortalLayout" */ '../../layouts/user/PortalLayout'),
          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/user/PortalLayout').default,
    routes: [
      {
        name: 'home_portal',
        path: '/',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__user__HomeV2__model.ts' */ 'D:/yuncut_q/src/pages/user/HomeV2/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__user__HomeV2__home_portal" */ '../user/HomeV2/home_portal'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../user/HomeV2/home_portal').default,
        hideInMenu: true,
        exact: true,
      },
      {
        name: 'home',
        path: '/home',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__user__HomeV2__model.ts' */ 'D:/yuncut_q/src/pages/user/HomeV2/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__user__HomeV2" */ '../user/HomeV2'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../user/HomeV2').default,
        exact: true,
      },
      {
        name: 'my',
        path: '/my',
        routes: [
          {
            path: '/my',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'layouts__user__model.ts' */ 'D:/yuncut_q/src/layouts/user/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "layouts__user__MyLayout" */ '../../layouts/user/MyLayout'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../../layouts/user/MyLayout').default,
            routes: [
              {
                path: '/my',
                redirect: '/my/order/list',
                exact: true,
              },
              {
                name: 'my.overview',
                path: '/my/overview',
                icon: 'home',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__user__Overview__model.ts' */ 'D:/yuncut_q/src/pages/user/Overview/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/Overview'),
                      LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../user/Overview').default,
                exact: true,
              },
              {
                name: 'my.inquiry',
                icon: 'FileSearchOutlined',
                path: '/my/inquiry',
                routes: [
                  {
                    name: 'form',
                    path: '/my/inquiry/form',
                    icon: 'FileAddOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__Inquiry__model.ts' */ 'D:/yuncut_q/src/pages/user/Inquiry/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/Inquiry/InquiryForm'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/Inquiry/InquiryForm').default,
                    exact: true,
                  },
                  {
                    name: 'batch',
                    path: '/my/inquiry/batch',
                    icon: 'FileAddOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__Inquiry__InquiryBatch__model.ts' */ 'D:/yuncut_q/src/pages/user/Inquiry/InquiryBatch/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                            import(/* webpackChunkName: 'p__user__Inquiry__model.ts' */ 'D:/yuncut_q/src/pages/user/Inquiry/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/Inquiry/InquiryBatch/HandsontableExcel'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/Inquiry/InquiryBatch/HandsontableExcel')
                          .default,
                    exact: true,
                  },
                  {
                    name: 'list',
                    path: '/my/inquiry/list',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__Inquiry__model.ts' */ 'D:/yuncut_q/src/pages/user/Inquiry/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/Inquiry/InquiryList'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/Inquiry/InquiryList').default,
                    exact: true,
                  },
                  {
                    name: 'detail',
                    path: '/my/inquiry/detail',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__Inquiry__InquiryDetail__model.ts' */ 'D:/yuncut_q/src/pages/user/Inquiry/InquiryDetail/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                            import(/* webpackChunkName: 'p__user__Inquiry__model.ts' */ 'D:/yuncut_q/src/pages/user/Inquiry/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/Inquiry/InquiryDetail'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/Inquiry/InquiryDetail').default,
                    hideInMenu: true,
                    exact: true,
                  },
                  {
                    name: 'order',
                    path: '/my/inquiry/order',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__Inquiry__model.ts' */ 'D:/yuncut_q/src/pages/user/Inquiry/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/Inquiry/InquiryOrder'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/Inquiry/InquiryOrder').default,
                    hideInMenu: true,
                    exact: true,
                  },
                ],
              },
              {
                name: 'my.order',
                icon: 'FileSearchOutlined',
                path: '/my/order',
                routes: [
                  {
                    path: '/my/order',
                    redirect: '/my/order/list',
                    exact: true,
                  },
                  {
                    name: 'list',
                    path: '/my/order/list',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__Order__model.ts' */ 'D:/yuncut_q/src/pages/user/Order/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/Order/OrderList'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/Order/OrderList').default,
                    exact: true,
                  },
                  {
                    name: 'appliedChangeList',
                    path: '/my/order/appliedChangeList',
                    icon: 'FileSearchOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__ChangeManagement__model.ts' */ 'D:/yuncut_q/src/pages/user/ChangeManagement/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/ChangeManagement/AlreadyAppliedChangeList'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/ChangeManagement/AlreadyAppliedChangeList')
                          .default,
                    exact: true,
                  },
                  {
                    name: 'changeContent',
                    path: '/my/order/appliedChangeList/changeContent',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__ChangeManagement__model.ts' */ 'D:/yuncut_q/src/pages/user/ChangeManagement/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/ChangeManagement/AlreadyAppliedChangeList/ChangeContent/OrderChangeContent'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/ChangeManagement/AlreadyAppliedChangeList/ChangeContent/OrderChangeContent')
                          .default,
                    hideInMenu: true,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'changePriceConfirm',
                    path: '/my/order/appliedChangeList/changePriceConfirm',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__ChangeManagement__model.ts' */ 'D:/yuncut_q/src/pages/user/ChangeManagement/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/ChangeManagement/AlreadyAppliedChangeList/PriceConfirm'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/ChangeManagement/AlreadyAppliedChangeList/PriceConfirm')
                          .default,
                    hideInMenu: true,
                    showBack: true,
                    exact: true,
                  },
                  {
                    name: 'detail',
                    path: '/my/order/detail',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__Order__model.ts' */ 'D:/yuncut_q/src/pages/user/Order/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/Order/OrderDetail'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/Order/OrderDetail').default,
                    hideInMenu: true,
                    exact: true,
                  },
                  {
                    name: 'applyChange',
                    path: '/my/order/applyChange',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__ChangeManagement__model.ts' */ 'D:/yuncut_q/src/pages/user/ChangeManagement/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/ChangeManagement/ApplyChange'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/ChangeManagement/ApplyChange').default,
                    hideInMenu: true,
                    exact: true,
                  },
                  {
                    name: 'ChangePartOne',
                    path: '/my/order/applyChange/ChangePartOne',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__ChangeManagement__model.ts' */ 'D:/yuncut_q/src/pages/user/ChangeManagement/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/ChangeManagement/ApplyChange/ChangePartOne'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/ChangeManagement/ApplyChange/ChangePartOne')
                          .default,
                    hideInMenu: true,
                    exact: true,
                  },
                  {
                    name: 'PartChangeTable',
                    path: '/my/order/applyChange/PartChangeTable',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__ChangeManagement__ApplyChange__PartChangeTable__model.ts' */ 'D:/yuncut_q/src/pages/user/ChangeManagement/ApplyChange/PartChangeTable/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                            import(/* webpackChunkName: 'p__user__ChangeManagement__model.ts' */ 'D:/yuncut_q/src/pages/user/ChangeManagement/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/ChangeManagement/ApplyChange/PartChangeTable/HandsontableExcel'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/ChangeManagement/ApplyChange/PartChangeTable/HandsontableExcel')
                          .default,
                    hideInMenu: true,
                    exact: true,
                  },
                  {
                    name: 'PartChangeSubmit',
                    path: '/my/order/applyChange/PartChangeSubmit',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__ChangeManagement__model.ts' */ 'D:/yuncut_q/src/pages/user/ChangeManagement/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/ChangeManagement/ApplyChange/ChangePartSubmit'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/ChangeManagement/ApplyChange/ChangePartSubmit')
                          .default,
                    hideInMenu: true,
                    exact: true,
                  },
                  {
                    name: 'anotherList',
                    path: '/my/order/anotherList',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__Order__model.ts' */ 'D:/yuncut_q/src/pages/user/Order/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/Order/InquiryOrder'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/Order/InquiryOrder').default,
                    hideInMenu: true,
                    exact: true,
                  },
                  {
                    name: 'batch-payment-deposit',
                    path: '/my/order/batch-payment-deposit',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__Order__model.ts' */ 'D:/yuncut_q/src/pages/user/Order/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/Order/OrderList/BatchPaymentDepositForm'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/Order/OrderList/BatchPaymentDepositForm')
                          .default,
                    showBack: true,
                    hideInMenu: true,
                    exact: true,
                  },
                ],
              },
              {
                name: 'my.postSale',
                icon: 'BlockOutlined',
                path: '/my/postSale',
                routes: [
                  {
                    name: 'list',
                    path: '/my/postSale/list',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__Order__postSale__List__model.ts' */ 'D:/yuncut_q/src/pages/user/Order/postSale/List/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                            import(/* webpackChunkName: 'p__user__Order__model.ts' */ 'D:/yuncut_q/src/pages/user/Order/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/Order/postSale/List'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/Order/postSale/List').default,
                    exact: true,
                  },
                  {
                    name: 'AlreadyAppliedList',
                    path: '/my/postSale/AlreadyApplied_list',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__Order__model.ts' */ 'D:/yuncut_q/src/pages/user/Order/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/Order/postSale/AlreadyApplied_list'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/Order/postSale/AlreadyApplied_list')
                          .default,
                    exact: true,
                  },
                  {
                    name: 'postSale',
                    path: '/my/postSale/apply',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__Order__postSale__Form__model.ts' */ 'D:/yuncut_q/src/pages/user/Order/postSale/Form/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                            import(/* webpackChunkName: 'p__user__Order__model.ts' */ 'D:/yuncut_q/src/pages/user/Order/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/Order/postSale/Form'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/Order/postSale/Form').default,
                    hideInMenu: true,
                    exact: true,
                  },
                  {
                    name: 'postSaleDetail',
                    path: '/my/postSale/postSaleDetail',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__Order__model.ts' */ 'D:/yuncut_q/src/pages/user/Order/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/Order/postSale/Detail'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/Order/postSale/Detail').default,
                    showBack: true,
                    hideInMenu: true,
                    exact: true,
                  },
                  {
                    name: 'returnGoods',
                    path: '/my/postSale/returnGoodsApply',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__Order__returnGoods__Form__model.ts' */ 'D:/yuncut_q/src/pages/user/Order/returnGoods/Form/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                            import(/* webpackChunkName: 'p__user__Order__model.ts' */ 'D:/yuncut_q/src/pages/user/Order/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/Order/returnGoods/Form'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/Order/returnGoods/Form').default,
                    hideInMenu: true,
                    exact: true,
                  },
                ],
              },
              {
                name: 'my.trade',
                icon: 'DollarOutlined',
                path: '/my/trade',
                routes: [
                  {
                    name: 'pay',
                    path: '/my/trade/pay',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__Order__pay__List__model.ts' */ 'D:/yuncut_q/src/pages/user/Order/pay/List/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                            import(/* webpackChunkName: 'p__user__Order__model.ts' */ 'D:/yuncut_q/src/pages/user/Order/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/Order/pay/List'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/Order/pay/List').default,
                    exact: true,
                  },
                  {
                    name: 'invoiceList',
                    path: '/my/trade/invoiceList',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__Order__invoice__List__model.ts' */ 'D:/yuncut_q/src/pages/user/Order/invoice/List/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                            import(/* webpackChunkName: 'p__user__Order__model.ts' */ 'D:/yuncut_q/src/pages/user/Order/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/Order/invoice/List'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/Order/invoice/List').default,
                    exact: true,
                  },
                ],
              },
              {
                name: 'my.part',
                icon: 'BlockOutlined',
                path: '/my/part',
                routes: [
                  {
                    name: 'list',
                    path: '/my/part/list',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__Part__PartList__model.ts' */ 'D:/yuncut_q/src/pages/user/Part/PartList/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/Part/PartList'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/Part/PartList').default,
                    exact: true,
                  },
                  {
                    name: 'form',
                    path: '/my/part/form',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__Part__PartList__model.ts' */ 'D:/yuncut_q/src/pages/user/Part/PartList/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/Part/PartList/PartForm'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/Part/PartList/PartForm').default,
                    hideInMenu: true,
                    exact: true,
                  },
                  {
                    name: 'fixedPrice',
                    path: '/my/part/partFixedPrice',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__Part__PartList__PartsFixedPrice__model.ts' */ 'D:/yuncut_q/src/pages/user/Part/PartList/PartsFixedPrice/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                            import(/* webpackChunkName: 'p__user__Part__PartList__model.ts' */ 'D:/yuncut_q/src/pages/user/Part/PartList/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/Part/PartList/PartsFixedPrice'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/Part/PartList/PartsFixedPrice')
                          .default,
                    hideInMenu: true,
                    exact: true,
                  },
                  {
                    name: 'detail',
                    path: '/my/part/detail',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__Part__PartList__PartDetail__model.ts' */ 'D:/yuncut_q/src/pages/user/Part/PartList/PartDetail/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                            import(/* webpackChunkName: 'p__user__Part__PartList__model.ts' */ 'D:/yuncut_q/src/pages/user/Part/PartList/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/Part/PartList/PartDetail'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/Part/PartList/PartDetail').default,
                    hideInMenu: true,
                    exact: true,
                  },
                  {
                    name: 'combine',
                    path: '/my/part/combine',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__Part__PartCombine__model.ts' */ 'D:/yuncut_q/src/pages/user/Part/PartCombine/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/Part/PartCombine'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/Part/PartCombine').default,
                    exact: true,
                  },
                ],
              },
              {
                name: 'my.settlement',
                path: '/my/settlement',
                icon: 'TransactionOutlined',
                hideChildrenInMenu: true,
                routes: [
                  {
                    path: '/my/settlement',
                    redirect: '/my/settlement/list',
                    exact: true,
                  },
                  {
                    name: 'list',
                    path: '/my/settlement/list',
                    icon: 'TransactionOutlined',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__Settlement__model.ts' */ 'D:/yuncut_q/src/pages/user/Settlement/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/Settlement'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/Settlement').default,
                    exact: true,
                  },
                ],
              },
              {
                name: 'my.setting',
                icon: 'setting',
                path: '/my/setting',
                routes: [
                  {
                    name: 'profile',
                    path: '/my/setting/profile',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__Setting__Profile__model.ts' */ 'D:/yuncut_q/src/pages/user/Setting/Profile/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/Setting/Profile'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/Setting/Profile').default,
                    exact: true,
                  },
                  {
                    name: 'unit-information',
                    path: '/my/setting/unit-information',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__Setting__Unit__model.ts' */ 'D:/yuncut_q/src/pages/user/Setting/Unit/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/Setting/Unit'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/Setting/Unit').default,
                    exact: true,
                  },
                  {
                    name: 'sub-account',
                    path: '/my/setting/sub-account',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__Setting__SubAccount__model.ts' */ 'D:/yuncut_q/src/pages/user/Setting/SubAccount/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/Setting/SubAccount'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/Setting/SubAccount').default,
                    exact: true,
                  },
                  {
                    name: 'invoice-information',
                    path: '/my/setting/invoice-information',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__Setting__Invoice__model.ts' */ 'D:/yuncut_q/src/pages/user/Setting/Invoice/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/Setting/Invoice'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/Setting/Invoice').default,
                    exact: true,
                  },
                  {
                    name: 'receive-address',
                    path: '/my/setting/receive-address',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          app: require('@tmp/dva').getApp(),
                          models: () => [
                            import(/* webpackChunkName: 'p__user__Setting__ReceiveAddress__model.ts' */ 'D:/yuncut_q/src/pages/user/Setting/ReceiveAddress/model.ts').then(
                              m => {
                                return { namespace: 'model', ...m.default };
                              },
                            ),
                          ],
                          component: () =>
                            import(/* webpackChunkName: "layouts__user__MyLayout" */ '../user/Setting/ReceiveAddress'),
                          LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../user/Setting/ReceiveAddress').default,
                    exact: true,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'steel-plate-cutting',
        path: '/home2',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__user__HomeV2__model.ts' */ 'D:/yuncut_q/src/pages/user/HomeV2/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__user__HomeV2" */ '../user/HomeV2'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../user/HomeV2').default,
        exact: true,
      },
      {
        name: 'material-market',
        path: '/material-market',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__user__MaterialMarket" */ '../user/MaterialMarket'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../user/MaterialMarket').default,
        exact: true,
      },
      {
        name: 'noLoginInquiry',
        path: '/noLoginInquiry',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__user__Inquiry__model.ts' */ 'D:/yuncut_q/src/pages/user/Inquiry/model.ts').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__user__Inquiry__InquiryNoLogin" */ '../user/Inquiry/InquiryNoLogin'),
              LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                .default,
            })
          : require('../user/Inquiry/InquiryNoLogin').default,
        hideInMenu: true,
        exact: true,
      },
      {
        name: 'resource-center',
        path: '/resource-center',
        routes: [
          {
            path: '/resource-center/resourceCenter',
            redirect: '/resource-center',
            exact: true,
          },
          {
            name: 'home',
            path: '/resource-center',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__user__resourceCenter__model.ts' */ 'D:/yuncut_q/src/pages/user/resourceCenter/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__user__resourceCenter" */ '../user/resourceCenter'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../user/resourceCenter').default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'List',
            path: '/resource-center/List',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__user__resourceCenter__model.ts' */ 'D:/yuncut_q/src/pages/user/resourceCenter/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__user__resourceCenter__List" */ '../user/resourceCenter/List'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../user/resourceCenter/List').default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'VideoList',
            path: '/resource-center/VideoList',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__user__resourceCenter__model.ts' */ 'D:/yuncut_q/src/pages/user/resourceCenter/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__user__resourceCenter__VideoList" */ '../user/resourceCenter/VideoList'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../user/resourceCenter/VideoList').default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'Detail',
            path: '/resource-center/Detail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__user__resourceCenter__model.ts' */ 'D:/yuncut_q/src/pages/user/resourceCenter/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__user__resourceCenter__Detail" */ '../user/resourceCenter/Detail'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../user/resourceCenter/Detail').default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
          {
            name: 'homeSearchPage',
            path: '/resource-center/Search',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__user__resourceCenter__model.ts' */ 'D:/yuncut_q/src/pages/user/resourceCenter/model.ts').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__user__resourceCenter__Search" */ '../user/resourceCenter/Search'),
                  LoadingComponent: require('D:/yuncut_q/src/components/PageLoading/index')
                    .default,
                })
              : require('../user/resourceCenter/Search').default,
            hideInMenu: true,
            showBack: true,
            exact: true,
          },
        ],
      },
    ],
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}
