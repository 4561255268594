import request from '@/utils/request';
import {OpeAccountType} from "@/types/opeAccount";
import {RegisterInfo} from "@/services/user/register";

export async function queryCurrent(): Promise<any> {
  return request('/yuncut_operation_login/ServerFunc_Operation_GetLoginInfo');
}

export async function query(params:any): Promise<any> {
  return request('/yuncut_operation_db/ServerFunc_GetOpeAccount',{
    method: 'POST',
    data:params
  });
}

export async function queryExclusiveManagerInfo(params:any): Promise<any> {
  return request('/yuncut_operation_db/ServerFunc_GetExclusiveManagerInfo',{
    method: 'POST',
    data:params
  });
}

export async function save(params:OpeAccountType): Promise<any> {
  return request('/yuncut_operation_db/ServerFunc_SaveOpeAccount',{
    method: 'POST',
    data: {
      account:[params],
      roleId:params.roleId,
    }
  });
}

export async function modifyAvatar(fileId:string): Promise<any> {
  return request("/yuncut_operation_db/ServerFunc_Chang_HeadPortrait",{
    method: 'POST',
    data: {
      FileId:fileId,
    }
  });
}


export async function  queryAvatars():Promise<any> {
  return request('/yuncut_sys_headportrait_db/ServerFunc_GetSysDefaultHeadPortrait');
}

export async function saveCusAccountBase(payload:any): Promise<any> {
  return request("/yuncut_operation_db/ServerFunc_SaveOpeAccountBase",{
    method: 'POST',
    data: {
      id:payload.id,
      account:payload.account,
      email:payload.email,
      phone:payload.phone,
      particular:payload.particular,
    }
  });
}

export async function modifyPassword(payload:any):Promise<any>{//更换密码
  return request(
    '/yuncut_operation_db/ServerFunc_Change_Password', {
      method:'POST',
      data:{
        password:payload.password,
        certCode:payload.certCode,
      }
    }
  )
}

export async function modifyPhone(payload:any): Promise<any> {//更换手机
  return request("/yuncut_operation_db/ServerFunc_Change_Phone",{
    method: 'POST',
    data: {
      phone:payload.phone,
      certCode:payload.certCode,
    }
  });
}

export async function sendCertCode(payload:any): Promise<any> {//校验验证码
  return request("/common_certcode_service/ServerFunc_CheckCertCode",{
    method: 'POST',
    data: {
      certCode:payload.certCode,
    }
  });
}

export async function queryNotices(): Promise<any> {
  return request('/api/notices');
}

export async function isExist(params: RegisterInfo) {
  return request('/yuncut_operation_db/ServerFunc_CheckOpeAccount', {
    method: 'POST',
    data: {
      account:params.account
    },
  });
}

export async function setEnable(params:{ id?:string,isEnable?:boolean}): Promise<any> {// 更账户的使用情况
  return request('/yuncut_operation_db/ServerFunc_ModifyAccountEnable',{
    method:'POST',
    data:params
  });
}
export async function modifyPassWord(params:{ id?:string,pwd?:boolean}): Promise<any> {// 修改密码
  return request('/yuncut_operation_db/ServerFunc_ModifyPassWord',{
    method:'POST',
    data:params
  });
}


