import request from "@/utils/request";
import {PaginationParams} from "@/utils/utils";

export interface SupplierQueryParams extends PaginationParams{

}

export async function query(params:SupplierQueryParams): Promise<any> {
  return request('/yuncut_supplier_db/ServerFunc_GetSupplierList',{
    method:'POST',
    data:params
  });
}

export async function queryAll(params:SupplierQueryParams): Promise<any> {
  return request('/yuncut_supplier_db/ServerFunc_GetAllSupplier',{
    method:'POST',
  });
}


export async function queryAllCentre(params:SupplierQueryParams): Promise<any> {//获取所有中心仓供应商
  return request('/yuncut_centralwarehouse_db/ServerFunc_GetAllSuppliers',{
    method:'POST',
    data:params
  });
}

export async function queryGys(params:SupplierQueryParams): Promise<any> {
  return request('/yuncut_supplier_db/ServerFunc_GetAllSupplier_sup',{
    method:'POST',
  });
}


//继承分页类
export interface CanDistributeSupplierQueryParams extends PaginationParams{
  mainId?:string;//销售订单id
  cutMethodId?:string;//切割工艺Id
  plateThickId?:string;//板厚id
  plateThick?:string;//板厚
  maxRectangleLong?:string;//最大矩形长度
  maxRectangleWidth?:string;//最大矩形宽度
  cutMethodThickId?:string;//切割板厚id
}

export async function queryCanDistribute(params:CanDistributeSupplierQueryParams): Promise<any> {
  return request('/yuncut_supplier_db/ServerFunc_CanDistributeSupplier',{
    method:'POST',
    data:{
      cusparts:params
    }
  });
}

//yzl  分配下拉框查询用
export async function queryCanDistributes(params:any): Promise<any> {
  return request('/yuncut_supplier_db/ServerFunc_CanDistributeSupplier_v2',{
    method:'POST',
    data:params
  });
}
//供应商下拉框搜索
export async function filterSuppliers(params:any): Promise<any> {
  return request('/yuncut_supplier_db/ServerFunc_FilterCanDistributeSupplier_2',{
    method:'POST',
    data:params
  });
}
//工厂下拉框
export async function querySupplierOptions(params:any): Promise<any> {
  return request('/yuncut_supplier_db/ServerFunc_GetAllSupplierOption',{
    method:'POST',
    data:params
  });
}

export async function queryYs7_GetCameraInfo(params:any): Promise<any> {//
  return request('/ydg_open_ys7_db/ServerFunc_OpenYs7_GetCameraInfo',{
    method:'POST',
    data:params
  });
}

export async function getAllSupplier(params:any): Promise<any> {//
  return request('/yuncut_supplier_db/ServerFunc_GetAllSupplier',{
    method:'POST',
    data:params
  });
}

export async function saveCameraYs(params:any): Promise<any> {//
  return request('/ydg_open_ys7_db/ServerFunc_OpenYs7_SaveCameraInfo',{
    method:'POST',
    data:params
  });
}

export async function deleteCameraYs(params:any): Promise<any> {//
  return request('/ydg_open_ys7_db/ServerFunc_OpenYs7_DeleteCameraInfo',{
    method:'POST',
    data:params
  });
}



