export default {
  'component.globalHeader.search': 'サイト内検索',
  'component.globalHeader.search.example1': '検索ヒント一',
  'component.globalHeader.search.example2': '検索ヒント二',
  'component.globalHeader.search.example3': '検索ヒント三',
  'component.globalHeader.help': '使用ドキュメント',
  'component.globalHeader.notification': '通知',
  'component.globalHeader.notification.empty': 'すべての通知を表示しました',
  'component.globalHeader.message': 'メッセージ',
  'component.globalHeader.message.empty': 'すべてのメッセージを読みました',
  'component.globalHeader.event': 'やるべきこと',
  'component.globalHeader.event.empty': 'すべてのタスクを完了しました',
  'component.noticeIcon.clear': 'クリア',
  'component.noticeIcon.cleared': 'クリアされました',
  'component.noticeIcon.empty': 'データがありません',
  'component.noticeIcon.view-more': 'もっと見る',
};
