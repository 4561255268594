import request from '@/utils/request';

//材质标准
export async function query(): Promise<any> {
  return request('/yuncut_base_texturestandard_db/ServerFunc_GetTexturestandard_Drop_Down',{
    method:'POST',
  });
}

//材质归类下拉
export async function queryMaterialClassification(): Promise<any> {
  return request('/yuncut_base_texture_db/ServerFunc_GetTextureClassification_DropDown',{
    method:'POST',
  });
}

//获取材质标准的数据
export async function queryAll(params:any): Promise<any> {
  return request('/yuncut_base_texturestandard_db/ServerFunc_GetTexturestandard',{
    method:'POST',
    data:params
  });
}

export async function save(params:any): Promise<any> {
  return request('/yuncut_base_texturestandard_db/ServerFunc_SaveTexturestandard',{
    method:'POST',
    data: {
      textureStandard: [params]
    }
  });
}
export async function remove(params:{bizId?:string}): Promise<any> {//删除
  return request('/yuncut_base_texturestandard_db/ServerFunc_DeleteTexturestandard',{
    method:'POST',
    data:params
  });
}


export async function setEnable(params:{ bizId?:string,isEnable?:boolean}): Promise<any> {//更新状态
    return request('/yuncut_base_texturestandard_db/ServerFunc_SetEnable_Status',{
      method:'POST',
      data:params
    });
  }

export async function isExists(params:any) {
  return request('/yuncut_base_texturestandard_db/ServerFunc_IfTextureNameExist', {
    method: 'POST',
    data: params,
  });
}
