import request from '@/utils/request';


//材质钢厂

export async function query(): Promise<any> {
  return request('/yuncut_base_brand_db/ServerFunc_GetBaseBrand_DropDown',{
    method:'POST',
  });
}

export async function querys(params:any): Promise<any> {
  return request('/yuncut_base_brand_db/ServerFunc_GetBrand',{
    method:'POST',
    data:params
  });
}

export async function save(params:any): Promise<any> {//保存
  return request('/yuncut_base_brand_db/ServerFunc_SaveBrand', {
    method: 'POST',
    data: {
      texturebrand:[params]
    }
  });
}

export async function remove(params:{bizId?:string}): Promise<any> {//删除
  return request('/yuncut_base_brand_db/ServerFunc_DeleteBrand',{
    method:'POST',
    data:params
  });
}

export async function enable(params:{bizId?:string,isEnable?:boolean}):Promise<any>{
  return request('/yuncut_base_brand_db/ServerFunc_SetEnable_Status',{
    method:'POST',
    data:params
  });
}
