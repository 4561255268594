import { Reducer } from 'redux';
import {Subscription, Effect} from 'dva';

import {queryExclusiveManagerInfo} from '@/services/operation/operation'
import { NoticeIconData } from '@/components/NoticeIcon';
import { ConnectState } from './connect.d';
import {setAuthority} from "@/utils/authority";
import {FunctionType} from "@/types/function";
import {routes} from "../../config/config";
import {formatMessage} from "umi-plugin-react/locale";
import {router} from "umi";
import {modifyPhone, queryCurrent} from "@/services/user/user";

function getMenuName(data:any=[],pathname:any,name:any,isMenu:boolean,isShowNav:boolean){
  for(let r=0;r<data.length;r++){
    const route:any=data[r];
    if(!route.name){
      continue;
    }

    if(pathname.match(route.path)){
      name=name+'.'+route.name;
      if(pathname===route.path){
        if(!route.hideChildrenInMenu&&route.routes&&route.routes.length>0){
          isMenu=true;
        }
        if(route.hideInNavTabs){
          isShowNav=false;
        }
        break;
      }
      if(route.routes){
        const menu=getMenuName(route.routes,pathname,name,isMenu,isShowNav);
        name=menu.name;
        isMenu=menu.isMenu;
        isShowNav=menu.isShowNav;
      }
    }
  }
  return {name,isMenu,isShowNav};
}

export interface NoticeItem extends NoticeIconData {
  id: string;
  type: string;
  status: string;
}

export interface CurrentUser {
  id?:string,
  name?:string,
  avatar?:string,
  phone?:string,
  email?:string,
  company?:string,
  companyId?:string,

  customerId?:string,
  isFixedPrice?:any,
  priceValidity?:any,
  exclusiveManagerId?:string,
  functions?:FunctionType[],
  isOpenMessage?:boolean,
  nickName?:string,
  type?:String,
}

export interface GlobalModelState {
  collapsed: boolean;
  notices: NoticeItem[];
  currentUser: CurrentUser;
  isLogin: boolean;
  fullScreen: boolean;
  instanceMap:{
    active:string;
    search?:string;
    instances:any[];
  };
  showTag:boolean
}

export interface GlobalModelType {
  namespace: 'global';
  state: GlobalModelState;
  effects: {
    fetchNotices: Effect;
    clearNotices: Effect;
    changeNoticeReadState: Effect;
    queryExclusiveManagerInfo: Effect;
    pushInstance: Effect;
    replaceInstance: Effect;
    removeInstance: Effect;
    modifyPhone:Effect;
    queryCurrent:Effect;
  };
  reducers: {
    changeLoginStatus: Reducer<GlobalModelState>;
    changeLayoutCollapsed: Reducer<GlobalModelState>;
    changeFullScreen: Reducer<GlobalModelState>;
    saveNotices: Reducer<GlobalModelState>;
    saveClearedNotices: Reducer<GlobalModelState>;
    saveInstances: Reducer<GlobalModelState>;
  };
  subscriptions: { setup: Subscription };
}

const GlobalModel: GlobalModelType = {
  namespace: 'global',

  state: {
    collapsed: false,
    notices: [],
    currentUser: {},
    isLogin:false,
    fullScreen:false,
    instanceMap:{
      active:'/',
      instances:[
        {path:'/',name:'总览'}
      ]
    },
    showTag:false
  },

  effects: {
    *modifyPhone({payload}, {call}){
      try {
        const {success,data}=yield call(modifyPhone,payload);
        return {success,data}
      }catch (e) {
        return {success:false,data:null}
      }
    },
    *queryCurrent({payload}, {call, put}) {
      const {success,data}  = yield call(queryCurrent);
      if(success){
        yield put({
          type: 'global/changeLoginStatus',
          payload: {
            currentAuthority:'ROLE_USER',
            isLogin: !!data.userId,
            currentUser:{
              id:data.userId,
              name:data.userName,
              company:data.customerName,
              companyId:data.customerId,
              email:data.email,
              phone:data.phone,
              avatar:data.headportraitid,
              nickName:data.nickName,
              isOpenMessage:data.isOpenMessage,
              type:data.type,
            }
          },
        });
      }
    },
    *fetchNotices(_, { call, put, select }) {
      // const data = yield call(queryNotices);
      const data:NoticeItem[]=[];
      yield put({
        type: 'saveNotices',
        payload: data,
      });
      const unreadCount: number = yield select(
        (state: ConnectState) => state.global.notices.filter(item => !item.read).length,
      );
      yield put({
        type: 'user/changeNotifyCount',
        payload: {
          totalCount: data.length,
          unreadCount,
        },
      });
    },
    *clearNotices({ payload }, { put, select }) {
      yield put({
        type: 'saveClearedNotices',
        payload,
      });
      const count: number = yield select((state: ConnectState) => state.global.notices.length);
      const unreadCount: number = yield select(
        (state: ConnectState) => state.global.notices.filter(item => !item.read).length,
      );
      yield put({
        type: 'user/changeNotifyCount',
        payload: {
          totalCount: count,
          unreadCount,
        },
      });
    },
    *changeNoticeReadState({ payload }, { put, select }) {
      const notices: NoticeItem[] = yield select((state: ConnectState) =>
        state.global.notices.map(item => {
          const notice = { ...item };
          if (notice.id === payload) {
            notice.read = true;
          }
          return notice;
        }),
      );

      yield put({
        type: 'saveNotices',
        payload: notices,
      });

      yield put({
        type: 'user/changeNotifyCount',
        payload: {
          totalCount: notices.length,
          unreadCount: notices.filter(item => !item.read).length,
        },
      });
    },
    *queryExclusiveManagerInfo({payload},{call}){
      const {data} = yield call(queryExclusiveManagerInfo,payload);
      return data;
    },
    *pushInstance({payload},{put,select}){
      const {instanceMap,currentUser} = yield select((state: ConnectState) => state.global);
      const {instances,active}=instanceMap;
      const {pathname,search}=payload;
      if(currentUser.id&&pathname&&active!==pathname){
        let flag:boolean=false;
        if(!(instances.some((instance:any)=>instance.path===pathname))){
          const endPoint=window['ENDPOINT'];
          const route=routes[endPoint]
            .filter((item:any)=>item.path&&pathname.match(item.path));
          let path;
          if('/'===pathname[0]){
            const paths=pathname.substring(1,pathname.length).split('/');
            path=paths[0];
          }
          if(path){
            path='/'+path;
          }

          const data=route[0].routes
            .filter((item:any)=>item.path&&item.path!=='/'&&item.path===path);
          const {name,isMenu}=getMenuName(data,pathname,'menu',false,true)
          if(!isMenu){
            flag=true;
            instances.push({
              name:formatMessage({ id: name==='menu'?'menu.home':name }),
              path:pathname,
              search:search,
            });
            instanceMap.instances=[...instances];
          }
        }
        if(flag){
          instanceMap.active=pathname;
          instanceMap.search=search;
          localStorage.setItem(`${currentUser.id}-tag`,JSON.stringify(instanceMap));
          put({
            type:'saveInstanceMap',
            payload:{...instanceMap}
          })
        }
      }
    },
    *replaceInstance({payload},{put,select}){
      const {pathname,search}=payload;
      const {instanceMap,currentUser} = yield select((state: ConnectState) => state.global);
      if(currentUser.id){
        const index=instanceMap.instances.findIndex((item:any)=>item.path===pathname);
        if(index!==-1){
          instanceMap.instances[index].path=pathname;
          instanceMap.instances[index].search=search;
          instanceMap.active=pathname;
          instanceMap.search=search;
          localStorage.setItem(`${currentUser.id}-tag`,JSON.stringify(instanceMap));
          put({
            type:'saveInstanceMap',
            payload:{
              ...instanceMap,
            }
          })
        }else{
          put({
            type:'pushInstance',
            payload:{
              pathname,
              search,
            }
          })
        }
      }
    },
    *removeInstance({payload},{put,select}){
      const {instanceMap,currentUser} = yield select((state: ConnectState) => state.global);
      const instances=instanceMap.instances;
      instanceMap.instances=[...instances.filter((instance:any)=>instance.path!==payload)];

      if(instanceMap.active===payload){
        const instance=instanceMap.instances[instanceMap.instances.length-1];
        instanceMap.active=instance.path;
        instanceMap.search=instance.search;
      }
      put({
        type:'saveInstanceMap',
        payload:{
          ...instanceMap,
        }
      })
      localStorage.setItem(`${currentUser.id}-tag`,JSON.stringify(instanceMap));
      if(instanceMap.search){
        router.replace({
          pathname:instanceMap.active,
          search:instanceMap.search,
        });
      }else{
        router.replace(instanceMap.active);
      }
    },
  },

  reducers: {
    // @ts-ignore
    changeLoginStatus(state, { payload }) {
      setAuthority(payload.currentAuthority);
      const currentUser=payload.currentUser || {};
      return {
        ...state,
        isLogin:payload.isLogin,
        // currentUser: payload.currentUser || {},
        currentUser: {
          ...currentUser,
          functions:currentUser.functions,
        },
      };
    },
    // @ts-ignore
    changeLayoutCollapsed(state:GlobalModelState, { payload }): GlobalModelState {
      return {
        ...state,
        collapsed: payload,
      };
    },
    // @ts-ignore
    changeFullScreen(state:GlobalModelState, { payload }:any): GlobalModelState {
      return {
        ...state,
        fullScreen: payload,
      };
    },
    // @ts-ignore
    saveNotices(state:GlobalModelState, { payload }): GlobalModelState {
      return {
        // @ts-ignore
        collapsed: false,
        ...state,
        notices: payload,
      };
    },
    // @ts-ignore
    saveClearedNotices(state:GlobalModelState, { payload }): GlobalModelState {
      return {
        // @ts-ignore
        collapsed: false,
        ...state,
        notices: state.notices.filter((item): boolean => item.type !== payload),
      };
    },
    // @ts-ignore
    saveInstanceMap(state:GlobalModelState, { payload }): GlobalModelState {
      return {
        ...state,
        instanceMap: payload,
      };
    },
    // @ts-ignore
    initInstanceMap(state:GlobalModelState, { payload }): GlobalModelState {
      return {
        ...state,
        instanceMap: {
          active:'/',
          instances:[
            {path:'/',name:'总览'}
          ]
        }
      };
    },
    // @ts-ignore
    saveShowTag(state:GlobalModelState, { payload }): GlobalModelState {
      return {
        ...state,
        showTag: payload
      };
    },
  },

  subscriptions: {
    setup(props): void {
      const {dispatch,history}=props;

      // Subscribe history(url) change, trigger `load` action if pathname is `/`
      history.listen((historyProps): void => {
        const { pathname, search }=historyProps;
        if(window['ENDPOINT']==='operation'){
          const route=routes['operation']
          const data=route.filter((item:any)=> {return item&&item.path==="/"})[0]?.routes
          const menu=getMenuName(data,pathname,'menu',false,true)
          if (menu&&!menu.isShowNav) {
            return
          }
          const {action}=history;
          if(action==='PUSH'){
            dispatch({
              type: 'pushInstance',
              payload:{
                pathname,
                search,
              }
            });
          }else if(action==='REPLACE'){
            dispatch({
              type: 'replaceInstance',
              payload:{
                pathname,
                search,
              }
            });
          }
        }
        if (typeof window.ga !== 'undefined') {
          window.ga('send', 'pageview', pathname + search);
        }
      });
    },
  },
};

export default GlobalModel;
