export default [
  {
    name:'login',
    path: '/login',
    component: './user/Login',
    hideInMenu :true
  },
  {
    name:'scanCodeLogin',
    path: '/scanCodeLogin',
    component: './user/Login/ScanCode',
    hideInMenu :true
  },
  {
    name:'Detail',
    path:'/resource-center/Detail',
    component:'./user/resourceCenter/Detail',
    hideInMenu: true,
    showBack:true,
  },
  {
    name: 'print',
    path: '/print/contract-print',
    component: './operation/order/salesOrder/Contract',
    hideInMenu: true,
  },
  {
    name: 'print',
    path: '/my/order/print',
    component: './user/Order/Print',
    hideInMenu:true
  },
  {
    name: 'chose-register',
    path: '/chose-register-identity',
    component: './user/Register/ChoseRegisterIdentity',
    hideInMenu :true
  },
  {
    name: 'change-password',
    path: '/chose-register-password',
    component: './user/Register/ChangePassword',
    hideInMenu :true
  },
  {
    name: 'user-register',
    path: '/user/register',
    component: './user/Register',
    hideInMenu :true
  },
  {
    name: 'wechat-register',
    path: '/wechatRegister',
    component: './user/Register/WechatRegister',
    hideInMenu :true
  },
  {
    name: 'supplier-register',
    path: '/supplier/register',
    component: './user/Register/SupplierRegister',
    hideInMenu :true
  },
  {
    path: '/JP',
    component: '../layouts/user/PhonePortalLayout',
    hideInMenu: true,
    routes: [
      {
        name:'mobileHome',
        path: '/JP',
        component: './user/HomeV2/MobileHomeJP',
      },
      {
        name:'founder',
        path: '/JP/personalIntro',
        component: './user/HomeV2/mobileHomeJP/Founder',
      },
      {
        name:'product',
        path: '/JP/product',
        component: './user/HomeV2/ProductJP',
      },
      {
        name:'device',
        path: '/JP/device',
        component: './user/HomeV2/DeviceJP',
      },
      {
        name:'contract',
        path: '/JP/contractUs',
        component: './user/HomeV2/mobileHomeJP/ContractUs',
      },
      {
        name:'consult',
        path: '/JP/consultService',
        component: './user/HomeV2/mobileHomeJP/ConsultService',
      },
    ]
  },
  {
    path: '/',
    component: '../layouts/user/PortalLayout',
    routes: [
      {
        name:'home_portal',
        path: '/',
        component: './user/HomeV2/home_portal',
        hideInMenu: true
      },
      {
        name: 'home',
        path: '/home',
        component: './user/HomeV2',
      },
      {
        name: 'my',
        path: '/my',
        routes: [
          {
            path: '/my',
            component: '../layouts/user/MyLayout',
            routes: [
              {
                path: '/my',
                redirect: '/my/order/list',
              },
              {
                name: 'my.overview',
                path: '/my/overview',
                icon: 'home',
                component: './user/Overview',
              },
              {
                name: 'my.inquiry',
                icon: 'FileSearchOutlined',
                path: '/my/inquiry',
                routes: [
                  {
                    name: 'form',
                    path: '/my/inquiry/form',
                    icon: 'FileAddOutlined',
                    component: './user/Inquiry/InquiryForm',
                  },
                  // {
                  //   name: 'noLogin ',
                  //   path: '/my/inquiry/noLogin',
                  //   icon: 'FileAddOutlined',
                  //   component: './user/Inquiry/InquiryNoLogin',
                  // },
                  // {
                  //   name:'test',
                  //   path: '/my/inquiry/handsontable',
                  //   component: './user/Inquiry/InquiryBatch/HandsontableExcel',
                  // },
                  {
                    name: 'batch',
                    path: '/my/inquiry/batch',
                    icon: 'FileAddOutlined',
                    component: './user/Inquiry/InquiryBatch/HandsontableExcel',
                  },
                  // {
                  //   name: 'ruleInquiry',
                  //   path: '/my/inquiry/ruleInquiry',
                  //   icon: 'FileAddOutlined',
                  //   component: './user/Inquiry/inquiryRule',
                  // },
                  // {
                  //   name: 'quickInquiry',
                  //   path: '/my/inquiry/quickInquiry',
                  //   icon: 'FileAddOutlined',
                  //   component: './user/Inquiry/InquiryBatch/HandsontableExcelSinoCam',
                  // },
                  {
                    name: 'list',
                    path: '/my/inquiry/list',
                    icon: 'FileSearchOutlined',
                    component: './user/Inquiry/InquiryList',
                  },
                  {
                    name: 'detail',
                    path: '/my/inquiry/detail',
                    component: './user/Inquiry/InquiryDetail',
                    hideInMenu:true
                  },
                  {
                    name: 'order',
                    path: '/my/inquiry/order',
                    component: './user/Inquiry/InquiryOrder',
                    hideInMenu:true
                  },
                ]
              },
              // {
              //   name: 'my.layout',
              //   icon: 'FileSearchOutlined',
              //   path: '/my/layout',
              //   routes: [
              //    {
              //       name: 'layoutBatch',
              //       path: '/my/layout/layoutBatch',
              //       icon: 'FileAddOutlined',
              //       component: './user/Layout/LayoutBatch',
              //     },
              //     {
              //       name: 'layoutList',
              //       path: '/my/layout/layoutList',
              //       icon: 'FileSearchOutlined',
              //       component: './user/Layout/LayoutList',
              //     },
              //     {
              //       name: 'layoutDetail',
              //       path: '/my/layout/layoutDetail',
              //       component: './user/Layout/LayoutDetail',
              //       hideInMenu :true
              //     },
              //   ]
              // },
              {
                name: 'my.order',
                icon: 'FileSearchOutlined',
                path: '/my/order',
                //hideChildrenInMenu:true,
                routes: [
                  {
                    path: '/my/order',
                    redirect: '/my/order/list',
                  },
                  {
                    name: 'list',
                    path: '/my/order/list',
                    icon: 'FileSearchOutlined',
                    component: './user/Order/OrderList',
                  },
                  {
                    name: 'appliedChangeList',
                    path: '/my/order/appliedChangeList',
                    icon: 'FileSearchOutlined',
                    component: './user/ChangeManagement/AlreadyAppliedChangeList',
                  },
                  {
                    name: 'changeContent',
                    path: '/my/order/appliedChangeList/changeContent',
                    component: './user/ChangeManagement/AlreadyAppliedChangeList/ChangeContent/OrderChangeContent',
                    hideInMenu:true,
                    showBack:true
                  },
                  {
                    name: 'changePriceConfirm',
                    path: '/my/order/appliedChangeList/changePriceConfirm',
                    component: './user/ChangeManagement/AlreadyAppliedChangeList/PriceConfirm',
                    hideInMenu:true,
                    showBack:true
                  },
                  {
                    name: 'detail',
                    path: '/my/order/detail',
                    component: './user/Order/OrderDetail',
                    hideInMenu:true
                  },
                  {
                    name: 'applyChange',
                    path: '/my/order/applyChange',
                    component: './user/ChangeManagement/ApplyChange',
                    hideInMenu:true
                  },
                  {
                    name: 'ChangePartOne',
                    path: '/my/order/applyChange/ChangePartOne',
                    component: './user/ChangeManagement/ApplyChange/ChangePartOne',
                    hideInMenu:true
                  },
                  {
                    name: 'PartChangeTable',
                    path: '/my/order/applyChange/PartChangeTable',
                    component: './user/ChangeManagement/ApplyChange/PartChangeTable/HandsontableExcel',
                    hideInMenu:true
                  },
                  {
                    name: 'PartChangeSubmit',
                    path: '/my/order/applyChange/PartChangeSubmit',
                    component: './user/ChangeManagement/ApplyChange/ChangePartSubmit',
                    hideInMenu:true,
                  },
                  {
                    name: 'anotherList',
                    path: '/my/order/anotherList',
                    component: './user/Order/InquiryOrder',
                    hideInMenu:true
                  },
                  {//批量支付
                    name: 'batch-payment-deposit',
                    path: '/my/order/batch-payment-deposit',
                    component: './user/Order/OrderList/BatchPaymentDepositForm',
                    showBack:true,
                    hideInMenu:true
                  },
                ]
              },
              {
                name: 'my.postSale',
                icon: 'BlockOutlined',
                path: '/my/postSale',
                routes: [{
                  name: 'list',
                  path: '/my/postSale/list',
                  component: './user/Order/postSale/List',
                },
                // {
                //   name: 'returnGoodsList',
                //   path: '/my/postSale/returnGoodsList',
                //   component: './user/Order/returnGoods/List',
                // },
                {
                  name: 'AlreadyAppliedList',
                  path: '/my/postSale/AlreadyApplied_list',
                  component: './user/Order/postSale/AlreadyApplied_list',
                },
                  {
                    name: 'postSale',
                    path: '/my/postSale/apply',
                    component: './user/Order/postSale/Form',
                    hideInMenu:true
                  },
                  {
                    name: 'postSaleDetail',
                    path: '/my/postSale/postSaleDetail',
                    component: './user/Order/postSale/Detail',
                    showBack:true,
                    hideInMenu:true
                  },
                  {
                    name: 'returnGoods',
                    path: '/my/postSale/returnGoodsApply',
                    component: './user/Order/returnGoods/Form',
                    hideInMenu:true
                  }]
              },
              {
                name: 'my.trade',
                icon: 'DollarOutlined',
                path: '/my/trade',
                routes: [{
                  name: 'pay',
                  path: '/my/trade/pay',
                  component: './user/Order/pay/List',
                },{
                  name: 'invoiceList',
                  path: '/my/trade/invoiceList',
                  component: './user/Order/invoice/List',
                }]
              },
              {
                name: 'my.part',
                icon: 'BlockOutlined',
                path: '/my/part',
                routes: [
                  {
                    name: 'list',
                    path: '/my/part/list',
                    component: './user/Part/PartList',
                  },
                  {
                    name: 'form',
                    path: '/my/part/form',
                    component: './user/Part/PartList/PartForm',
                    hideInMenu:true,
                  },
                  {
                    name: 'fixedPrice',
                    path: '/my/part/partFixedPrice',
                    component: './user/Part/PartList/PartsFixedPrice',
                    hideInMenu:true,
                  },
                  {
                    name: 'detail',
                    path: '/my/part/detail',
                    component: './user/Part/PartList/PartDetail',
                    hideInMenu:true,
                  },
                  // {
                  //   path: '/my/part/batch-add',
                  //   component: './user/Part/PartBatch',
                  // },
                  {
                    name: 'combine',
                    path: '/my/part/combine',
                    component: './user/Part/PartCombine',
                  },
                  // { //零件分类
                  //   name: 'category',
                  //   path: '/my/part/category',
                  //   component: './user/Part/PartCategory',
                  // },
                ],
              },
              {
                name: 'my.settlement',
                path: '/my/settlement',
                icon: 'TransactionOutlined',
                hideChildrenInMenu:true,
                routes: [
                  {
                    path: '/my/settlement',
                    redirect: '/my/settlement/list',
                  },
                  {
                    name: 'list',
                    path: '/my/settlement/list',
                    icon: 'TransactionOutlined',
                    component: './user/Settlement',
                  },
                ]
              },
              /*{
                name: 'my.invoice',
                path: '/my/invoice',
                icon: 'DollarOutlined',
                hideChildrenInMenu:true,
                routes: [
                  {
                    path: '/my/invoice',
                    redirect: '/my/invoice/list',
                  },
                  {
                    name: 'list',
                    path: '/my/invoice/list',
                    icon: 'DollarOutlined',
                    component: './user/Invoice/InvoiceList',
                  },
                ]
              },*/
              {
                name: 'my.setting',
                icon: 'setting',
                path: '/my/setting',
                routes: [
                  {
                    name: 'profile',
                    path: '/my/setting/profile',
                    component: './user/Setting/Profile',
                  },
                  {
                    name: 'unit-information',
                    path: '/my/setting/unit-information',
                    component: './user/Setting/Unit',
                  },
                  {
                    name: 'sub-account',
                    path: '/my/setting/sub-account',
                    component: './user/Setting/SubAccount',
                  },
                  {
                    name: 'invoice-information',
                    path: '/my/setting/invoice-information',
                    component: './user/Setting/Invoice',
                  },
                  {
                    name: 'receive-address',
                    path: '/my/setting/receive-address',
                    component: './user/Setting/ReceiveAddress',
                  },
                ]
              },
            ]
          },
        ]
      },
      {
        name: 'steel-plate-cutting',
        path: '/home2',
        component: './user/HomeV2',
      },

      {
        name: 'material-market',
        path: '/material-market',
        component: './user/MaterialMarket',
      },
      {
        name: 'noLoginInquiry',
        path: '/noLoginInquiry',
        component: './user/Inquiry/InquiryNoLogin',
        hideInMenu :true
      },
      /*{
        name: 'yuncut-matter',
        path: 'https://iot.yuncut.com/v/',
        //component: 'https://iot.yuncut.com/v/',
      },*/
      {
        name: 'resource-center',
        path: '/resource-center',
        // component: './user/resourceCenter',
        routes:[
          {
            path:'resourceCenter',
            redirect: '/resource-center'
          },
          {
            name:'home',
            path:'/resource-center',
            component:'./user/resourceCenter',
            hideInMenu: true,
            showBack:true,
          },
          {
            name:'List',
            path:'/resource-center/List',
            component:'./user/resourceCenter/List',
            hideInMenu: true,
            showBack:true,
          },
          {
            name:'VideoList',
            path:'/resource-center/VideoList',
            component:'./user/resourceCenter/VideoList',
            hideInMenu: true,
            showBack:true,
          },
          {
            name:'Detail',
            path:'/resource-center/Detail',
            component:'./user/resourceCenter/Detail',
            hideInMenu: true,
            showBack:true,
          },
          {
            name:'homeSearchPage',
            path:'/resource-center/Search',
            component:'./user/resourceCenter/Search',
            hideInMenu: true,
            showBack:true,
          }
        ]
      },
    ],
  },
];
