import { Reducer } from 'redux';
import {
  queryProvince, queryCity,
  queryDistric, queryLocationByIP
} from '@/services/common/district';
import {Effect} from "dva";
import {ConnectState} from "@/models/connect";
import { OptionType } from '@/types/basicLibrary';

export interface DistrictStateType {
  district:OptionType[]
}

export interface DistrictModelType {
  namespace: 'district';
  state: DistrictStateType;
  effects: {
    queryProvince:Effect;
    queryCity:Effect;
    queryDistric:Effect;
    queryLocationByIP:Effect;
  };
  reducers: {
    changeDistrict:Reducer<DistrictStateType>
  };
}
const DistrictModel: DistrictModelType = {
  namespace: 'district',
  state:{
    district:[]
  },
  effects:{
    *queryProvince(_, { call, put,select }) {
      const district:OptionType[] = yield select(
        (state: ConnectState) => state.district.district,
      );
      if(district.length>0){
        return ;
      }
      const {data} = yield call(queryProvince);
      console.log(data)
      yield put({
        type: 'changeDistrict',
        payload: data.province.map((item:OptionType)=>{return {...item,isLeaf: false}})
      });
    },
    *queryCity({payload}, { call, put,select }) {
      let district:OptionType[] = yield select(
        (state: ConnectState) => state.district.district,
      );
      if (!payload[0])return;
      const index=district.findIndex(item=>item.id===payload[0]);
      console.log(index)
      let province=district[index];
      console.log(
        province
      )
      if(province.children&&province.children.length>0){
        return ;
      }

      const {data} = yield call(queryCity,payload[0]);
      console.log(
        data
      )
      province.children=data.city.map((item:OptionType)=>{return {...item,isLeaf: false}});
      district[index]=province;

      yield put({
        type: 'changeDistrict',
        payload: [...district],
      });
    },
    *queryDistric({payload}, { call, put,select }) {
      let district:OptionType[] = yield select(
        (state: ConnectState) => state.district.district,
      );
      if (!payload[0])return;
      const provinceIndex=district.findIndex(item=>item.id===payload[0]);


      if (!payload[1])return;
      let province=district[provinceIndex];
      const cityIndex=province.children?.findIndex(item=>item.id===payload[1]);
      // @ts-ignore
      let city=province.children[cityIndex];
      if(city.children&&city.children.length>0){
        return ;
      }

      const {data} = yield call(queryDistric,payload[1]);
      city.children=data.distric.map((item:OptionType)=>{return {...item,isLeaf: true}});
      // @ts-ignore
      province[cityIndex]=city;
      district[provinceIndex]=province;

      yield put({
        type: 'changeDistrict',
        payload: [...district],
      });
    },
    *queryLocationByIP(_, { call}) {
      const {data} = yield call(queryLocationByIP);
      return data.district[0]||{};
    }
  },
  reducers: {
    changeDistrict(state, { payload }) {
      return {
        ...state,
        district: payload||[],
      };
    },
  }
};
export default DistrictModel;
