import request from "@/utils/request";

export async function query(factoryId:string,type?:string): Promise<any> {
  let params={
    factoryId:factoryId,
  }
  if(type){
    params['warehouseTypeCode']=type;
  }
  return request('/yuncut_supplier_db/ServerFunc_GetSupplierWarehouseList',{
    method:'POST',
    data:params
  });
}

export async function queryC(factoryId:string,type?:string): Promise<any> {
  let params={
    factoryId:factoryId,
  }
  if(type){
    params['warehouseTypeCode']=type;
  }
  return request('/yuncut_centralwarehouse_db/ServerFunc_GetWarehouseInfo',{
    method:'POST',
    data:params
  });
}
//查询所有中心仓余料仓库
export async function queryYl(factoryId:string): Promise<any> {
  return request('/yuncut_centralwarehouse_db/ServerFunc_GetWarehouseInfo',{
    method:'POST',
    data:{
      factoryId,
      warehouseTypeCode:"surplus"
    }
  });
}

//查询所有中心仓客供仓库
export async function queryKg(factoryId:string): Promise<any> {
  return request('/yuncut_centralwarehouse_db/ServerFunc_GetWarehouseInfo',{
    method:'POST',
    data:{
      factoryId,
      warehouseTypeCode:"customer"
    }
  });
}

//查询所有中心仓主材和余料仓库
export async function queryCy(factoryId:string,type?:string): Promise<any> {
  let params={
    factoryId:factoryId,
  }
  if(type==='mainMaterials' || type === 'surplus'){
    params['warehouseTypeCode']=type;
  }
  return request('/yuncut_centralwarehouse_db/ServerFunc_GetWarehouseInfo',{
    method:'POST',
    data:params
  });
}




export async function queryFilter(factoryId:string): Promise<any> {
  return request('/yuncut_supplier_db/ServerFunc_GetSupplierWarehouseList',{
    method:'POST',
    data:{
      factoryId,
      warehouseTypeCode:"mainMaterials"
    }
  });
}

export async function queryFilterCentre(factoryId:string): Promise<any> {//获取所有仓库的中心仓
  return request('/yuncut_centralwarehouse_db/ServerFunc_GetWarehouseInfo',{
    method:'POST',
    data:{
      factoryId,
      warehouseTypeCode:"mainMaterials"
    }
  });
}

export async function queryCustomerFilterCentre(factoryId:string): Promise<any> {//获取所有(客供仓库)的中心仓
  return request('/yuncut_centralwarehouse_db/ServerFunc_GetWarehouseInfo',{
    method:'POST',
    data:{
      factoryId,
      warehouseTypeCode:"customer"
    }
  });
}

export async function queryPartsFilter(factoryId:string): Promise<any> {
  return request('/yuncut_supplier_db/ServerFunc_GetSupplierWarehouseList',{
    method:'POST',
    data:{
      factoryId,
      warehouseTypeCode:"product"
    }
  });
}

export async function queryShelf(warehouseId:string): Promise<any> {
  return request('/yuncut_supplier_db/ServerFunc_GetSupplierShelfList',{
    method:'POST',
    data:{
      warehouseId:warehouseId
    }
  });
}


//查询工厂基础库收发类别
export async function queryIntoType(params:any):Promise<any>{
  return request('/yuncut_factory_base/ServerFunc_GetINtoTypeOption',{
    method:'POST',
    data:params
  });
}


//获取工厂供应商下拉列表
export async function querySupplierType(params:any):Promise<any>{
  return request('/yuncut_factory_customer/ServerFunc_Serlect_supplier',{
    method:'POST',
    data:params
  });
}
