import request from '@/utils/request';
//切割方式

export async function query(): Promise<any> {
  return request('/yuncut_base_cutmethod_db/ServerFunc_GetCutmethod_DropDown',{
    method:'POST',
  });
}


