import request from '@/utils/request';


export async function queryCurrent(): Promise<any> {
  return request('/yuncut_customer_login/ServerFunc_Customer_GetLoginInfo',{
    method: 'POST',
  });
}

export async function query(): Promise<any> {
  return request('/users',{
    method: 'POST',
  });
}

// export async function queryCurrentAccount(): Promise<any> {
//   return request('/yuncut_customer_login/ServerFunc_Customer_GetLoginAccountInfo');
// }

export async function queryAvatars(): Promise<any> {
  return request('/yuncut_sys_headportrait_db/ServerFunc_GetSysDefaultHeadPortrait',{
    method: 'POST',
  });
}

export async function saveCusAccountBase(payload:any): Promise<any> {
  return request("/yuncut_customer_db/ServerFunc_SaveCusAccountBase",{
    method: 'POST',
    data: {
      id:payload.id,
      account:payload.account,
      email:payload.email,
      phone:payload.phone,
    }
  });
}

export async function modifyPhone(payload:any): Promise<any> {//更换手机
  return request("/yuncut_customer_db/ServerFunc_Change_Phone",{
    method: 'POST',
    data: {
      phone:payload.phone,
      certCode:payload.certCode,
    }
  });
}

export async function modifyWechat(payload:any): Promise<any> {//更换微信
  return request("/yuncut_weixin_api/ServerFunc_UpdateUnbind_Cus",{
    method: 'POST',
    data: {
      code:payload.code,
    }
  });
}
export async function setMessage(payload:any): Promise<any> {//设置消息开关
  return request("/yuncut_customer_db/ServerFunc_SetMessage",{
    method: 'POST',
  });
}
export async function unboundWechat(payload:any): Promise<any> {//解除绑定微信
  return request("/yuncut_weixin_api/ServerFunc_UnBind_Cus",{
    method: 'POST',
  });
}
export async function boundWechat(payload:any): Promise<any> {//绑定微信
  return request("/yuncut_weixin_api/ServerFunc_Wechar_Unbind",{
    method: 'POST',
    data:payload,
  });
}


export async function modifyPassword(payload:any): Promise<any> {//更换密码
  return request("/yuncut_customer_db/ServerFunc_Change_Password",{
    method: 'POST',
    data: {
      password:payload.password,
      certCode:payload.certCode,
    }
  });
}

export async function modifyAvatar(fileId:string): Promise<any> {//更换密码
  return request("/yuncut_customer_db/ServerFunc_Chang_HeadPortrait",{
    method: 'POST',
    data: {
      FileId:fileId,
    }
  });
}

export async function sendCertCode(payload:any): Promise<any> {//校验验证码
  return request("/common_certcode_service/ServerFunc_CheckCertCode",{
    method: 'POST',
    data: {
      certCode:payload.certCode,
    }
  });
}


