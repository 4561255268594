export default {
  'user-login.login.userName': 'ユーザー名',
  'user-login.login.password': 'パスワード',
  'user-login.login.message-invalid-credentials': 'アカウントまたはパスワードが間違っています（admin/ant.design）',
  'user-login.login.message-invalid-verification-code': '認証コードが間違っています',
  'user-login.login.tab-login-credentials': 'アカウントパスワードでログイン',
  'user-login.login.tab-login-mobile': '携帯電話番号でログイン',
  'user-login.login.remember-me': '自動ログイン',
  'user-login.login.forgot-password': 'パスワードを忘れた',
  'user-login.login.sign-in-with': '他のログイン方法',
  'user-login.login.signup': 'アカウント登録',
  'user-login.login.login': 'ログイン',
  'user-login.register.register': '登録',
  'user-login.register.get-verification-code': '認証コードを取得',
  'user-login.register.sign-in': '既存のアカウントでログイン',
  'user-login.register-result.msg': 'あなたのアカウント：{email} が正常に登録されました',
  'user-login.register-result.activation-email':
    'アクティベーションメールがあなたのメールボックスに送信されました。メールは24時間有効です。メールボックスにログインし、メール内のリンクをクリックしてアカウントをアクティベートしてください。',
  'user-login.register-result.back-home': 'ホームに戻る',
  'user-login.register-result.view-mailbox': 'メールボックスを見る',
  'user-login.email.required': 'メールアドレスを入力してください！',
  'user-login.email.wrong-format': 'メールアドレスの形式が間違っています！',
  'user-login.userName.required': 'ユーザー名を入力してください！',
  'user-login.password.required': 'パスワードを入力してください！',
  'user-login.password.twice': '二回入力したパスワードが一致しません！',
  'user-login.strength.msg': '少なくとも6文字を入力してください。推測されやすいパスワードは使用しないでください。',
  'user-login.strength.strong': '強度：強',
  'user-login.strength.medium': '強度：中',
  'user-login.strength.short': '強度：短すぎる',
  'user-login.confirm-password.required': 'パスワードを確認してください！',
  'user-login.phone-number.required': '携帯電話番号を入力してください！',
  'user-login.phone-number.wrong-format': '携帯電話番号の形式が間違っています！',
  'user-login.verification-code.required': '認証コードを入力してください！',
  'user-login.title.required': 'タイトルを入力してください',
  'user-login.date.required': '開始日と終了日を選択してください',
  'user-login.goal.required': '目標の説明を入力してください',
  'user-login.standard.required': '基準を入力してください',
  'user-login.form.get-captcha': '認証コードを取得',
  'user-login.captcha.second': '秒',
  'user-login.form.optional': '（オプション）',
  'user-login.form.submit': '提出',
  'user-login.form.save': '保存',
  'user-login.email.placeholder': 'メール',
  'user-login.password.placeholder': '少なくとも6文字のパスワード、大文字と小文字を区別',

  'user-login.confirm-password.placeholder': 'パスワードを確認',
  'user-login.phone-number.placeholder': '携帯番号',
  'user-login.verification-code.placeholder': '認証コード',
  'user-login.title.label': 'タイトル',
  'user-login.title.placeholder': '目標に名前をつけてください',
  'user-login.date.label': '開始・終了日',
  'user-login.placeholder.start': '開始日',
  'user-login.placeholder.end': '終了日',
  'user-login.goal.label': '目標の説明',
  'user-login.goal.placeholder': '段階的な仕事の目標を入力してください',
  'user-login.standard.label': '測定基準',
  'user-login.standard.placeholder': '測定基準を入力してください',
  'user-login.client.label': 'クライアント',
  'user-login.label.tooltip': '目標のサービス対象',
  'user-login.client.placeholder': 'サービス対象のクライアントを説明してください。内部クライアントは直接 @名前／社員番号',
  'user-login.invites.label': '評価を依頼する人',
  'user-login.invites.placeholder': '直接 @名前／社員番号で、最大5人まで招待できます',
  'user-login.weight.label': '重み',
  'user-login.weight.placeholder': '入力してください',
  'user-login.public.label': '目標の公開',
  'user-login.label.help': 'クライアントと評価を依頼する人はデフォルトで共有されます',
  'user-login.radio.public': '公開',
  'user-login.radio.partially-public': '部分的に公開',
  'user-login.radio.private': '非公開',
  'user-login.publicUsers.placeholder': '公開対象',
  'user-login.option.A': '同僚A',
  'user-login.option.B': '同僚B',
  'user-login.option.C': '同僚C',
  'user-login.navBar.lang': '言語',
};
