// @ts-ignore
const userMenu = {
  //登录、注册、测试
  'menu.test': '测试页面',
  'menu.login': '登录',
  'menu.print':'订单打印',
  'menu.chose-register': '前往注册',
  'menu.user-register': '客户注册',
  'menu.supplier-register': '供应商注册',


  //账号
  'menu.account.profile': 'Personal information',
  'menu.account.logout': 'Sign out',

  //首页
  'menu.home': 'Home',
  'menu.steel-plate-cutting': 'Steel plate cutting',
  'menu.material-market': 'Materials market',
  /*'menu.yuncut-matter': '云切物联',*/
  //资源中心
  'menu.resource-center':'Resource Center',
  'menu.resource-center.home':'资源中心首页',
  'menu.resource-center.List':'列表数据',
  'menu.resource-center.VideoList':'视频列表',
  'menu.resource-center.Detail':'详情',
  'menu.resource-center.homeSearchPage':'搜索',
  //我的
  'menu.my': 'My YunCut',
  'menu.my.overview': '概览',

  //询价
  'menu.my.inquiry': '询价信息',
  'menu.my.inquiry.form': '立即询价',
  'menu.my.inquiry.batch': '批量询价',
  'menu.my.inquiry.noDrawing': '无图纸批量询价',
  /*'menu.my.inquiry.quickInquiry':'快速询价（内测）',
  'menu.my.inquiry.ruleInquiry':'单规则零件询价',*/
  'menu.my.inquiry.list': '询价记录',
  'menu.my.inquiry.change': '询价单变更',
  'menu.my.inquiry.detail': '询价详情',
  'menu.my.inquiry.order': '立即下单',

  //排版
  'menu.my.layout': 'SaaS排版',
  'menu.my.layout.layoutBatch': '批量排版',
  'menu.my.layout.layoutList': '排版记录',
  'menu.my.layout.layoutDetail': '排版详情',
  //订单
  'menu.my.order': '我的订单',
  'menu.my.order.list': '订单列表',
  'menu.my.order.detail': '订单详情',
  'menu.my.order.pay-balance-moeny': '付尾款',
  'menu.my.order.batch-payment-deposit':'批量支付',
  'menu.my.order.evaluate': '评价',
  'menu.my.order.anotherList': '再来一单',
  'menu.my.order.postSale': '售后申请',


  //订单变更
  'menu.my.change':'变更管理',
  'menu.my.change.list':'可申请列表',
  'menu.my.change.appliedChangeList':'已申请列表',
  'menu.my.order.appliedChangeList':'我的变更',
  'menu.my.order.changeContent':'变更内容',
  'menu.my.order.changePriceConfirm':'价格确认',
  'menu.my.order.applyChange':'申请变更',
  'menu.my.order.PartChangeTable':'申请变更',
  'menu.my.order.PartChangeSubmit':'申请变更',



  //零件
  'menu.my.part': '零件管理',
  'menu.my.part.list': '零件列表',
  'menu.my.part.form': '零件表单',
  'menu.my.part.detail': '零件详情',
  'menu.my.part.combine': '零件组合',
  'menu.my.part.category': '零件分类',
  'menu.my.part.fixedPrice': '固定价格下单',

  //结算
  'menu.my.settlement': '结算信息',
  'menu.my.settlement.list': '结算信息',

  //发票
  'menu.my.invoice': '发票管理',
  'menu.my.invoice.list': '发票管理',

  //设置
  'menu.my.setting': '设置',
  'menu.my.setting.profile': '个人信息',
  'menu.my.setting.unit-information': '企业信息',
  'menu.my.setting.sub-account': '子账号管理',
  'menu.my.setting.invoice-information': '开票信息管理',
  'menu.my.setting.receive-address': '收货地址管理',

  //售后
  'menu.my.postSale':'售后管理',
  'menu.my.postSale.postSale':'售后管理',
  'menu.my.postSale.list':'售后列表',
  'menu.my.postSale.returnGoodsList':'退货列表',
  'menu.my.postSale.returnGoods':'退货申请',
  'menu.my.postSale.AlreadyAppliedList':'已申请列表',
  'menu.my.postSale.postSaleDetail':'售后详情',

  //交易
  'menu.my.trade':'交易管理',
  'menu.my.trade.pay':'付款计划',
  'menu.my.trade.pay.list':'付款计划列表',
  'menu.my.trade.invoiceList':'发票信息',
};

const operationMenu = {

  //pdf预览
  'menu.pdfview': 'PDF预览',
  // 报错未定义键值
  'menu':'menu',
  'menu.qualityControl':'品质管控',
  'menu.qualityControl.dataLibrary':'云切资料库',
  'menu.qualityControl.dataLibraryDetail':'云切资料库详情',
  'menu.qualityControl.dataLibrary.dataLibraryDetail':'详情',
  'menu.qualityControl.experienceSharing':'经验分享',
  'menu.qualityControl.edit_experienceSharing':'新增/编辑',
  'menu.qualityControl.experienceSharing.experienceSharing':'经验分享详情',
  'menu.qualityControl.video':'视频',
  'menu.qualityControl.train': '培训',
  'menu.qualityControl.train.assessment-record':'培训详情',
  'menu.qualityControl.trainForm': '新增培训',
  'menu.qualityControl.train.trainForm':'新增/编辑培训',
  'menu.qualityControl.assessment-record': '考核过程记录',
  'menu.qualityControl.assessment-record-detail': '导出考核记录',


  'menu.qualityControl.train.assessment-record.assessment-record-detail':'导出记录表',
  'menu.qualityControl.spotCheck': '抽检',
  'menu.qualityControl.non-performingBill':'不良台账',
  'menu.qualityControl.non-performingBill.non-performingBill-List':'不良台账列表',
  'menu.qualityControl.non-performingBill-List':'不良台账列表',
  'menu.qualityControl.spotCheckForm': '新增抽检',
  'menu.qualityControl.spotCheck.spotCheckResult': '结束抽检',
  'menu.qualityControl.spotCheckResult':'结束抽检',
  'menu.qualityControl.spotCheck.spotCheckDetail': '抽检详情',
  'menu.qualityControl.spotCheckDetail':'抽检详情',
  'menu.qualityControl.spotCheck.spotCheckForm':'新增/编辑抽检',
  'menu.qualityControl.newsCenter':'新闻中心',
  'menu.qualityControl.newsCenter.newsCenterDetail':'新闻中心详情',
  'menu.qualityControl.newsCenterDetail':'新闻中心详情',
  'menu.qualityControl.rejectRatio':'不良/损失率',
  'menu.qualityControl.rejectRatioForm':'不良/损失率修改',
  'menu.qualityControl.badRateFactory':'PPM/COPQ不良率',
  'menu.qualityControl.badRateFactory.badRateFactoryForm':'PPM/COPQ不良率修改',

  'menu.login': '登录',
  'menu.account.profile': '个人信息',
  'menu.account.logout': '退出登录',

  //总览
  'menu.home': '总览',

  //询价
  'menu.inquiry': '询价管理',
  'menu.inquiry.first': '首次询价',
  'menu.inquiry.list': '询价列表',
  'menu.inquiry.change': '询价单变更',
  'menu.inquiry.detail': '询价详情',


  //日志管理
  'menu.logManagement': '日志管理',
  'menu.logManagement.orderLog': '销售订单日志',
  'menu.logManagement.materialPurchaseLog': '材料采购日志',
  'menu.logManagement.partsPurchaseLog': '零件采购日志',
  'menu.logManagement.deliverLog': '销售发货日志',
  'menu.logManagement.materialCustomerLog': '客供入库日志',
  'menu.logManagement.unNumberReportLog': '切割报工日志',
  'menu.logManagement.unQualityReportLog': '质量报工日志',
  'menu.logManagement.programLog': '程序钢板日志',
  'menu.logManagement.materialAllotLog': '材料调拨日志',
  'menu.logManagement.receiptLog': '收款单日志',
  'menu.logManagement.systemLog': '系统操作日志',
  'menu.logManagement.Interface': '第三方接口',
  'menu.logManagement.XMLDetail': 'XML详情',
  'menu.logManagement.integrationService': '集成服务日志',
  'menu.logManagement.integrationService.programChk': '兰特程序检查',
  'menu.logManagement.programChk': '兰特程序检查',

  'menu.deliver': '发货管理',
  'menu.deliver.can-deliver-list': '待发货',
  'menu.deliver.delivered-list': '已发货',
  'menu.deliver.can-deliver-procedure-list': '工序待发货',//yzl
  'menu.deliver.can-deliver-mergeDocuments-list': '合并发货单(马尼)',//yzl
  'menu.deliver.detail': '发货详情',
  'menu.deliver.form': '发货表单',
  'menu.deliver.logistic-list': '物流管理',
  'menu.deliver.logistic-order': '新增物流信息',
  'menu.deliver.transport-list': '物流公司管理',

  'menu.deliver.huolala-logistic-list': '货拉拉物流管理',
  'menu.deliver.huolala-logistic-order': '货拉拉新增物流信息',
  'menu.deliver.huolala-logistic-detail': '货拉拉物流信息详情',

  //订单
  'menu.order': '订单管理',
  'menu.order.sale-order': '销售订单',
  'menu.order.sale-order.list': '销售订单列表',

  //订单变更
  'menu.order.changeOrder':'变更管理',
  'menu.order.changeOrder.list':'变更列表',
  'menu.order.changeOrder.approvalInfo':'变更审核',
  'menu.order.changeOrder.changeContent':'变更内容',
  'menu.order.changeOrder.offerInfo':'变更报价',
  'menu.order.changeOrder.distributionInfo':'分配变更零件',
  'menu.order.sale-order.applyChange':'申请变更',
  'menu.order.sale-order.partChangeTable':'申请变更',
  'menu.order.sale-order.partChangeSubmit':'申请变更',
  //零件变更报表
  'menu.order.materialChange':'零件材质变更报表',
  'menu.order.materialChange.list':'零件材质变更列表',
  //订单变更-工厂变更
  'menu.order.changeOrder.factoryChangeIndex':'申请变更',

  'menu.order.sale-order.detail': '销售订单详情',
  'menu.order.sale-order.allocation': '销售订单分配',
  'menu.order.sale-order.examine-deliver': '审核发货',
  'menu.order.sale-order.batch-collection':'批量收款',
  'menu.my.order.applyChange':'申请变更',
  'menu.order.prod-plan': '生产计划',
  'menu.order.prod-plan.list': '生产计划列表',
  'menu.order.prod-plan.detail': '生产计划详情',
  'menu.order.prod-plan.refuseDetail': '生产计划拒绝详情',
  'menu.order.task': '作业列表',
  'menu.order.task.list': '作业列表',
  'menu.order.task.detail': '作业详情',
  'menu.order.program': '程序列表',
  'menu.order.program.list': '程序列表',
  'menu.order.program.detail': '程序详情',
  'menu.order.program.erpDetail': 'ERP同步日志',
  'menu.order.program.editProgramCuttingTimes':'程序修改切割次数',
  'menu.order.prod-order': '生产订单',
  'menu.order.prod-order.list': '生产订单列表',
  'menu.order.prod-order.detail': '生产订单详情',
  'menu.order.order-track':'生产计划跟踪',
  'menu.order.order-track.list':'生产计划跟踪',


  //后工序订单管理
  'menu.workProcess': '后工序订单管理',
  'menu.workProcess.list': '后工序订单列表',
  //后工序分配管理
  'menu.workProcess.partsList': '后工序分配列表',


  //yzl
  'menu.order.procedure-order':'后工序委外订单',
  'menu.order.procedure-order.list': '后工序委外订单列表',
  //lqg
  'menu.order.payment-order':'进度款订单',
  'menu.order.payment-order.list':'进度款订单列表',
  'menu.order.payment-order.detail':'进度款订单详情列表',

  //lqg
  'menu.order.customer-order': '客户跟踪订单',
  'menu.order.customer-order.list': '客户跟踪订单列表',




  //报工管理
  // 'menu.work-report': '报工管理',
  // 'menu.work-report.list': '报工管理',

  //补件管理
  'menu.supplement': '补件管理',
  'menu.supplement.can-supplement-list': '待补件',
  'menu.supplement.supplement-applying-list': '申请中',
  'menu.supplement.supplemented-list': '已补件',
  'menu.supplement.reject-supplement-list': '已拒绝',
  'menu.supplement.apply-form': '补件申请',
  'menu.supplement.PatchDetails': '补件申请',
  'menu.supplement.detail': '详情',
  'menu.supplement.badLoss-list': '不良损失',


  //财务管理
  'menu.finance': '财务管理',
  'menu.finance.list': '财务管理',
  'menu.finance.customer-period':'客户账期',
  'menu.finance.customer-period.list': '客户账期详情',
  'menu.finance.invoice-information':'发票管理',
  'menu.finance.invoice-information.list':'发票管理详情',
  'menu.finance.collection-plan':'收款计划',
  'menu.finance.collection-plan.list':'收款计划详情',
  'menu.finance.collection-plan.detail':'收款计划支付明细路由',
  'menu.finance.collection-plan.depositModification':'收款计划定金改款',
  'menu.finance.collection-plan.depositWriteOff':'收款计划定金核销',
  'menu.finance.collection-plan.progressModification':'收款计划进度款改款',
  'menu.finance.collection-plan.paymentWriteOff':'收款计划进度款核销',
  'menu.finance.collection-plan.toBeReviewed':'尾款审核',
  'menu.finance.collection-plan.advanceReviewed':'预收款审核',
  'menu.finance.payment-agreement':'收付款协议',
  'menu.finance.payment-agreement.list':'收付款协议详情',
  'menu.finance.collection-plan.splitOrder':'收款计划尾款拆单',
  'menu.finance.collection-plan.balanceModification':'尾款改款',
  'menu.finance.collection-plan.advanceModification':'预收款改款',
  'menu.finance.collection-plan.balanceWriteOff':'收款计划尾款核销',
  'menu.finance.collection-plan.balanceSubmit':'收款计划待确认提交',
  'menu.finance.collection-plan.advancePayment':'收款计划尾款增收预付款',
  'menu.finance.collection-plan.invoiceOffset':'对冲',





  //财务报表
  'menu.finance-report': '报表管理',
  'menu.finance-report.finance-produce': '委外订单报表',
  'menu.finance-report.finance-scrap': '废钢出入库报表',
  'menu.finance-report.finance-subcontract': '委外出入库报表',
  'menu.finance-report.purchase-order': '采购订单报表',
  'menu.finance-report.purchase-warehouse': '采购入库单报表',
  'menu.finance-report.material_delivery': '材料出库单报表',
  'menu.finance-report.sales-order': '销售订单报表',
  'menu.finance-report.salesPrice-order': '销售订单明细报表',
  'menu.finance-report.sales-shipment': '销售发货报表',
  'menu.finance-report.scrap-purchase': '废钢购入erp记录',
  'menu.finance-report.order-execution': '销售订单明细执行报表',
  'menu.finance-report.finance-ymAmbGrossProfit': 'YM阿米巴计算表',
  'menu.finance-report.ymAmbGrossProfit-transportation': 'YM阿米巴采购部门修改',
  'menu.finance-report.ymAmbGrossProfit-overdue': 'YM阿米巴供应链中心修改',
  'menu.finance-report.ymAmbGrossProfit-claimant': 'YM阿米巴财务部门修改',
  'menu.finance-report.finance-ymAmbGrossProfitAnnual': '年度YM阿米巴报表',
  'menu.finance-report.finance-ymAmbGrossProfitDept': 'YM阿米巴部门报表',
  'menu.finance-report.finance-ymAmbDeptReport': '(新)YM阿米巴部门报表',
  'menu.finance-report.finance-ymAmbProfitReportChart': '(新)YM阿米巴客户报表',

  //退货管理
  'menu.returns': '退货管理',
  'menu.returns.list': '退货管理',

  //客户管理
  'menu.customer': '客户管理',
  'menu.customer.user': '客户管理',
  'menu.customer.address': '地址管理',
  'menu.customer.address.list': '地址管理',
  'menu.customer.invoice': '开票管理',
  'menu.customer.invoice.list': '开票管理',
  'menu.customer.user.list': '客户列表',
  'menu.customer.user.detail': '客户详情',
  'menu.customer.part': '零件管理',
  'menu.customer.part.manage': '零件管理',
  'menu.customer.part.form': '零件表单',
  'menu.customer.part.detail': '零件详情',
  'menu.customer.custom-template': '定制模板',
  'menu.customer.consultService': '咨询管理',

  //系统管理
  'menu.system': '系统管理',
  'menu.system.user': '用户管理',
  'menu.system.role': '角色管理',
  'menu.system.weChatTemplate': '消息配置管理', //企业微信消息配置模板
  'menu.system.departmentTemplate': 'YM阿米巴部门管理', //阿米巴部门配置模板
  'menu.system.function': '功能管理',
  'menu.system.interface': '接口管理',
  'menu.system.announcement': '公告管理',
  'menu.system.statistics': '统计模板',
  'menu.system.dictionary': '数据字典',
  'menu.system.weight': '权重管理',

  'menu.contract-template': '合同模板管理',
  'menu.contract-template.list': '合同模板列表',

  //设置
  'menu.setting': '设置',
  'menu.setting.profile': '个人信息',

  'menu.material-library': '材料库管理',

  'menu.material-library.material-science': '材料仓库',
  'menu.material-library.material-science-form': '材料入库',
  'menu.material-library.material-surplus': '余料仓库',
  'menu.material-library.material-surplus-form': '余料入库',
  // 'menu.material-library.material-science-warehousing': '入库',
  'menu.material-library.material-purchase.warehousing':'入库',
  'menu.material-library.material-purchase.list': '材料采购',
  'menu.material-library.material-purchase.save': '新增采购',
  'menu.material-library.material-purchase.detail': '采购详情',
  'menu.product-library.partsPurchase-list': '零件采购',
  'menu.product-library.partsPurchase-save': '零件库采购',
  'menu.product-library.partsPurchase-order': '销售订单零件采购',
  'menu.product-library.partsPurchase-detail': '零件采购详情',
  'menu.product-library.partsPurchase-warehousing': '零件入库',
  'menu.product-library.productJournal-list': '产品流水账',
  'menu.material-library.material-summary': '余料统计汇总',

  'menu.material-library.material-type-management': '材质管理',
  'menu.material-library.material-supplier': '材料供应商管理',
  'menu.material-library.material-purchase': '材料采购',
  'menu.material-library.material-allocation': '材料调拨',
  'menu.material-library.management': '仓库管理',
  // 'menu.material-library.material-stockIn': '入库记录',
  'menu.material-library.material-taking-form': '盘点入库',
  'menu.material-library.material-taking-form-no-stock': '非库存盘点入库',


  // 'menu.material-library.material-stockOut': '出库记录',
  'menu.material-library.material-stockInOut': '出入库记录',
  'menu.material-library.material-stockInOut.list': '出入库记录',
  'menu.material-library.material-stockInOut.stockIn': '入库记录',
  'menu.material-library.material-stockInOut.stockOut': '出库记录',
  'menu.material-library.material-stockInOut.check': '库存盘点',
  'menu.material-library.material-stockInOut.noCheck': '非库存盘点',


  'menu.material-library.material-stockInItem': '入库明细',
  'menu.material-library.material-notPurchaseStockIn': '入库',
  'menu.material-library.material-SteelScrapStockOut': '废钢批量出库',
  'menu.material-library.material-StockOutOperation': '出库',
  'menu.material-library.choice-issue': '新盘点出库',
  'menu.material-library.steel-plate-split': '钢板拆分',
  'menu.material-library.material-stockOutItem': '明细信息',
  'menu.material-library.material-steelScrap': '废钢列表',
  'menu.material-library.material-steelScrap-warehouse': '废钢仓库',
  'menu.material-library.material-steelScrap.material-steelScrap-warehouse': '废钢仓库',
  'menu.material-library.material-customer': '客供材料',
  'menu.material-library.material-customer.list': '客供材料列表',
  'menu.material-library.material-customer.stock-in': '客供材料入库',
  'menu.material-library.material-customer.stock-out': '客供材料出库',
  'menu.material-library.material-reconciliation': '采购对账',
  'menu.material-library.material-reconciliation.list': '采购对账列表',
  'menu.material-library.material-materialStock': '库存预警',
  'menu.material-library.material-materialStock.list':'库存预警列表',
  'menu.material-library.material-InventoryReport': '库存月报',
  'menu.material-library.material-InventoryReport.list':'库存月报列表',
  'menu.material-library.material-Journal': '材料流水账',

  //中心仓管理
  'menu.centre-library':'中心仓管理',
  'menu.centre-library.centre-science':'云切库存',
  'menu.centre-library.centre-science-form': '材料入库',
  'menu.centre-library.centre-stockInOut': '出入库记录(中心仓)',
  'menu.centre-library.centre-stockInOut.list': '出入库记录',
  'menu.centre-library.centre-stockInOut.stockIn': '入库记录',
  'menu.centre-library.centre-stockInOut.stockOut': '出库记录',
  'menu.centre-library.centre-stockInOut.check': '库存盘点',
  'menu.centre-library.centre-stockInOut.noCheck': '非库存盘点',
  'menu.centre-library.centre-stockInItem': '入库明细',
  'menu.centre-library.centre-notPurchaseStockIn': '入库',
  'menu.centre-library.centre-StockOutOperation': '出库',
  'menu.centre-library.centre-ReturnGoodsOperation': '材料退货',
  'menu.centre-library.steel-plate-split': '钢板拆分',
  'menu.centre-library.centre-stockOutItem': '明细信息',
  'menu.centre-library.centre-customer': '客供库存(中心仓)',
  'menu.centre-library.centre-customer.list': '客供材料列表',
  'menu.centre-library.centre-customer.stock-in': '客供材料入库',
  'menu.centre-library.centre-customer.stock-out': '客供材料出库',
  'menu.centre-library.centre-allocation': '材料调拨(中心仓)',
  'menu.centre-library.centre-purchase.warehousing':'入库',
  'menu.centre-library.centre-purchase': '材料采购(中心仓)',
  'menu.centre-library.centre-purchase.list': '材料采购列表',
  'menu.centre-library.centre-purchase.save': '新增采购(中心仓)',
  'menu.centre-library.centre-purchase.save.saveSurplus': '新增余料采购(中心仓)',
  'menu.centre-library.centre-purchase.detail': '采购详情',
  'menu.centre-library.centre-InventoryReport':'库存月报(中心仓)',
  'menu.centre-library.centre-InventoryReport.list':'库存月报列表',
  'menu.centre-library.centre-surplus':'余料仓库(中心仓)',
  'menu.centre-library.centre-surplus.list':'余料仓库列表',
  'menu.centre-library.centre-surplus-form':'余料入库',
  'menu.centre-library.centre-allocation.approveWarehoue':'调拨审查',
  'menu.centre-library.centre-centreMaterialStock': '中心仓库存预警',
  'menu.centre-library.centre-centreMaterialStock.list': '中心仓库存预警列表',
  'menu.centre-library.centre-purchase.saveSurplus':'未定义',

  //基础库
  'menu.basic-library': '基础库管理',
  'menu.basic-library.material-texture': '材质管理',
  'menu.basic-library.plate-thickness': '板厚管理',
  'menu.basic-library.cut-method': '切割工艺管理',
  'menu.basic-library.cut-seam': '切割缝管理',
  'menu.basic-library.contract-template': '合同模板管理',
  'menu.basic-library.quality-book': '质保书管理',
  'menu.basic-library.work-order': '工序管理',
  'menu.basic-library.material-brand': '钢厂管理',
  'menu.basic-library.material-standard': '材质标准管理',
  'menu.basic-library.material-category': '材质品类管理',
  'menu.basic-library.postSale_badType':'售后不良分类',


  // 'menu.basic-library.global-use-rate':'全局利用率',
  // 'menu.basic-library.material-type-list':'材质列表',
  // 'menu.basic-library.material-standard-list':'材质标准列表',
  // 'menu.basic-library.material-thickness-list':'材质厚度列表',
  // 'menu.basic-library.material-brand-list':'材质钢厂列表',
  // 'menu.basic-library.equipment-brand-list':'设备钢厂列表',
  // 'menu.basic-library.material-category-list':'材质品类列表',
  // 'menu.basic-library.cutting-method-list':'切割工艺列表',
  // 'menu.basic-library.cutting-seam-list':'切割缝列表',
  // 'menu.basic-library.Warranty-list':'质保书管理',
  // 'menu.basic-library.formula-define-list':'公式定义',
  // 'menu.basic-library.system-taxRate':'系统税率',
  // 'menu.basic-library.contracts-template':'合同模板管理',

  'menu.supplier': '供应商管理',
  'menu.supplier.material-supplier': '材料供应商管理',
  'menu.supplier.detail': '详情',

  'menu.supplier.firstContact':'首次联系',
  'menu.supplier.groupPermiss':'分组权限',
  'menu.supplier.groupPermiss.groupMember':'分组成员',
  'menu.supplier.supFunctionManage':'功能管理',
  'menu.supplier.subAccount':'供应商子账号',

  'menu.supplier.supplier': '切割供应商管理',
  'menu.supplier.supplier.detail': '详情',
  'menu.supplier.supplierAdd': '工厂管理',
  'menu.supplier.supplierDevice-information': '设备列表',
  'menu.supplier.cameraYsList': '摄像头信息',

  'menu.base-price': '销售单价管理',
  'menu.base-price.base-price-save':'销售单价导入',

  'menu.product-library': '产品库管理',
  'menu.product-library.list': '产品列表',
  'menu.product-library.stock-taking-form': '库存盘点',
  'menu.product-library.product-ProductOutOperation': '盘点出库',
  'menu.product-library.product-ProductInOperation': '盘点入库',
  'menu.product-library.partsProcedure-list': '后工序产品',
  'menu.product-library.partsProcedure-stock-taking-form': '盘点入库',

  'menu.product-library.storehouse-out-list': '出库记录',
  'menu.product-library.storehouse-out-detail': '出库详情',
  'menu.product-library.storehouse-in-list': '入库记录',
  'menu.product-library.storehouse-in-detail': '入库详情',
  // 产品列表
  'menu.product-library.productStorage':'产品暂存入库',
  'menu.product-library.productStorage.productStorageForm':'产品入库详情',
  'menu.product-library.productStorageForm':'产品入库详情',

  'menu.weight': '权重管理',
  'menu.weight.weight-type': '权重管理',
  'menu.weight.formula-define-list': '公式定义',
  'menu.weight.system-taxRate': '系统税率',
  'menu.weight.global-use-rate': '全局利用率',
  'menu.weight.globalSetting': '全局时间查询',
  'menu.weight.global-use-rate-save':'全局利用率导入',

  'menu.operation-delivery-management': '发货管理',
  'menu.operation-reported-management': '报工管理',


  'menu.logistics': '物流管理',
  'menu.logistics.list': '物流列表',

  'menu.freight':'运费管理',
  'menu.freight.list':'运费列表',

  //结算管理
  'menu.settlement': '结算管理',
  'menu.settlement.list': '结算列表',
  'menu.settlement.settlement_mtSale': '材料销售订单',
  'menu.settlement.settlement_mtSale.settlement_addMtSale': '新增材料销售订单',
  'menu.settlement.settlement_addMtSale.settlement_addMtSaleExcelint': '导入材料销售订单',
  'menu.settlement.settlement_addMtSaleExcelint': '导入材料销售订单',
  'menu.settlement.settlement_addMtSale': '新增材料销售订单',
  'menu.settlement.settlement_mtSale.settlement_mtSaleDetail': '材料销售订单详情',
  'menu.settlement.settlement_mtSaleDetail': '材料销售订单详情',
  'menu.settlement.settlement_mtSale.settlement_mtSaleDeliverDetail': '发货单',
  'menu.settlement.settlement_mtSaleDeliverDetail': '发货单',
  'menu.settlement.settlement_mtSale.settlement_mtSaleDeliver': '材料销售订单发货',
  'menu.settlement.settlement_mtSaleDeliver': '材料销售订单发货',

  'menu.settlement.materialTransformation-list': '材料形态转换',
  'menu.settlement.materialTransformation-save': '新增材料形态转换',

  'menu.settlement.settlementCenter': '结算中心',
  'menu.settlement.settlementCenter.settlementDetail': '结算明细',
  'menu.settlement.settlementDetail': '结算明细',
  'menu.settlement.settlementPaymentPlan.planSettlementDetail': '结算',
  'menu.settlement.planSettlementDetail': '结算',
  'menu.settlement.settlementCenter.paymentApplyDetail': '付款申请明细',
  'menu.settlement.paymentApplyDetail': '付款申请明细',
  'menu.settlement.settlementCenter.settlementCenter_PaymentApply': '交易申请',
  'menu.settlement.settlementCenter_PaymentApply': '交易申请',
  'menu.settlement.settlementPaymentPlan': '结算明细单',
  'menu.settlement.settlementInvoice': '发票信息',
  'menu.settlement.claimList': '索赔单',
  'menu.settlement.prodList': '生产订单',
  'menu.settlement.partsPurchaseList': '零件采购订单',
  'menu.settlement.procedureOrderList': '后工序委外订单',

  'menu.statistics': '统计分析',
  'menu.statistics.list': '统计列表',


  //售后
  'menu.postSale':'售后管理',
  'menu.postSale.postSale-index':'售后申请',
  'menu.postSale.postSale-list':'售后列表',
  'menu.postSale.sale_detail':'售后详情',
  'menu.postSale.postSale-list.sale_detail':'售后详情',
  'menu.postSale.return_repair':'退货返修',
  'menu.postSale.missing_parts':'缺件补件',
  'menu.postSale.scrap_supplement':'报废补件',
  'menu.postSale.concession_receive':'让步接收',
  'menu.postSale.return_cancel':'退货/缺件取消',
  'menu.postSale.claim':'发起索赔',
  'menu.postSale.claimList':'索赔单列表',
  'menu.postSale.claimList_detail':'索赔详情',
  'menu.postSale.claimList.claimList_detail':'索赔详情',
  'menu.postSale.FreeAudit':'费用审核',
  'menu.postSale.otherClaim':'其他索赔',
  'menu.postSale.order_detail':'订单详情',
  'menu.postSale.badReportList':'不良报告列表',


  'menu.postSale.machineSchedule':'在线排产',


  //供应商发票信息管理、收货信息管理
  'menu.supplier.Op_supInvoice':'开票管理',
  'menu.supplier.Op_supAddress':'地址管理',
};

// @ts-ignore
const supplierMenu = {
  //账号
  'menu.account.profile': '个人信息',
  'menu.account.logout': '退出登录',
  // 供应商端
  'menu.home': '首页',
  'menu.deliverPrint': '发货清单',
  //生产计划
  'menu.prod-plan': '生产计划',
  'menu.prod-plan.list': '列表-生产计划',
  'menu.prod-plan.detail': '详情-生产计划',
  'menu.order-track':'生产计划跟踪',
  'menu.order-track.list':'列表-生产计划跟踪',

  //程序管理
  'menu.program': '程序管理',
  'menu.program.list': '程序列表',
  'menu.program.summary': '报工汇总',
  'menu.program.detail': '程序详情',
  'menu.program.report': '程序报工',
  'menu.program.schedule': '日程列表',
  //生产订单
  'menu.prod-order': '生产订单',
  'menu.prod-order.list': '列表-生产订单',
  'menu.prod-order.detail': '详情-生产订单',

  //报工管理
  // 'menu.reported-work': '报工管理',
  // 'menu.reported-work.number-list': '报数量列表',
  // 'menu.reported-work.quality-list': '报质量列表',
  // 'menu.reported-work.form': '报质量列表',

  //发货管理
  'menu.deliver': '发货管理',
  'menu.deliver.list': '已发货',
  'menu.deliver.detail': '发货详情',
  'menu.deliver.can-deliver-list': '待发货',
  'menu.deliver.form': '待发货',

  //补件管理
  'menu.supplement': '补件管理',
  'menu.supplement.list': '待补件',
  'menu.supplement.CanSupplement-patchDetails': '补件详情',
  'menu.supplement.ApplySupplementList': '申请中',
  'menu.supplement.SupplementedList': '已补件',
  'menu.supplement.RejectSupplementList': '已拒绝',

  //零件库
  'menu.product-library': '产品库管理',
  'menu.product-library.productLibrary':'产品库',
  'menu.product-library.productStockInOutReport':'产品流水账',
  'menu.product-library.productStockInSupplier':'入库记录',
  'menu.product-library.productStockOutSupplier':'出库记录',
  'menu.product-library.productStorehouse-in-detail': '入库单详情',
  'menu.product-library.productStorehouse-out-detail': '出库单详情',

  //结算信息
  'menu.settlement': '结算信息',
  'menu.settlement.list': '结算信息',
  'menu.settlement.detail': '结算详情',

  //材料库
  'menu.material-library': '材料库',
  'menu.material-library.material': '材料仓库',
  'menu.material-library.surplus-material': '余料仓库',
  'menu.material-library.steelScrap-material': '废钢列表',
  'menu.material-library.materialPurchase': '主材采购',
  'menu.material-library.surplusPurchase':'余料采购',
  'menu.material-library.materialScience':'材料调拨',
  'menu.material-library.materialStockInOutReport':'材料流水账',

  //我的工厂
  'menu.my-salesOrder':'我的工厂',
  'menu.my-salesOrder.my-factory': '销售管理',
  'menu.my-salesOrder.my-factory.businessNavigator':'业务导航',
  'menu.my-salesOrder.my-factory.businessNavigator.list':'业务导航列表',
  'menu.my-salesOrder.my-factory.order':'销售订单',
  'menu.my-salesOrder.my-factory.order.list':'销售订单列表',
  'menu.my-salesOrder.my-factory.orderReport':'销售明细报表',
  'menu.my-salesOrder.my-factory.prodOrder':'生产订单',
  'menu.my-salesOrder.my-factory.prodOrder.list': '列表-生产订单',
  'menu.my-salesOrder.my-factory.prodOrder.detail': '详情-生产订单',
  'menu.my-salesOrder.my-factory.prodOrder.edit':"修改-生产订单",
  'menu.my-salesOrder.suProdOrder':'生产管理',
  'menu.my-salesOrder.suProdOrder.businessNavigator':'业务导航',
  'menu.my-salesOrder.suProdOrder.businessNavigator.list':'业务导航列表',
  'menu.my-salesOrder.suProdOrder.list':'生产订单',
  'menu.my-salesOrder.suProdOrder.detail':'详情-工厂生产订单',
  'menu.my-salesOrder.suProdOrder.edit':'修改-工厂生产订单',
  'menu.my-salesOrder.basic-archives.BatchImportPartsForm':"批量导入零件",
  /*'menu.my-factory.workReport':'报工管理',
  'menu.my-factory.workReport.list': '列表-报工管理',*/
  'menu.my-salesOrder.my-factory.deliver':'发货管理',
  'menu.my-salesOrder.my-factory.deliver.list': '发货管理',
  'menu.my-salesOrder.my-factory.deliver.detail': '发货详情',
  'menu.my-salesOrder.my-factory.deliver.save': '新增发货单',
  'menu.my-salesOrder.my-factory.deliver.MaterialSave':'新增材料发货单',
  'menu.my-salesOrder.my-factory.deliver.materialDetail':'材料发货单详情',
  'menu.my-salesOrder.my-factory.prodOrder.work':'生产订单报工',
  'menu.my-salesOrder.my-factory.prodOrder.save':'新增生产订单',
  'menu.my-salesOrder.suProdOrder.work':'生产订单报工',
  'menu.my-salesOrder.suProdOrder.save':'新增生产订单',
  'menu.my-salesOrder.my-factory.deliver.partsEdit':'产品发货单修改',
  'menu.my-salesOrder.my-factory.deliver.materialEdit':'材料发货单修改',

  'menu.my-salesOrder.my-factory.order.PartsDetail':'零件销售订单详情',
  'menu.my-salesOrder.my-factory.order.MaterialDetail':'材料销售订单详情',
  'menu.my-salesOrder.my-factory.order.save':'新增零件销售订单',
  'menu.my-salesOrder.my-factory.order.form':'修改零件销售订单',
  'menu.my-salesOrder.my-factory.order.materialForm':'修改材料销售订单',
  'menu.my-salesOrder.my-factory.order.materialSave':'新增材料销售订单',
  'menu.my-salesOrder.stock-management.productStockIn.list':'产品入库列表',
  'menu.my-salesOrder.stock-management.productStockIn.detail':'产品入库详情',
  'menu.my-salesOrder.stock-management.productStockIn.save':'新增-产品入库',
  'menu.my-salesOrder.stock-management.productStockOut.list':'产品出库列表',
  'menu.my-salesOrder.stock-management.productStockOut.detail':'产品出库详情',
  'menu.my-salesOrder.stock-management.productStockOut.save':'新增-产品出库',
  'menu.my-salesOrder.stock-management.materialStockIn.list':'材料入库列表',
  'menu.my-salesOrder.stock-management.material-stockOutItem':'材料出库详情',
  'menu.my-salesOrder.stock-management.materialStockIn.save':'新增-材料入库',
  'menu.my-salesOrder.stock-management.material-stockInItem':'材料入库详情',
  'menu.my-salesOrder.stock-report.materialStock.list':'材料库存列表',
  'menu.my-salesOrder.stock-report.productStockInOutItem.list':'产品出入库',
  'menu.my-salesOrder.stock-report.materialStockInOutItem.list':'材料出入库',
  'menu.my-salesOrder.stock-report.productStock.list':'产品库存列表',
  'menu.my-salesOrder.stock-management.materialAllocation.list':'调拨列表',
  'menu.my-salesOrder.stock-management.materialAllocation.detail':'调拨单详情',
  'menu.my-salesOrder.stock-management.materialStockOut.save':'保存材料出库',
  'menu.my-salesOrder.stock-management.materialStockOut.list':'材料出库列表',
  'menu.my-salesOrder.stock-management.materialAllocation.product-save':'保存产品调拨',
  'menu.my-salesOrder.stock-management.materialAllocation.material-save':'保存材料调拨',
  'menu.my-salesOrder.my-factory.collection':'收款申请',
  'menu.my-salesOrder.my-factory.sales-freight':'销售运费',
// /my-salesOrder/material-purchase/sales-freight
  'menu.my-salesOrder.my-factory.sales-invoice':'销售发票申请',
  'menu.my-salesOrder.my-factory.sales-invoice.list':'材料销售发票信息',
  'menu.my-salesOrder.my-factory.sales-invoice.material-add':'申请材料销售发票信息',
  'menu.my-salesOrder.my-factory.sales-invoice.part-add':'申请零件销售发票信息',
  'menu.my-salesOrder.my-factory.sales-invoice.part-edit':'修改零件销售发票信息',
  'menu.my-salesOrder.my-factory.sales-invoice.material-edit':'修改材料销售发票信息',
  'menu.my-salesOrder.material-purchase.material-management.materialPurchaseEdit':'修改材料采购入库单',
  'menu.my-salesOrder.material-purchase.material-management.partPurchaseEdit':'修改零件采购入库单',
  'menu.my-salesOrder.my-factory.sales-invoice.part-detail':'零件销售发票详情',
  'menu.my-salesOrder.my-factory.sales-invoice.material-detail':'材料销售发票详情',
  // 'menu.my-salesOrder.material-purchase.material-management.partPurchaseEdit':'',
  //工厂库存管理
  'menu.my-salesOrder.stock-management': '库存管理',
  'menu.my-salesOrder.stock-management.businessNavigator':'业务导航',
  'menu.my-salesOrder.stock-management.businessNavigator.list':'业务导航列表',
  'menu.my-salesOrder.stock-management.productStockIn':'产品入库单',
  'menu.my-salesOrder.stock-management.productStockOut':'产品出库单',
  'menu.my-salesOrder.stock-management.materialStockIn':'材料入库单',
  'menu.my-salesOrder.stock-management.materialStockOut':'材料出库单',
  'menu.my-salesOrder.stock-management.materialAllocation':'调拨单',
  'menu.my-salesOrder.stock-management.productStockIn.edit':'修改产品入库单',
  'menu.my-salesOrder.stock-management.productStockOut.proStockOutEdit':'修改产品出库单',
  'menu.my-salesOrder.stock-management.materialStockIn.matStockInEdit':'修改材料入库单',
  'menu.my-salesOrder.stock-management.materialStockOut.matStockOutEdit':'修改材料出库单',
  'menu.my-salesOrder.stock-management.materialAllocation.product-edit':'修改产品调拨单',
  'menu.my-salesOrder.stock-management.materialAllocation.material-edit':'修改材料调拨单',


//工厂库存报表
  'menu.my-salesOrder.stock-report': '库存报表管理',
  'menu.my-salesOrder.stock-report.productStock':'产品库存表',
  'menu.my-salesOrder.stock-report.materialStock':'材料库存表',
  'menu.my-salesOrder.stock-report.materialStockInOutItem':'材料出入库明细表',
  'menu.my-salesOrder.stock-report.productStockInOutItem':'产品出入库明细表',
  'menu.my-salesOrder.stock-report.realTimeStock':'云切废钢库存',
  'menu.my-salesOrder.stock-report.realTimeStock.list':'云切废钢库存列表',
  'menu.my-salesOrder.stock-report.realTimeStock.redRushScrap':'红冲云切废钢',
  'menu.my-salesOrder.stock-report.businessNavigator':'废钢业务导航',

  //工厂基础档案
  'menu.my-salesOrder.basic-archives': '基础档案',
  'menu.my-salesOrder.basic-archives.businessNavigator':'业务导航',
  'menu.my-salesOrder.basic-archives.businessNavigator.list':'业务导航列表',
  'menu.my-salesOrder.basic-archives.part-file':'工厂存货档案',
  'menu.my-salesOrder.basic-archives.customer-profile':'工厂客户档案',
  'menu.my-salesOrder.basic-archives.detail':'客户详情',
  'menu.my-salesOrder.basic-archives.supplier-file':'工厂供应商档案',
  'menu.my-salesOrder.basic-archives.warehouse archives':'工厂仓库档案',
  'menu.my-salesOrder.basic-archives.sendReceive-category':'工厂收发类别',
  'menu.my-salesOrder.basic-archives.scrapPriceArchives':'工厂废钢当月单价',
  'menu.my-salesOrder.basic-archives.operating-log':'工厂操作系统日志',

  //工厂财务管理
  'menu.my-salesOrder.finance': '财务管理',
  'menu.my-salesOrder.finance.businessNavigator':'业务导航',
  'menu.my-salesOrder.finance.businessNavigator.List':'业务导航列表',
  'menu.my-salesOrder.finance.list': '运费单查询',
  'menu.my-salesOrder.finance.customer-period':'收付款确认',
  'menu.my-salesOrder.finance.customer-period.list': '发票确认',
  'menu.my-salesOrder.finance.invoice-information':'发票确认',
  'menu.my-salesOrder.finance.payment-report':'收付款报表',
  'menu.my-salesOrder.finance.payment-report.list':'收付款报表',
  'menu.my-salesOrder.finance.invoice-information.list':'发票管理详情',
  'menu.my-salesOrder.finance.invoice-information.partSalesDetail':'零件发票详情',
  'menu.my-salesOrder.finance.invoice-information.materialSalesDetail':'材料发票详情',


  //工厂采购管理
  'menu.my-salesOrder.material-purchase': '采购管理',
  'menu.my-salesOrder.material-purchase.businessNavigator': '业务导航',
  'menu.my-salesOrder.material-purchase.businessNavigator.list': '业务导航列表',
  'menu.my-salesOrder.material-purchase.material-management': '采购入库单',
  'menu.my-salesOrder.material-purchase.payment' : '付款申请',
  'menu.my-salesOrder.material-purchase.purchase-invoice' : '  采购发票申请',
  'menu.my-salesOrder.material-purchase.purchase-freight' : '  采购运费',
  'menu.my-salesOrder.material-purchase.material-management.list':'采购入库列表',
  'menu.my-salesOrder.material-purchase.material-management.partPurchaseSave':'产品采购入库',
  'menu.my-salesOrder.material-purchase.material-management.materialPurchaseSave':'材料采购入库',
  'menu.my-salesOrder.material-purchase.material-management.materialPurchaseDetail':'材料采购详情',
  'menu.my-salesOrder.material-purchase.purchase-invoice.save':'材料采购发票申请',
  'menu.my-salesOrder.material-purchase.purchase-invoice.list':'采购申请列表',
  'menu.my-salesOrder.material-purchase.purchase-invoice.materialDetail':'材料采购发票详情',
  'menu.my-salesOrder.material-purchase.purchase-invoice.partDetail':'零件采购发票详情',
  'menu.my-salesOrder.material-purchase.purchase-invoice.edit':'修改材料采购发票',
  'menu.my-salesOrder.material-purchase.material-management.partPurchaseDetail':'零件采购入库单详情',
  'menu.my-salesOrder.material-purchase.purchase-invoice.partSave':'申请零件采购发票',
  'menu.my-salesOrder.material-purchase.purchase-invoice.partEdit':'修改零件采购发票',
  //
  // 'menu.my-salesOrder.material-purchase.material-science-form': '材料入库',
  // 'menu.my-salesOrder.material-purchase.material-surplus': '余料仓库',
  // 'menu.my-salesOrder.material-purchase.material-surplus-form': '余料入库',
  // 'menu.my-salesOrder.material-purchase.material-purchase':'采购管理',


  //setting
  'menu.setting': '设置',
  'menu.setting.profile': '个人信息',
  'menu.setting.subAccount': '子账号管理',
  'menu.setting.supplierRole': '角色管理',
  'menu.setting.roleMember': '角色管理',
  'menu.setting.unit-information': '单位信息',
  'menu.setting.factory-management': '工厂管理',
  'menu.setting.cutting-method': '切割方式',
  'menu.setting.cutting-unit-price': '切割单价',
  'menu.setting.device-information': '设备信息',
  'menu.setting.payment-method': '收款方式',
  'menu.setting.stock-manager': '仓库管理 ',
  'menu.setting.invoice-manager': '发票信息管理 ',
  'menu.setting.receiveAddress-manager': '收货信息管理 ',
  //品质管理
  'menu.qualityControl':'品质管理',
  'menu.qualityControl.dataLibrary':'云切资料库',
  'menu.qualityControl.dataLibraryDetail':'云切资料库详情',
  'menu.qualityControl.experienceSharing':'经验分享',
  'menu.qualityControl.experienceSharingDetail':'分享详情',
  'menu.qualityControl.videos':'视频',
  'menu.qualityControl.train':'培训',
  'menu.qualityControl.train-detail':'培训详情',
  'menu.qualityControl.spotCheck':'抽检',
  'menu.qualityControl.non-performingBill':'不良台账',
  'menu.qualityControl.non-performingBill.non-performingBill-List':'不良台账列表',
  'menu.qualityControl.non-performingBill-List':'不良台账列表',

  //售后管理
  'menu.postSale':'售后管理',
  'menu.postSale_ClaimDetail':'索赔单',
  'menu.ClaimList':'索赔单列表',
  'menu.postSaleDetail':'售后信息',
  'menu.badReportList':'不良报告列表',


  'menu.machineSchedule':'在线排产',

  //发票管理
  'menu.invoice' : '发票管理',

  //材料销售
  'menu.settlement.settlement_mtSale' : '材料订单',

  'menu.settlement.settlement_mtSale.settlement_mtSaleDetail' : '材料订单详情',
  'menu.settlement.settlement_mtSaleDetail' : '材料订单详情',

  'menu.settlement.settlementCenter' : '结算中心',

  'menu.settlement.settlementCenter.settlementDetail': '结算明细',
  'menu.settlement.settlementDetail': '结算明细',

  'menu.settlement.settlementCenter.paymentApplyDetail': '付款申请明细',
  'menu.settlement.paymentApplyDetail': '付款申请明细',

  'menu.settlement.settlementPaymentPlan': '结算明细单',

  'menu.settlement.settlementPaymentPlan.settlementDetail': '结算',
  'menu.settlement.planSettlementDetail': '结算',

  'menu.settlement.settlementInvoice': '发票信息',

  'menu.settlement.settlement_mtSale.settlement_mtSaleDeliverDetail' : '发货单',
  'menu.settlement.settlement_mtSaleDeliverDetail' : '发货单',
};

const menus = {
  'user': userMenu,
  'operation': operationMenu,
  'supplier': supplierMenu,
};
window['ENDPOINT'] = undefined;
if (window.location.host.indexOf('yy.') !== -1 || window.location.host.indexOf('op.') !== -1 || window.location.host.indexOf('y.') !== -1) {
  window['ENDPOINT'] = 'operation';
} else if (window.location.host.indexOf('gys.') !== -1 || window.location.host.indexOf('sp.') !== -1 || window.location.host.indexOf('g.') !== -1) {
  window['ENDPOINT'] = 'supplier';
} else if (window.location.host.indexOf('new.') !== -1 || window.location.host.indexOf('ct.') !== -1 || window.location.host === 'yuncut.com') {
  window['ENDPOINT'] = 'user';
}

export default ENDPOINT ? menus[ENDPOINT] : {};


