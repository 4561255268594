// @ts-ignore
const userMenu = {
  //登录、注册、测试
  'menu.test': '测试页面',
  'menu.login': 'ログイン',
  'menu.print': '注文印刷',
  'menu.chose-register': '登録に進む',
  'menu.user-register': '顧客登録',
  'menu.supplier-register': 'サプライヤー登録',



  //账号
  'menu.account.profile': '個人情報',
  'menu.account.logout': 'サインアウト',

  //首页
  'menu.home': '表紙',
  'menu.steel-plate-cutting': '鋼板の切断',
  'menu.material-market': '材料市場',
  /*'menu.yuncut-matter': '云切物联',*/
  //资源中心
  'menu.resource-center':'リソース・センター',
  'menu.resource-center.home': 'リソースセンターのホームページ',
  'menu.resource-center.List': 'リストデータ',
  'menu.resource-center.VideoList': 'ビデオリスト',
  'menu.resource-center.Detail': '詳細',
  'menu.resource-center.homeSearchPage': '検索',

  //手机版(日文)
  'menu.mobileHome':'トップページ',
  'menu.founder':'創業者',
  'menu.product':'製品',
  'menu.device':'設備',
  'menu.contract':'連絡先',
  'menu.consult':'コンサルティング',

  //我的
  'menu.my': '私のクラウドカット',
  'menu.my.overview': '概要',

  //询价
  'menu.my.inquiry': '見積もり情報',
  'menu.my.inquiry.form': 'すぐに見積もり',
  'menu.my.inquiry.batch': '一括見積もり',
  'menu.my.inquiry.noDrawing': '図面なしの一括見積もり',

  'menu.my.inquiry.list': '見積もり記録',
  'menu.my.inquiry.change': '見積もり変更',
  'menu.my.inquiry.detail': '見積もり詳細',
  'menu.my.inquiry.order': 'すぐに注文',

  //排版
  'menu.my.layout': 'SaaS排版',
  'menu.my.layout.layoutBatch': '批量排版',
  'menu.my.layout.layoutList': '排版记录',
  'menu.my.layout.layoutDetail': '排版详情',
//订单
  'menu.my.order': '私の注文',
  'menu.my.order.list': '注文リスト',
  'menu.my.order.detail': '注文詳細',
  'menu.my.order.pay-balance-moeny': '残金を支払う',
  'menu.my.order.batch-payment-deposit':'一括支払い',
  'menu.my.order.evaluate': '評価',
  'menu.my.order.anotherList': 'もう一度注文',
  'menu.my.order.postSale': 'アフターサービス申請',



  //订单变更
  'menu.my.change': '変更管理',
  'menu.my.change.list': '申請可能リスト',
  'menu.my.change.appliedChangeList': '申請済みリスト',
  'menu.my.order.appliedChangeList': '私の変更',
  'menu.my.order.changeContent': '変更内容',
  'menu.my.order.changePriceConfirm': '価格確認',
  'menu.my.order.applyChange': '変更申請',
  'menu.my.order.PartChangeTable': '変更申請',
  'menu.my.order.PartChangeSubmit': '変更申請',



  //零件
  'menu.my.part': '部品管理',
  'menu.my.part.list': '部品リスト',
  'menu.my.part.form': '部品フォーム',
  'menu.my.part.detail': '部品詳細',
  'menu.my.part.combine': '部品組み合わせ',
  'menu.my.part.category': '部品分類',
  'menu.my.part.fixedPrice': '固定価格で注文',

  //结算
  'menu.my.settlement': '決済情報',
  'menu.my.settlement.list': '決済情報',

  //发票
  'menu.my.invoice': '請求書管理',
  'menu.my.invoice.list': '請求書管理',

  //设置
  'menu.my.setting': '設定',
  'menu.my.setting.profile': '個人情報',
  'menu.my.setting.unit-information': '企業情報',
  'menu.my.setting.sub-account': 'サブアカウント管理',
  'menu.my.setting.invoice-information': '請求書情報管理',
  'menu.my.setting.receive-address': '受取住所管理',

  //售后
  'menu.my.postSale': 'アフターサービス管理',
  'menu.my.postSale.postSale': 'アフターサービス管理',
  'menu.my.postSale.list': 'アフターサービスリスト',
  'menu.my.postSale.returnGoodsList': '返品リスト',
  'menu.my.postSale.returnGoods': '返品申請',
  'menu.my.postSale.AlreadyAppliedList': '申請済みリスト',
  'menu.my.postSale.postSaleDetail': 'アフターサービス詳細',

  //交易
  'menu.my.trade': '取引管理',
  'menu.my.trade.pay': '支払い計画',
  'menu.my.trade.pay.list': '支払い計画リスト',
  'menu.my.trade.invoiceList': '請求書情報',
};

const operationMenu = {

  //PDFプレビュー
  'menu.pdfview': 'PDFプレビュー',
//未定義のキー値エラー
  'menu': 'メニュー',
  'menu.qualityControl': '品質管理',
  'menu.qualityControl.dataLibrary': 'クラウドカット資料ライブラリ',
  'menu.qualityControl.dataLibraryDetail': 'クラウドカット資料ライブラリ詳細',
  'menu.qualityControl.dataLibrary.dataLibraryDetail': '詳細',
  'menu.qualityControl.experienceSharing': '経験の共有',
  'menu.qualityControl.edit_experienceSharing': '新規/編集',
  'menu.qualityControl.experienceSharing.experienceSharing': '経験共有の詳細',
  'menu.qualityControl.video': 'ビデオ',
  'menu.qualityControl.train': 'トレーニング',
  'menu.qualityControl.train.assessment-record': 'トレーニングの詳細',
  'menu.qualityControl.trainForm': '新規トレーニング',
  'menu.qualityControl.train.trainForm': '新規/編集トレーニング',
  'menu.qualityControl.assessment-record': '評価プロセス記録',
  'menu.qualityControl.assessment-record-detail': '評価記録のエクスポート',

  'menu.qualityControl.train.assessment-record.assessment-record-detail': '記録表のエクスポート',
  'menu.qualityControl.spotCheck': '抜き取り検査',
  'menu.qualityControl.non-performingBill': '不良台帳',
  'menu.qualityControl.non-performingBill.non-performingBill-List': '不良台帳リスト',
  'menu.qualityControl.non-performingBill-List': '不良台帳リスト',
  'menu.qualityControl.spotCheckForm': '新規抜き取り検査',
  'menu.qualityControl.spotCheck.spotCheckResult': '抜き取り検査の終了',
  'menu.qualityControl.spotCheckResult': '抜き取り検査の終了',
  'menu.qualityControl.spotCheck.spotCheckDetail': '抜き取り検査の詳細',
  'menu.qualityControl.spotCheckDetail': '抜き取り検査の詳細',
  'menu.qualityControl.spotCheck.spotCheckForm': '新規/編集抜き取り検査',
  'menu.qualityControl.newsCenter': 'ニュースセンター',
  'menu.qualityControl.newsCenter.newsCenterDetail': 'ニュースセンターの詳細',
  'menu.qualityControl.newsCenterDetail': 'ニュースセンターの詳細',
  'menu.qualityControl.rejectRatio': '不良/損失率',
  'menu.qualityControl.rejectRatioForm': '不良/損失率の修正',
  'menu.qualityControl.badRateFactory': 'PPM/COPQ不良率',
  'menu.qualityControl.badRateFactory.badRateFactoryForm': 'PPM/COPQ不良率の修正',

  'menu.login': 'ログイン',
  'menu.account.profile': '個人情報',
  'menu.account.logout': 'ログアウト',

//概要
  'menu.home': '概要',


  //询价
  'menu.inquiry': '見積もり管理',
  'menu.inquiry.first': '初回見積もり',
  'menu.inquiry.list': '見積もりリスト',
  'menu.inquiry.change': '見積もり変更',
  'menu.inquiry.detail': '見積もり詳細',
//日志管理
  'menu.logManagement': 'ログ管理',
  'menu.logManagement.orderLog': '販売注文ログ',
  'menu.logManagement.materialPurchaseLog': '材料購入ログ',
  'menu.logManagement.partsPurchaseLog': '部品購入ログ',
  'menu.logManagement.deliverLog': '販売出荷ログ',
  'menu.logManagement.materialCustomerLog': '顧客供給入庫ログ',
  'menu.logManagement.unNumberReportLog': '切断報告ログ',
  'menu.logManagement.unQualityReportLog': '品質報告ログ',
  'menu.logManagement.programLog': 'プログラム鋼板ログ',
  'menu.logManagement.materialAllotLog': '材料配分ログ',
  'menu.logManagement.receiptLog': '受領書ログ',
  'menu.logManagement.systemLog': 'システム操作ログ',
  'menu.logManagement.Interface': '第三者インターフェース',
  'menu.logManagement.XMLDetail': 'XML詳細',
  'menu.logManagement.integrationService': '統合サービスログ',
  'menu.logManagement.integrationService.programChk': 'ランテプログラムチェック',
  'menu.logManagement.programChk': 'ランテプログラムチェック',
  'menu.deliver': '出荷管理',
  'menu.deliver.can-deliver-list': '出荷待ち',
  'menu.deliver.delivered-list': '出荷済み',
  'menu.deliver.can-deliver-procedure-list': '工程出荷待ち',//yzl
  'menu.deliver.can-deliver-mergeDocuments-list': '統合出荷書(マニ)',//yzl
  'menu.deliver.detail': '出荷詳細',
  'menu.deliver.form': '出荷フォーム',
  'menu.deliver.logistic-list': '物流管理',
  'menu.deliver.logistic-order': '新規物流情報',
  'menu.deliver.transport-list': '物流会社管理',
  'menu.deliver.huolala-logistic-list': 'フオララ物流管理',
  'menu.deliver.huolala-logistic-order': 'フオララ新規物流情報',
  'menu.deliver.huolala-logistic-detail': 'フオララ物流情報詳細',

//注文
  'menu.order': '注文管理',
  'menu.order.sale-order': '販売注文',
  'menu.order.sale-order.list': '販売注文リスト',

//注文変更
  'menu.order.changeOrder':'変更管理',
  'menu.order.changeOrder.list':'変更リスト',
  'menu.order.changeOrder.approvalInfo':'変更承認',
  'menu.order.changeOrder.changeContent':'変更内容',
  'menu.order.changeOrder.offerInfo':'変更見積もり',
  'menu.order.changeOrder.distributionInfo':'変更部品配分',
  'menu.order.sale-order.applyChange':'変更申請',
//注文変更
  'menu.order.sale-order.partChangeTable': '変更申請',
  'menu.order.sale-order.partChangeSubmit': '変更申請',
//部品変更レポート
  'menu.order.materialChange': '部品材質変更レポート',
  'menu.order.materialChange.list': '部品材質変更リスト',
//注文変更-工場変更
  'menu.order.changeOrder.factoryChangeIndex': '変更申請',

  'menu.order.sale-order.detail': '販売注文詳細',
  'menu.order.sale-order.allocation': '販売注文割り当て',
  'menu.order.sale-order.examine-deliver': '出荷審査',
  'menu.order.sale-order.batch-collection': '一括回収',
  'menu.my.order.applyChange': '変更申請',
  'menu.order.prod-plan': '生産計画',
  'menu.order.prod-plan.list': '生産計画リスト',
  'menu.order.prod-plan.detail': '生産計画詳細',
  'menu.order.prod-plan.refuseDetail': '生産計画拒否詳細',
  'menu.order.task': '作業リスト',
  'menu.order.task.list': '作業リスト',
  'menu.order.task.detail': '作業詳細',
  'menu.order.program': 'プログラムリスト',
  'menu.order.program.list': 'プログラムリスト',
  'menu.order.program.detail': 'プログラム詳細',
  'menu.order.program.erpDetail': 'ERP同期ログ',
  'menu.order.program.editProgramCuttingTimes': 'プログラム切断回数の編集',
  'menu.order.prod-order': '生産注文',
  'menu.order.prod-order.list': '生産注文リスト',
  'menu.order.prod-order.detail': '生産注文詳細',
  'menu.order.order-track': '生産計画追跡',
  'menu.order.order-track.list': '生産計画追跡',

//後工程注文管理
  'menu.workProcess': '後工程注文管理',
  'menu.workProcess.list': '後工程注文リスト',
//後工程割り当て管理
  'menu.workProcess.partsList': '後工程割り当てリスト',
//yzl
  'menu.order.procedure-order': '後工程委託注文',
  'menu.order.procedure-order.list': '後工程委託注文リスト',
//lqg
  'menu.order.payment-order': '進捗金注文',
  'menu.order.payment-order.list': '進捗金注文リスト',
  'menu.order.payment-order.detail': '進捗金注文詳細リスト',

  //lqg
  'menu.order.customer-order': '顧客追跡注文',
  'menu.order.customer-order.list': '顧客追跡注文リスト',

  //补件管理
  'menu.supplement': '補充管理',
  'menu.supplement.can-supplement-list': '補充待ち',
  'menu.supplement.supplement-applying-list': '申請中',
  'menu.supplement.supplemented-list': '補充済み',
  'menu.supplement.reject-supplement-list': '拒否済み',
  'menu.supplement.apply-form': '補充申請',
  'menu.supplement.PatchDetails': '補充申請',
  'menu.supplement.detail': '詳細',
  'menu.supplement.badLoss-list': '不良損失',

  //财务管理
  'menu.finance': '財務管理',
  'menu.finance.list': '財務管理',
  'menu.finance.customer-period': '顧客アカウント期間',
  'menu.finance.customer-period.list': '顧客アカウント期間詳細',
  'menu.finance.invoice-information': '請求書管理',
  'menu.finance.invoice-information.list': '請求書管理詳細',
  'menu.finance.collection-plan': '回収計画',
  'menu.finance.collection-plan.list': '回収計画詳細',
  'menu.finance.collection-plan.detail': '回収計画支払い明細ルート',
  'menu.finance.collection-plan.depositModification': '回収計画頭金改訂',
  'menu.finance.collection-plan.depositWriteOff': '回収計画頭金消却',
  'menu.finance.collection-plan.progressModification': '回収計画進捗金改訂',
  'menu.finance.collection-plan.paymentWriteOff': '回収計画進捗金消却',
  'menu.finance.collection-plan.toBeReviewed': '残金審査',
  'menu.finance.collection-plan.advanceReviewed': '前受金審査',
  'menu.finance.payment-agreement': '支払い協定',
  'menu.finance.payment-agreement.list': '支払い協定詳細',
  'menu.finance.collection-plan.splitOrder': '回収計画残金分割注文',
  'menu.finance.collection-plan.balanceModification': '残金改訂',
  'menu.finance.collection-plan.advanceModification': '前受金改訂',
  'menu.finance.collection-plan.balanceWriteOff': '回収計画残金消却',
  'menu.finance.collection-plan.balanceSubmit': '回収計画確認待ち提出',
  'menu.finance.collection-plan.advancePayment': '回収計画残金増額前払い',
  'menu.finance.collection-plan.invoiceOffset': '相殺',

  //财务报表
  'menu.finance-report': '報告管理',
  'menu.finance-report.finance-produce': '委託注文報告',
  'menu.finance-report.finance-scrap': '廃鋼出入庫報告',
  'menu.finance-report.finance-subcontract': '委託出入庫報告',
  //財務報告
  'menu.finance-report.purchase-order': '購買注文報告',
  'menu.finance-report.purchase-warehouse': '購買入庫伝票報告',
  'menu.finance-report.material_delivery': '材料出庫伝票報告',
  'menu.finance-report.sales-order': '販売注文報告',
  'menu.finance-report.salesPrice-order': '販売注文明細報告',
  'menu.finance-report.sales-shipment': '販売出荷報告',
  'menu.finance-report.scrap-purchase': '廃鋼購入ERP記録',
  'menu.finance-report.order-execution': '販売注文明細実行報告',
  'menu.finance-report.finance-ymAmbGrossProfit': 'YMアミーバ計算表',
  'menu.finance-report.ymAmbGrossProfit-transportation': 'YMアミーバ購買部門修正',
  'menu.finance-report.ymAmbGrossProfit-overdue': 'YMアミーバ供給チェーンセンター修正',
  'menu.finance-report.ymAmbGrossProfit-claimant': 'YMアミーバ財務部門修正',
  'menu.finance-report.finance-ymAmbGrossProfitAnnual': '年次YMアミーバ報告',
  'menu.finance-report.finance-ymAmbGrossProfitDept': 'YMアミーバ部門報告',
  'menu.finance-report.finance-ymAmbDeptReport': '(新)YMアミーバ部門報告',
  'menu.finance-report.finance-ymAmbProfitReportChart': '(新)YMアミーバ顧客報告',

//返品管理
  'menu.returns': '返品管理',
  'menu.returns.list': '返品管理',

//顧客管理
  'menu.customer': '顧客管理',
  'menu.customer.user': '顧客管理',
  'menu.customer.address': '住所管理',
  'menu.customer.address.list': '住所管理',
  'menu.customer.invoice': '請求書管理',
  'menu.customer.invoice.list': '請求書管理',
  'menu.customer.user.list': '顧客リスト',
  'menu.customer.user.detail': '顧客詳細',
  'menu.customer.part': '部品管理',
  'menu.customer.part.manage': '部品管理',
  'menu.customer.part.form': '部品フォーム',
  'menu.customer.part.detail': '部品詳細',
  'menu.customer.custom-template': 'カスタムテンプレート',

//システム管理
  'menu.system': 'システム管理',
  'menu.system.user': 'ユーザー管理',
  'menu.system.role': 'ロール管理',
  'menu.system.weChatTemplate': 'メッセージ設定管理', //企業微信メッセージ設定テンプレート
  'menu.system.departmentTemplate': 'YMアミーバ部門管理', //アミーバ部門設定テンプレート
  'menu.system.function': '機能管理',
  'menu.system.interface': 'インターフェース管理',
  'menu.system.announcement': '公告管理',
  'menu.system.statistics': '統計テンプレート',
  'menu.system.dictionary': 'データ辞書',
  'menu.system.weight': '重み管理',
  'menu.contract-template': '契約テンプレート管理',
  'menu.contract-template.list': '契約テンプレートリスト',
  //设置
  'menu.setting': '設定',
  'menu.setting.profile': '個人情報',
  'menu.material-library': '材料ライブラリ管理',
  'menu.material-library.material-science': '材料倉庫',
  'menu.material-library.material-science-form': '材料入庫',
  'menu.material-library.material-surplus': '余剰材料倉庫',
  'menu.material-library.material-surplus-form': '余剰材料入庫',
  'menu.material-library.material-purchase.warehousing':'入庫',
  'menu.material-library.material-purchase.list': '材料購入',
  'menu.material-library.material-purchase.save': '新規購入',
  'menu.material-library.material-purchase.detail': '購入詳細',
  'menu.product-library.partsPurchase-list': '部品購入',
  'menu.product-library.partsPurchase-save': '部品ライブラリ購入',
  'menu.product-library.partsPurchase-order': '販売注文部品購入',
  'menu.product-library.partsPurchase-detail': '部品購入詳細',
  'menu.product-library.partsPurchase-warehousing': '部品入庫',
  'menu.product-library.productJournal-list': '製品流通帳',
  'menu.material-library.material-summary': '余剰材料統計集計',
  'menu.material-library.material-type-management': '材質管理',
  'menu.material-library.material-supplier': '材料供給者管理',
  'menu.material-library.material-purchase': '材料購入',
  'menu.material-library.material-allocation': '材料配分',
  'menu.material-library.management': '倉庫管理',
  'menu.material-library.material-taking-form': '在庫盤点入庫',
  'menu.material-library.material-taking-form-no-stock': '非在庫盤点入庫',
  'menu.material-library.material-stockInOut': '出入庫記録',
  'menu.material-library.material-stockInOut.list': '出入庫記録',
  'menu.material-library.material-stockInOut.stockIn': '入庫記録',
  'menu.material-library.material-stockInOut.stockOut': '出庫記録',
  'menu.material-library.material-stockInOut.check': '在庫盤点',
  'menu.material-library.material-stockInOut.noCheck': '非在庫盤点',
  'menu.material-library.material-stockInItem': '入庫明細',

  'menu.material-library.material-notPurchaseStockIn': '入庫',
  'menu.material-library.material-SteelScrapStockOut': '廃鋼一括出庫',
  'menu.material-library.material-StockOutOperation': '出庫',
  'menu.material-library.choice-issue': '新しい棚卸出庫',
  'menu.material-library.steel-plate-split': '鋼板の分割',
  'menu.material-library.material-stockOutItem': '明細情報',
  'menu.material-library.material-steelScrap': '廃鋼リスト',
  'menu.material-library.material-steelScrap-warehouse': '廃鋼倉庫',
  'menu.material-library.material-customer': '顧客提供の材料',
  'menu.material-library.material-customer.list': '顧客提供の材料リスト',
  'menu.material-library.material-customer.stock-in': '顧客提供の材料入庫',
  'menu.material-library.material-customer.stock-out': '顧客提供の材料出庫',
  'menu.material-library.material-reconciliation': '購入照合',
  'menu.material-library.material-reconciliation.list': '購入照合リスト',
  'menu.material-library.material-materialStock': '在庫警告',
  'menu.material-library.material-materialStock.list':'在庫警告リスト',
  'menu.material-library.material-InventoryReport': '在庫月報',
  'menu.material-library.material-InventoryReport.list':'在庫月報リスト',
  'menu.material-library.material-Journal': '材料流通帳',

  //中心仓管理
  'menu.centre-library': '中央倉庫管理',
  'menu.centre-library.centre-science': 'クラウドカット在庫',
  'menu.centre-library.centre-science-form': '材料入庫',
  'menu.centre-library.centre-stockInOut': '出入庫記録（中央倉庫）',
  'menu.centre-library.centre-stockInOut.list': '出入庫記録',
  'menu.centre-library.centre-stockInOut.stockIn': '入庫記録',
  'menu.centre-library.centre-stockInOut.stockOut': '出庫記録',
  'menu.centre-library.centre-stockInOut.check': '在庫盤点',
  'menu.centre-library.centre-stockInOut.noCheck': '非在庫盤点',
  'menu.centre-library.centre-stockInItem': '入庫明細',
  'menu.centre-library.centre-notPurchaseStockIn': '入庫',
  'menu.centre-library.centre-StockOutOperation': '出庫',
  'menu.centre-library.centre-ReturnGoodsOperation': '材料返品',
  'menu.centre-library.steel-plate-split': '鋼板分割',
  'menu.centre-library.centre-stockOutItem': '明細情報',
  'menu.centre-library.centre-customer': '顧客供給在庫（中央倉庫）',
  'menu.centre-library.centre-customer.list': '顧客供給材料リスト',
  'menu.centre-library.centre-customer.stock-in': '顧客供給材料入庫',
  'menu.centre-library.centre-customer.stock-out': '顧客供給材料出庫',
  'menu.centre-library.centre-allocation': '材料配分（中央倉庫）',
  'menu.centre-library.centre-purchase.warehousing': '入庫',
  'menu.centre-library.centre-purchase': '材料購入（中央倉庫）',
  'menu.centre-library.centre-purchase.list': '材料購入リスト',
  'menu.centre-library.centre-purchase.save': '新規購入（中央倉庫）',
  'menu.centre-library.centre-purchase.save.saveSurplus': '新規余剰材料購入（中央倉庫）',
  'menu.centre-library.centre-purchase.detail': '購入詳細',
  'menu.centre-library.centre-InventoryReport': '在庫月報（中央倉庫）',
  'menu.centre-library.centre-InventoryReport.list': '在庫月報リスト',
  'menu.centre-library.centre-surplus': '余剰材料倉庫（中央倉庫）',
  'menu.centre-library.centre-surplus.list': '余剰材料倉庫リスト',
  'menu.centre-library.centre-surplus-form': '余剰材料入庫',
  'menu.centre-library.centre-allocation.approveWarehoue': '配分審査',
  'menu.centre-library.centre-centreMaterialStock': '中央倉庫在庫警告',
  'menu.centre-library.centre-centreMaterialStock.list': '中央倉庫在庫警告リスト',
  'menu.centre-library.centre-purchase.saveSurplus': '未定義',


  //基础库
  'menu.basic-library': '基本ライブラリ管理',
  'menu.basic-library.material-texture': '材質管理',
  'menu.basic-library.plate-thickness': '板厚管理',
  'menu.basic-library.cut-method': '切断工程管理',
  'menu.basic-library.cut-seam': '切断縫管理',
  'menu.basic-library.contract-template': '契約テンプレート管理',
  'menu.basic-library.quality-book': '品質保証書管理',
  'menu.basic-library.work-order': '工程管理',
  'menu.basic-library.material-brand': '鋼工場管理',
  'menu.basic-library.material-standard': '材質標準管理',
  'menu.basic-library.material-category': '材質カテゴリ管理',
  'menu.basic-library.postSale_badType': 'アフターサービス不良分類',



  // 'menu.basic-library.global-use-rate':'全局利用率',
  // 'menu.basic-library.material-type-list':'材质列表',
  // 'menu.basic-library.material-standard-list':'材质标准列表',
  // 'menu.basic-library.material-thickness-list':'材质厚度列表',
  // 'menu.basic-library.material-brand-list':'材质钢厂列表',
  // 'menu.basic-library.equipment-brand-list':'设备钢厂列表',
  // 'menu.basic-library.material-category-list':'材质品类列表',
  // 'menu.basic-library.cutting-method-list':'切割工艺列表',
  // 'menu.basic-library.cutting-seam-list':'切割缝列表',
  // 'menu.basic-library.Warranty-list':'质保书管理',
  // 'menu.basic-library.formula-define-list':'公式定义',
  // 'menu.basic-library.system-taxRate':'系统税率',
  // 'menu.basic-library.contracts-template':'合同模板管理',

  'menu.supplier': '供应商管理',
  'menu.supplier.material-supplier': '材料供应商管理',
  'menu.supplier.detail': '详情',

  'menu.supplier.firstContact':'首次联系',
  'menu.supplier.groupPermiss':'分组权限',
  'menu.supplier.groupPermiss.groupMember':'分组成员',
  'menu.supplier.supFunctionManage':'功能管理',
  'menu.supplier.subAccount':'供应商子账号',

  'menu.supplier.supplier': '切割供应商管理',
  'menu.supplier.supplier.detail': '详情',
  'menu.supplier.supplierAdd': '工厂管理',
  'menu.supplier.supplierDevice-information': '设备列表',
  'menu.supplier.cameraYsList': '摄像头信息',

  'menu.base-price': '销售单价管理',
  'menu.base-price.base-price-save':'销售单价导入',

  'menu.product-library': '产品库管理',
  'menu.product-library.list': '产品列表',
  'menu.product-library.stock-taking-form': '库存盘点',
  'menu.product-library.product-ProductOutOperation': '盘点出库',
  'menu.product-library.product-ProductInOperation': '盘点入库',
  'menu.product-library.partsProcedure-list': '后工序产品',
  'menu.product-library.partsProcedure-stock-taking-form': '盘点入库',

  'menu.product-library.storehouse-out-list': '出库记录',
  'menu.product-library.storehouse-out-detail': '出库详情',
  'menu.product-library.storehouse-in-list': '入库记录',
  'menu.product-library.storehouse-in-detail': '入库详情',
  // 产品列表
  'menu.product-library.productStorage':'产品暂存入库',
  'menu.product-library.productStorage.productStorageForm':'产品入库详情',
  'menu.product-library.productStorageForm':'产品入库详情',

  'menu.weight': '权重管理',
  'menu.weight.weight-type': '权重管理',
  'menu.weight.formula-define-list': '公式定义',
  'menu.weight.system-taxRate': '系统税率',
  'menu.weight.global-use-rate': '全局利用率',
  'menu.weight.globalSetting': '全局时间查询',
  'menu.weight.global-use-rate-save':'全局利用率导入',

  'menu.operation-delivery-management': '发货管理',
  'menu.operation-reported-management': '报工管理',


  'menu.logistics': '物流管理',
  'menu.logistics.list': '物流列表',

  'menu.freight':'运费管理',
  'menu.freight.list':'运费列表',

  //结算管理
  'menu.settlement': '结算管理',
  'menu.settlement.list': '结算列表',
  'menu.settlement.settlement_mtSale': '材料销售订单',
  'menu.settlement.settlement_mtSale.settlement_addMtSale': '新增材料销售订单',
  'menu.settlement.settlement_addMtSale.settlement_addMtSaleExcelint': '导入材料销售订单',
  'menu.settlement.settlement_addMtSaleExcelint': '导入材料销售订单',
  'menu.settlement.settlement_addMtSale': '新增材料销售订单',
  'menu.settlement.settlement_mtSale.settlement_mtSaleDetail': '材料销售订单详情',
  'menu.settlement.settlement_mtSaleDetail': '材料销售订单详情',
  'menu.settlement.settlement_mtSale.settlement_mtSaleDeliverDetail': '发货单',
  'menu.settlement.settlement_mtSaleDeliverDetail': '发货单',
  'menu.settlement.settlement_mtSale.settlement_mtSaleDeliver': '材料销售订单发货',
  'menu.settlement.settlement_mtSaleDeliver': '材料销售订单发货',

  'menu.settlement.materialTransformation-list': '材料形态转换',
  'menu.settlement.materialTransformation-save': '新增材料形态转换',

  'menu.settlement.settlementCenter': '结算中心',
  'menu.settlement.settlementCenter.settlementDetail': '结算明细',
  'menu.settlement.settlementDetail': '结算明细',
  'menu.settlement.settlementPaymentPlan.planSettlementDetail': '结算',
  'menu.settlement.planSettlementDetail': '结算',
  'menu.settlement.settlementCenter.paymentApplyDetail': '付款申请明细',
  'menu.settlement.paymentApplyDetail': '付款申请明细',
  'menu.settlement.settlementCenter.settlementCenter_PaymentApply': '交易申请',
  'menu.settlement.settlementCenter_PaymentApply': '交易申请',
  'menu.settlement.settlementPaymentPlan': '结算明细单',
  'menu.settlement.settlementInvoice': '发票信息',
  'menu.settlement.claimList': '索赔单',
  'menu.settlement.prodList': '生产订单',
  'menu.settlement.partsPurchaseList': '零件采购订单',
  'menu.settlement.procedureOrderList': '后工序委外订单',

  'menu.statistics': '统计分析',
  'menu.statistics.list': '统计列表',


  //售后
  'menu.postSale':'售后管理',
  'menu.postSale.postSale-index':'售后申请',
  'menu.postSale.postSale-list':'售后列表',
  'menu.postSale.sale_detail':'售后详情',
  'menu.postSale.postSale-list.sale_detail':'售后详情',
  'menu.postSale.return_repair':'退货返修',
  'menu.postSale.missing_parts':'缺件补件',
  'menu.postSale.scrap_supplement':'报废补件',
  'menu.postSale.concession_receive':'让步接收',
  'menu.postSale.return_cancel':'退货/缺件取消',
  'menu.postSale.claim':'发起索赔',
  'menu.postSale.claimList':'索赔单列表',
  'menu.postSale.claimList_detail':'索赔详情',
  'menu.postSale.claimList.claimList_detail':'索赔详情',
  'menu.postSale.FreeAudit':'费用审核',
  'menu.postSale.otherClaim':'其他索赔',
  'menu.postSale.order_detail':'订单详情',
  'menu.postSale.badReportList':'不良报告列表',


  'menu.postSale.machineSchedule':'在线排产',


  //供应商发票信息管理、收货信息管理
  'menu.supplier.Op_supInvoice':'开票管理',
  'menu.supplier.Op_supAddress':'地址管理',
};

// @ts-ignore
const supplierMenu = {
  //账号
  'menu.account.profile': '个人信息',
  'menu.account.logout': '退出登录',
  // 供应商端
  'menu.home': '首页',
  'menu.deliverPrint': '发货清单',
  //生产计划
  'menu.prod-plan': '生产计划',
  'menu.prod-plan.list': '列表-生产计划',
  'menu.prod-plan.detail': '详情-生产计划',
  'menu.order-track':'生产计划跟踪',
  'menu.order-track.list':'列表-生产计划跟踪',

  //程序管理
  'menu.program': '程序管理',
  'menu.program.list': '程序列表',
  'menu.program.summary': '报工汇总',
  'menu.program.detail': '程序详情',
  'menu.program.report': '程序报工',
  'menu.program.schedule': '日程列表',
  //生产订单
  'menu.prod-order': '生产订单',
  'menu.prod-order.list': '列表-生产订单',
  'menu.prod-order.detail': '详情-生产订单',

  //报工管理
  // 'menu.reported-work': '报工管理',
  // 'menu.reported-work.number-list': '报数量列表',
  // 'menu.reported-work.quality-list': '报质量列表',
  // 'menu.reported-work.form': '报质量列表',

  //发货管理
  'menu.deliver': '发货管理',
  'menu.deliver.list': '已发货',
  'menu.deliver.detail': '发货详情',
  'menu.deliver.can-deliver-list': '待发货',
  'menu.deliver.form': '待发货',

  //补件管理
  'menu.supplement': '补件管理',
  'menu.supplement.list': '待补件',
  'menu.supplement.CanSupplement-patchDetails': '补件详情',
  'menu.supplement.ApplySupplementList': '申请中',
  'menu.supplement.SupplementedList': '已补件',
  'menu.supplement.RejectSupplementList': '已拒绝',

  //零件库
  'menu.product-library': '产品库管理',
  'menu.product-library.productLibrary':'产品库',
  'menu.product-library.productStockInOutReport':'产品流水账',
  'menu.product-library.productStockInSupplier':'入库记录',
  'menu.product-library.productStockOutSupplier':'出库记录',
  'menu.product-library.productStorehouse-in-detail': '入库单详情',
  'menu.product-library.productStorehouse-out-detail': '出库单详情',

  //结算信息
  'menu.settlement': '结算信息',
  'menu.settlement.list': '结算信息',
  'menu.settlement.detail': '结算详情',

  //材料库
  'menu.material-library': '材料库',
  'menu.material-library.material': '材料仓库',
  'menu.material-library.surplus-material': '余料仓库',
  'menu.material-library.steelScrap-material': '废钢列表',
  'menu.material-library.materialPurchase': '主材采购',
  'menu.material-library.surplusPurchase':'余料采购',
  'menu.material-library.materialScience':'材料调拨',
  'menu.material-library.materialStockInOutReport':'材料流水账',

  //我的工厂
  'menu.my-salesOrder':'我的工厂',
  'menu.my-salesOrder.my-factory': '销售管理',
  'menu.my-salesOrder.my-factory.businessNavigator':'业务导航',
  'menu.my-salesOrder.my-factory.businessNavigator.list':'业务导航列表',
  'menu.my-salesOrder.my-factory.order':'销售订单',
  'menu.my-salesOrder.my-factory.order.list':'销售订单列表',
  'menu.my-salesOrder.my-factory.orderReport':'销售明细报表',
  'menu.my-salesOrder.my-factory.prodOrder':'生产订单',
  'menu.my-salesOrder.my-factory.prodOrder.list': '列表-生产订单',
  'menu.my-salesOrder.my-factory.prodOrder.detail': '详情-生产订单',
  'menu.my-salesOrder.my-factory.prodOrder.edit':"修改-生产订单",
  'menu.my-salesOrder.suProdOrder':'生产管理',
  'menu.my-salesOrder.suProdOrder.businessNavigator':'业务导航',
  'menu.my-salesOrder.suProdOrder.businessNavigator.list':'业务导航列表',
  'menu.my-salesOrder.suProdOrder.list':'生产订单',
  'menu.my-salesOrder.suProdOrder.detail':'详情-工厂生产订单',
  'menu.my-salesOrder.suProdOrder.edit':'修改-工厂生产订单',
  'menu.my-salesOrder.basic-archives.BatchImportPartsForm':"批量导入零件",
  /*'menu.my-factory.workReport':'报工管理',
  'menu.my-factory.workReport.list': '列表-报工管理',*/
  'menu.my-salesOrder.my-factory.deliver':'发货管理',
  'menu.my-salesOrder.my-factory.deliver.list': '发货管理',
  'menu.my-salesOrder.my-factory.deliver.detail': '发货详情',
  'menu.my-salesOrder.my-factory.deliver.save': '新增发货单',
  'menu.my-salesOrder.my-factory.deliver.MaterialSave':'新增材料发货单',
  'menu.my-salesOrder.my-factory.deliver.materialDetail':'材料发货单详情',
  'menu.my-salesOrder.my-factory.prodOrder.work':'生产订单报工',
  'menu.my-salesOrder.my-factory.prodOrder.save':'新增生产订单',
  'menu.my-salesOrder.suProdOrder.work':'生产订单报工',
  'menu.my-salesOrder.suProdOrder.save':'新增生产订单',
  'menu.my-salesOrder.my-factory.deliver.partsEdit':'产品发货单修改',
  'menu.my-salesOrder.my-factory.deliver.materialEdit':'材料发货单修改',

  'menu.my-salesOrder.my-factory.order.PartsDetail':'零件销售订单详情',
  'menu.my-salesOrder.my-factory.order.MaterialDetail':'材料销售订单详情',
  'menu.my-salesOrder.my-factory.order.save':'新增零件销售订单',
  'menu.my-salesOrder.my-factory.order.form':'修改零件销售订单',
  'menu.my-salesOrder.my-factory.order.materialForm':'修改材料销售订单',
  'menu.my-salesOrder.my-factory.order.materialSave':'新增材料销售订单',
  'menu.my-salesOrder.stock-management.productStockIn.list':'产品入库列表',
  'menu.my-salesOrder.stock-management.productStockIn.detail':'产品入库详情',
  'menu.my-salesOrder.stock-management.productStockIn.save':'新增-产品入库',
  'menu.my-salesOrder.stock-management.productStockOut.list':'产品出库列表',
  'menu.my-salesOrder.stock-management.productStockOut.detail':'产品出库详情',
  'menu.my-salesOrder.stock-management.productStockOut.save':'新增-产品出库',
  'menu.my-salesOrder.stock-management.materialStockIn.list':'材料入库列表',
  'menu.my-salesOrder.stock-management.material-stockOutItem':'材料出库详情',
  'menu.my-salesOrder.stock-management.materialStockIn.save':'新增-材料入库',
  'menu.my-salesOrder.stock-management.material-stockInItem':'材料入库详情',
  'menu.my-salesOrder.stock-report.materialStock.list':'材料库存列表',
  'menu.my-salesOrder.stock-report.productStockInOutItem.list':'产品出入库',
  'menu.my-salesOrder.stock-report.materialStockInOutItem.list':'材料出入库',
  'menu.my-salesOrder.stock-report.productStock.list':'产品库存列表',
  'menu.my-salesOrder.stock-management.materialAllocation.list':'调拨列表',
  'menu.my-salesOrder.stock-management.materialAllocation.detail':'调拨单详情',
  'menu.my-salesOrder.stock-management.materialStockOut.save':'保存材料出库',
  'menu.my-salesOrder.stock-management.materialStockOut.list':'材料出库列表',
  'menu.my-salesOrder.stock-management.materialAllocation.product-save':'保存产品调拨',
  'menu.my-salesOrder.stock-management.materialAllocation.material-save':'保存材料调拨',
  'menu.my-salesOrder.my-factory.collection':'收款申请',
  'menu.my-salesOrder.my-factory.sales-freight':'销售运费',
// /my-salesOrder/material-purchase/sales-freight
  'menu.my-salesOrder.my-factory.sales-invoice':'销售发票申请',
  'menu.my-salesOrder.my-factory.sales-invoice.list':'材料销售发票信息',
  'menu.my-salesOrder.my-factory.sales-invoice.material-add':'申请材料销售发票信息',
  'menu.my-salesOrder.my-factory.sales-invoice.part-add':'申请零件销售发票信息',
  'menu.my-salesOrder.my-factory.sales-invoice.part-edit':'修改零件销售发票信息',
  'menu.my-salesOrder.my-factory.sales-invoice.material-edit':'修改材料销售发票信息',
  'menu.my-salesOrder.material-purchase.material-management.materialPurchaseEdit':'修改材料采购入库单',
  'menu.my-salesOrder.material-purchase.material-management.partPurchaseEdit':'修改零件采购入库单',
  'menu.my-salesOrder.my-factory.sales-invoice.part-detail':'零件销售发票详情',
  'menu.my-salesOrder.my-factory.sales-invoice.material-detail':'材料销售发票详情',
  // 'menu.my-salesOrder.material-purchase.material-management.partPurchaseEdit':'',
  //工厂库存管理
  'menu.my-salesOrder.stock-management': '库存管理',
  'menu.my-salesOrder.stock-management.businessNavigator':'业务导航',
  'menu.my-salesOrder.stock-management.businessNavigator.list':'业务导航列表',
  'menu.my-salesOrder.stock-management.productStockIn':'产品入库单',
  'menu.my-salesOrder.stock-management.productStockOut':'产品出库单',
  'menu.my-salesOrder.stock-management.materialStockIn':'材料入库单',
  'menu.my-salesOrder.stock-management.materialStockOut':'材料出库单',
  'menu.my-salesOrder.stock-management.materialAllocation':'调拨单',
  'menu.my-salesOrder.stock-management.productStockIn.edit':'修改产品入库单',
  'menu.my-salesOrder.stock-management.productStockOut.proStockOutEdit':'修改产品出库单',
  'menu.my-salesOrder.stock-management.materialStockIn.matStockInEdit':'修改材料入库单',
  'menu.my-salesOrder.stock-management.materialStockOut.matStockOutEdit':'修改材料出库单',
  'menu.my-salesOrder.stock-management.materialAllocation.product-edit':'修改产品调拨单',
  'menu.my-salesOrder.stock-management.materialAllocation.material-edit':'修改材料调拨单',


//工厂库存报表
  'menu.my-salesOrder.stock-report': '库存报表管理',
  'menu.my-salesOrder.stock-report.productStock':'产品库存表',
  'menu.my-salesOrder.stock-report.materialStock':'材料库存表',
  'menu.my-salesOrder.stock-report.materialStockInOutItem':'材料出入库明细表',
  'menu.my-salesOrder.stock-report.productStockInOutItem':'产品出入库明细表',
  'menu.my-salesOrder.stock-report.realTimeStock':'云切废钢库存',
  'menu.my-salesOrder.stock-report.realTimeStock.list':'云切废钢库存列表',
  'menu.my-salesOrder.stock-report.realTimeStock.redRushScrap':'红冲云切废钢',
  'menu.my-salesOrder.stock-report.businessNavigator':'废钢业务导航',

  //工厂基础档案
  'menu.my-salesOrder.basic-archives': '基础档案',
  'menu.my-salesOrder.basic-archives.businessNavigator':'业务导航',
  'menu.my-salesOrder.basic-archives.businessNavigator.list':'业务导航列表',
  'menu.my-salesOrder.basic-archives.part-file':'工厂存货档案',
  'menu.my-salesOrder.basic-archives.customer-profile':'工厂客户档案',
  'menu.my-salesOrder.basic-archives.detail':'客户详情',
  'menu.my-salesOrder.basic-archives.supplier-file':'工厂供应商档案',
  'menu.my-salesOrder.basic-archives.warehouse archives':'工厂仓库档案',
  'menu.my-salesOrder.basic-archives.sendReceive-category':'工厂收发类别',
  'menu.my-salesOrder.basic-archives.scrapPriceArchives':'工厂废钢当月单价',
  'menu.my-salesOrder.basic-archives.operating-log':'工厂操作系统日志',

  //工厂财务管理
  'menu.my-salesOrder.finance': '财务管理',
  'menu.my-salesOrder.finance.businessNavigator':'业务导航',
  'menu.my-salesOrder.finance.businessNavigator.List':'业务导航列表',
  'menu.my-salesOrder.finance.list': '运费单查询',
  'menu.my-salesOrder.finance.customer-period':'收付款确认',
  'menu.my-salesOrder.finance.customer-period.list': '发票确认',
  'menu.my-salesOrder.finance.invoice-information':'发票确认',
  'menu.my-salesOrder.finance.payment-report':'收付款报表',
  'menu.my-salesOrder.finance.payment-report.list':'收付款报表',
  'menu.my-salesOrder.finance.invoice-information.list':'发票管理详情',
  'menu.my-salesOrder.finance.invoice-information.partSalesDetail':'零件发票详情',
  'menu.my-salesOrder.finance.invoice-information.materialSalesDetail':'材料发票详情',


  //工厂采购管理
  'menu.my-salesOrder.material-purchase': '采购管理',
  'menu.my-salesOrder.material-purchase.businessNavigator': '业务导航',
  'menu.my-salesOrder.material-purchase.businessNavigator.list': '业务导航列表',
  'menu.my-salesOrder.material-purchase.material-management': '采购入库单',
  'menu.my-salesOrder.material-purchase.payment' : '付款申请',
  'menu.my-salesOrder.material-purchase.purchase-invoice' : '  采购发票申请',
  'menu.my-salesOrder.material-purchase.purchase-freight' : '  采购运费',
  'menu.my-salesOrder.material-purchase.material-management.list':'采购入库列表',
  'menu.my-salesOrder.material-purchase.material-management.partPurchaseSave':'产品采购入库',
  'menu.my-salesOrder.material-purchase.material-management.materialPurchaseSave':'材料采购入库',
  'menu.my-salesOrder.material-purchase.material-management.materialPurchaseDetail':'材料采购详情',
  'menu.my-salesOrder.material-purchase.purchase-invoice.save':'材料采购发票申请',
  'menu.my-salesOrder.material-purchase.purchase-invoice.list':'采购申请列表',
  'menu.my-salesOrder.material-purchase.purchase-invoice.materialDetail':'材料采购发票详情',
  'menu.my-salesOrder.material-purchase.purchase-invoice.partDetail':'零件采购发票详情',
  'menu.my-salesOrder.material-purchase.purchase-invoice.edit':'修改材料采购发票',
  'menu.my-salesOrder.material-purchase.material-management.partPurchaseDetail':'零件采购入库单详情',
  'menu.my-salesOrder.material-purchase.purchase-invoice.partSave':'申请零件采购发票',
  'menu.my-salesOrder.material-purchase.purchase-invoice.partEdit':'修改零件采购发票',
  //
  // 'menu.my-salesOrder.material-purchase.material-science-form': '材料入库',
  // 'menu.my-salesOrder.material-purchase.material-surplus': '余料仓库',
  // 'menu.my-salesOrder.material-purchase.material-surplus-form': '余料入库',
  // 'menu.my-salesOrder.material-purchase.material-purchase':'采购管理',


  //setting
  'menu.setting': '设置',
  'menu.setting.profile': '个人信息',
  'menu.setting.subAccount': '子账号管理',
  'menu.setting.supplierRole': '角色管理',
  'menu.setting.roleMember': '角色管理',
  'menu.setting.unit-information': '单位信息',
  'menu.setting.factory-management': '工厂管理',
  'menu.setting.cutting-method': '切割方式',
  'menu.setting.cutting-unit-price': '切割单价',
  'menu.setting.device-information': '设备信息',
  'menu.setting.payment-method': '收款方式',
  'menu.setting.stock-manager': '仓库管理 ',
  'menu.setting.invoice-manager': '发票信息管理 ',
  'menu.setting.receiveAddress-manager': '收货信息管理 ',
  //品质管理
  'menu.qualityControl':'品质管理',
  'menu.qualityControl.dataLibrary':'云切资料库',
  'menu.qualityControl.dataLibraryDetail':'云切资料库详情',
  'menu.qualityControl.experienceSharing':'经验分享',
  'menu.qualityControl.experienceSharingDetail':'分享详情',
  'menu.qualityControl.videos':'视频',
  'menu.qualityControl.train':'培训',
  'menu.qualityControl.train-detail':'培训详情',
  'menu.qualityControl.spotCheck':'抽检',
  'menu.qualityControl.non-performingBill':'不良台账',
  'menu.qualityControl.non-performingBill.non-performingBill-List':'不良台账列表',
  'menu.qualityControl.non-performingBill-List':'不良台账列表',

  //售后管理
  'menu.postSale':'售后管理',
  'menu.postSale_ClaimDetail':'索赔单',
  'menu.ClaimList':'索赔单列表',
  'menu.postSaleDetail':'售后信息',
  'menu.badReportList':'不良报告列表',


  'menu.machineSchedule':'在线排产',

  //发票管理
  'menu.invoice' : '发票管理',

  //材料销售
  'menu.settlement.settlement_mtSale' : '材料订单',

  'menu.settlement.settlement_mtSale.settlement_mtSaleDetail' : '材料订单详情',
  'menu.settlement.settlement_mtSaleDetail' : '材料订单详情',

  'menu.settlement.settlementCenter' : '结算中心',

  'menu.settlement.settlementCenter.settlementDetail': '结算明细',
  'menu.settlement.settlementDetail': '结算明细',

  'menu.settlement.settlementCenter.paymentApplyDetail': '付款申请明细',
  'menu.settlement.paymentApplyDetail': '付款申请明细',

  'menu.settlement.settlementPaymentPlan': '结算明细单',

  'menu.settlement.settlementPaymentPlan.settlementDetail': '结算',
  'menu.settlement.planSettlementDetail': '结算',

  'menu.settlement.settlementInvoice': '发票信息',

  'menu.settlement.settlement_mtSale.settlement_mtSaleDeliverDetail' : '发货单',
  'menu.settlement.settlement_mtSaleDeliverDetail' : '发货单',
};

const menus = {
  'user': userMenu,
  'operation': operationMenu,
  'supplier': supplierMenu,
};
window['ENDPOINT'] = undefined;
if (window.location.host.indexOf('yy.') !== -1 || window.location.host.indexOf('op.') !== -1 || window.location.host.indexOf('y.') !== -1) {
  window['ENDPOINT'] = 'operation';
} else if (window.location.host.indexOf('gys.') !== -1 || window.location.host.indexOf('sp.') !== -1 || window.location.host.indexOf('g.') !== -1) {
  window['ENDPOINT'] = 'supplier';
} else if (window.location.host.indexOf('new.') !== -1 || window.location.host.indexOf('ct.') !== -1 || window.location.host === 'yuncut.com') {
  window['ENDPOINT'] = 'user';
}

export default ENDPOINT ? menus[ENDPOINT] : {};


