import request from "../../utils/request";
import {PaymentType} from "@/types/payment";
import  {subcontract} from "@/types/payment"

//保存付款记录
// export async function save(params:FinanceType): Promise<any> {
//   return request('/yuncut_balance_db/ServerFunc_SavePayRecord',{
//     method:'POST',
//     data:{
//       pay_record:[params]
//     }
//   });
// }

//通过订单id获取付款记录
export async function queryByOrderId(orderId:string): Promise<any> {
  return request('/yuncut_balance_db/ServerFunc_GetPayRecordByOrderId',{
    method:'POST',
    data:{orderId}
  });
}

//录入付款记录
export async function input(params:PaymentType): Promise<any> {
  return request('/yuncut_balance_db/ServerFunc_InputPayRecord',{
    method:'POST',
    data:params
  });
}

//委外订单查询
export async function queryProduce(params:any):Promise<any>{
  return request('/yuncut_produce_order_db/ServerFunc_GetProduce',{
    method:'POST',
    data:params
  });
}
//委外订单导出Excel
export async function queryProduceExcel(params:any):Promise<any>{
  return request('/yuncut_produce_order_db/ServerFunc_GetProduce_Excel',{
    method:'POST',
    data:params
  });
}

//废钢出入库记录查询
export async function queryScrap(params:any):Promise<any>{
  return request('/yuncut_stockin_db/ServerFunc_GetScrapWarehousing',{
    method:'POST',
    data:params
  });
}
//废钢出入库记录导出Excel
export async function queryScrapExcel(params:any):Promise<any>{
  return request('/yuncut_stockin_db/ServerFunc_GetScrapWarehousing_Excel',{
    method:'POST',
    data:params
  });
}

//委外加工入库查询
export async function querySubcontract(params:subcontract):Promise<any> {
  return request('/yuncut_balance_db/ServerFunc_GetProcessingStorage', {
    method: 'POST',
    data: params
  });
}
//委外加工入库导出Excel
export async function querySubcontractExport(params:subcontract):Promise<any> {
  return request('/yuncut_balance_db/ServerFunc_GetProcessingStorageExport', {
    method: 'POST',
    data: params
  });
}

//采购订单列表
export async function queryPurchaseOrder(params: PaymentType): Promise<any> {
  return request('/yuncut_materialpurchase_db/ServerFunc_GetPurchasesOrder',{
    method:'POST',
    data:params
  });
}

//采购订单导出Excel
export async function queryPurchaseOrderExcel(params:PaymentType):Promise<any>{
  return request('/yuncut_materialpurchase_db/ServerFunc_GetPurchasesOrder_Excel',{
    method:'POST',
    data:params
  });
}

//采购入库列表
export async function queryPurchaseWarehouse(params: PaymentType): Promise<any> {
  return request('/yuncut_materialpurchase_db/ServerFunc_GetPurchaseWarehouse',{
    method:'POST',
    data:params
  });
}

//采购入库导出Excel
export async function queryPurchaseWarehouseExcel(params:PaymentType):Promise<any>{
  return request('/yuncut_materialpurchase_db/ServerFunc_GetPurchaseWarehouse_Excel',{
    method:'POST',
    data:params
  });
}

//材料出库列表
export async function queryMaterialDelivery(params: PaymentType): Promise<any> {
  return request('/yuncut_materialpurchase_db/ServerFunc_GetMaterialDeliveryList',{
    method:'POST',
    data:params
  });
}

//材料出库导出Excel
export async function queryMaterialDeliveryExcel(params:PaymentType):Promise<any>{
  return request('/yuncut_materialpurchase_db/ServerFunc_GetMaterialDeliveryList_Excel',{
    method:'POST',
    data:params
  });
}

//销售订单列表
export async function querySalesOrder(params: PaymentType): Promise<any> {
  return request('/yuncut_materialpurchase_db/ServerFunc_GetSalesOrder',{
    method:'POST',
    data:params
  });
}



//销售订单导出Excel
export async function querySalesOrderExcel(params:PaymentType):Promise<any>{
  return request('/yuncut_materialpurchase_db/ServerFunc_GetSalesOrder_Excel',{
    method:'POST',
    data:params
  });
}

//销售发货列表
export async function querySalesShipment(params: PaymentType): Promise<any> {
  return request('/yuncut_delivery_db/ServerFunc_GetInvoice',{
    method:'POST',
    data:params
  });
}
//销售发货单导出Excel
export async function querySalesShipmetExcel(params:PaymentType):Promise<any>{
  return request('/yuncut_delivery_db/ServerFunc_GetInvoice_Excel',{
    method:'POST',
    data:params
  });
}

//销售订单明细列表
export async function querySalesOrderDetail(params: PaymentType): Promise<any> {
  return request('/yuncut_materialpurchase_db/ServerFunc_GetSalesOrderDetail',{
    method:'POST',
    data:params
  });
}
//销售订单明细列表导出Excel
export async function querySalesOrderDetailExcel(params:PaymentType):Promise<any>{
  return request('/yuncut_materialpurchase_db/ServerFunc_GetSalesOrderDetail_Excel',{
    method:'POST',
    data:params
  });
}



//废钢购入erp记录
export async function queryScrapPurchase(params: PaymentType): Promise<any> {
  return request('/yuncut_workreport_db/ServerFunc_GetScrapPurchase',{
    method:'POST',
    data:params
  });
}

//废钢购入erp记录列表详情
export async function queryDetailed(params: PaymentType): Promise<any> {
  return request('/yuncut_workreport_db/ServerFunc_GetScrapPurchaseDetailed',{
    method:'POST',
    data:params
  });
}
//废钢购入erp记录导出Excel
export async function queryScrapPurchaseExcel(params:PaymentType):Promise<any>{
  return request('/yuncut_workreport_db/ServerFunc_GetScrapPurchase_Excel',{
    method:'POST',
    data:params
  });
}


//销售订单明细执行报表
export async function queryOrderExecution(params: PaymentType): Promise<any> {
  return request('/yuncut_workreport_db/ServerFunc_GetSalesOrderExecution',{
    method:'POST',
    data:params
  });
}
//销售订单明细执行报表导出Excel
export async function queryOrderExecutionExcel(params:PaymentType):Promise<any>{
  return request('/yuncut_workreport_db/ServerFunc_GetSalesOrderExecution_Excel',{
    method:'POST',
    data:params
  });
}

//YM阿米巴毛利率查询
export async function queryGrossProfit(params: any): Promise<any> {
  return request('/yuncut_finance_db/ServerFunc_GetYmambGrossProfit',{
    method:'POST',
    data:params
  });
}

//YM阿米巴毛利率查询获取所有
export async function queryGrossProfitById(params: any): Promise<any> {
  return request('/yuncut_finance_db/ServerFunc_GetYmambGrossProfitById',{
    method:'POST',
    data:params
  });
}

//YM阿米巴毛利率查询 导出Excel
export async function queryGrossProfitExcel(params: any): Promise<any> {
  return request('/yuncut_finance_db/ServerFunc_GetYmambGrossProfitExcel',{
    method:'POST',
    data:params
  });
}
//YM阿米巴毛利率修改
export async function updateGrossProfit(params: any): Promise<any> {
  return request('/yuncut_finance_db/ServerFunc_UpdateGrossProfit',{
    method:'POST',
    data:params
  });
}

//YM阿米巴修改营销数据
export async function updateGrossProfitYxPrice(params: any): Promise<any> {
  return request('/yuncut_finance_db/ServerFunc_UpdateGrossProfit_YxPrice',{
    method:'POST',
    data:params
  });
}


//YM阿米巴毛利率获取erp数据
export async function updateYmambGrossProfit(params: any): Promise<any> {
  return request('/yuncut_finance_db/ServerFunc_UpdateYmambGrossProfit',{
    method:'POST',
    data:params
  });
}


//Y获取年度阿米巴数据
export async function queryGrossProfitAnnua(params: any): Promise<any> {
  return request('/yuncut_finance_db/ServerFunc_GetYmambGrossProfit_Year',{
    method:'POST',
    data:params
  });
}

//Y获取部门阿米巴数据
export async function queryGrossProfitDept(params: any): Promise<any> {
  return request('/yuncut_finance_db/ServerFunc_GetYmambGrossProfit_Department',{
    method:'POST',
    data:params
  });
}

//获取阿米巴报表数据
export async function queryAmbReportData(params?: any): Promise<any> {
  return request('/yuncut_finance_db/ServerFunc_GetYMambReportData',{
    method:'POST',
    data:params
  });
}
//获取阿米巴报表年份选项
export async function queryYearsOption(params?: any): Promise<any> {
  return request('/yuncut_finance_db/ServerFunc_GetYearsOption',{
    method:'POST',
    data:params
  });
}
//获取阿米巴报表
export async function queryGetYMAmbReportChart(params?: any): Promise<any> {
  return request('/yuncut_finance_db/ServerFunc_GetYMambReportChart',{
    method:'POST',
    data:params
  });
}
//Y获取部门和月份阿米巴数据
export async function queryGetYmAmbcDepName(params: any): Promise<any> {
  return request('/yuncut_finance_db/ServerFunc_GetYmAmbcDepName',{
    method:'POST',
    data:params
  });
}

//Y获取马尼所有订单号
export async function getAllOrder(params: any): Promise<any> {
  return request('/yuncut_delivery_db/ServerFunc_GetAllOrder',{
    method:'POST',
    data:params
  });
}

//Y获取马尼所有发货单号
export async function getAllDelivery(params: any): Promise<any> {
  return request('/yuncut_delivery_db/ServerFunc_GetAllDelivery',{
    method:'POST',
    data:params
  });
}
