import request from '@/utils/request';
import { RcFile } from 'antd/lib/upload';

export async function upload(file:RcFile): Promise<any> {
  const token=encodeURIComponent('{"data":{"dataId":"' + file.uid + '","uploadId":"","storeType":"1"}}');
  const formData=new FormData();
  formData.append('file',file);
  formData.append('token',token);
  formData.append('operation','FileUpload');
  return request('/module-operation!executeOperation',{
    method:'POST',
    data:formData,
    requestType:'form'
  });
}

/**
 * 多个文件
 * @param file
 */
export async function uploadFilesToCache(file:RcFile): Promise<any> {

  //const token=encodeURIComponent('{"data":{"dataId":"' + file.uid + '","uploadId":"","storeType":"1"}}');
  const formData=new FormData();
  formData.append('file',file);
  // formData.append('token',token);
  // formData.append('operation','FileUpload');
  return request('/edm/edmFile/uploadFilesToCache',{
    method:'POST',
    data:formData,
    requestType:'form'
  });
}

/**
 * 单个文件
 * @param file
 */
export async function uploadFileToCache(file:RcFile): Promise<any> {

  //const token=encodeURIComponent('{"data":{"dataId":"' + file.uid + '","uploadId":"","storeType":"1"}}');
  const formData=new FormData();
  formData.append('file',file);
  // formData.append('token',token);
  // formData.append('operation','FileUpload');
  return request('/edm/edmFile/uploadFileToCache',{
    method:'POST',
    data:formData,
    requestType:'form'
  });
}
/**
 * 单个文件 推送缓存中的文件到EDM
 * @param params
 */
export async function pushFileFormCacheToEDM(params:any): Promise<any> {
  return request('/edm/edmFile/pushFileFormCacheToEDM',{
    method:'POST',
    data:params
  });
}

// export async function multipleUpload(file:RcFile): Promise<any> {
//   const token=encodeURIComponent('{"data":{"dataId":"' + file.uid + '","uploadId":"","storeType":"1"}}');
//   const formData=new FormData();
//   formData.append('file',file);
//   formData.append('token',token);
//   formData.append('operation','FileUpload');
//   return request('/module-operation!executeOperation',{
//     method:'POST',
//     data:formData,
//     requestType:'form'
//   });
// }


export async function download(fileId:string): Promise<any> {
  const token = encodeURIComponent('{"data":{"isMulti":false,"dataId":"' + fileId + '","isShow":0}}');
  return request('/module-operation!executeOperation',{
    method:'POST',
    data:{
      token:token,
      operation:'FileDown'
    },
    requestType:'form'
  });
}
