import request from '@/utils/request';
import { PartCategoryType } from '@/types/partCategory';

export async function save(params:PartCategoryType[]): Promise<any> {
  return request('/yuncut_cusparts_datum_db/ServerFunc_SaveCusPartsDatumCategorys',{
    method:'POST',
    data:params
  });
}

export async function remove(params:{id?:string}): Promise<any> {
  return request('/yuncut_cusparts_datum_db/ServerFunc_DeleteCusPartsDatumCategory',{
    method:'POST',
    data:params
  });
}

export async function query(params:any): Promise<any> {
  return request('/yuncut_cusparts_datum_db/ServerFunc_GetCusPartsDatumCategorys',{
    method:'POST',
    data:params
  });
}
