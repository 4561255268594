import request from '@/utils/request';
import {PaginationParams} from "@/utils/utils";

export async function query(params: PaginationParams): Promise<any> {
  return request('/yuncut_materialsupplier_db/ServerFunc_GetMaterialSupplierList', {
    method: 'POST',
    data: params
  });
}


export async function queryAll(params: PaginationParams): Promise<any> {
  return request('/yuncut_materialsupplier_db/ServerFunc_GetAllMaterialSupplier', {
    method: 'POST',
  });
}


export async function save(params: any): Promise<any> {
  return request('/yuncut_materialsupplier_db/ServerFunc_AddMaterialSupplier', {
    method: 'POST',
    data: {
      materialsupplier: [params]
    }
  });
}

export async function updateSupplier(params: any): Promise<any> {
  return request('/yuncut_materialsupplier_db/ServerFunc_UpdateMaterialSupplier', {
    method: 'POST',
    data: {
      materialsupplier: [params]
    }
  });
}

export async function update(params: any): Promise<any> {
  return request('/yuncut_materialsupplier_db/ServerFunc_UpdateMaterialSupplier', {
    method: 'POST',
    data: {
      materialsupplier: [params]
    }
  });
}

export async function deleteInfo(id: any): Promise<any> {
  return request('/yuncut_materialsupplier_db/ServerFunc_DeleteMaterialSupplier', {
    method: 'POST',
    data: {
      "bizId": id
    }
  })
}
export async function checkIsExite(param:{supplierName:string}): Promise<any> {
  return request('/yuncut_materialsupplier_db/ServerFunc_CheckIsExite', {
    method: 'POST',
    data: param
  })
}
