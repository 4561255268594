import { IConfig, IPlugin } from 'umi-types';
import defaultSettings from './defaultSettings'; // https://umijs.org/config/
import slash from 'slash2';
// import themePluginConfig from './themePluginConfig';
import proxy from './proxy';
import webpackPlugin from './plugin.config';
import userRoutes from './routes-user';
import operationRoutes from './routes-operation';
import supplierRoutes from './routes-supplier';
const { pwa } = defaultSettings;

const { REACT_APP_ENV, ENDPOINT } = process.env;
// console.log('REACT_APP_ENV',process.env,ENDPOINT)
// console.log('跨域代理',proxy.dev)

export const routes = {
  'user': userRoutes,
  'operation': operationRoutes,
  'supplier': supplierRoutes,
};

const plugins: IPlugin[] = [
  ['umi-plugin-antd-icon-config', {}],
  [
    'umi-plugin-react',
    {
      antd: true,
      dva: {
        hmr: false,
      },
      locale: {
        // default false
        enable: true,
        // default zh-CN
        default: 'zh-CN',
        // default true, when it is true, will use `navigator.language` overwrite default
        baseNavigator: true,
      },
      dynamicImport: {
        loadingComponent: './components/PageLoading/index',
        webpackChunkName: true,
        level: 3,
      },
      pwa: pwa
        ? {
          workboxPluginMode: 'InjectManifest',
          workboxOptions: {
            importWorkboxFrom: 'local',
          },
        }
        : false,
      // default close dll, because issue https://github.com/ant-design/ant-design-pro/issues/4665
      // dll features https://webpack.js.org/plugins/dll-plugin/
      // dll: {
      //   include: ['dva', 'dva/router', 'dva/saga', 'dva/fetch'],
      //   exclude: ['@babel/runtime', 'netlify-lambda'],
      // },
    },
  ],
  [
    'umi-plugin-pro-block',
    {
      moveMock: false,
      moveService: false,
      modifyRequest: true,
      autoAddMenu: true,
    },
  ],
];
/**
 * GA
 */
// plugins.push([
//   'umi-plugin-ga',
//   {
//     code: 'UA-1',
//   },
// ]);

// plugins.push(['umi-plugin-antd-theme', themePluginConfig]);

export default {
  plugins,
  devtool: 'source-map',
  disableCSSSourceMap: true,
  hash: true,
  targets: {
    ie: 9,
  },
  // mfsu:{},
  // umi routes: https://umijs.org/zh/guide/router.html
  routes: routes[ENDPOINT || 'user'],
  // Theme for antd: https://ant.design/docs/react/customize-theme-cn
  theme: {
    // ...darkTheme,
    'primary-color': defaultSettings.primaryColor,
  },
  define: {
    REACT_APP_ENV: REACT_APP_ENV || false,
    ENDPOINT: ENDPOINT || 'user',
  },
  ignoreMomentLocale: true,
  lessLoaderOptions: {
    javascriptEnabled: true,
  },
  disableRedirectHoist: true,
  cssLoaderOptions: {
    modules: true,
    getLocalIdent: (
      context: {
        resourcePath: string;
      },
      _: string,
      localName: string,
    ) => {
      if (
        context.resourcePath.includes('node_modules') ||
        context.resourcePath.includes('ant.design.pro.less') ||
        context.resourcePath.includes('global.less')
      ) {
        return localName;
      }
      const match = context.resourcePath.match(/src(.*)/);
      if (match && match[1]) {
        const antdProPath = match[1].replace('.less', '');
        const arr = slash(antdProPath)
          .split('/')
          .map((a: string) => a.replace(/([A-Z])/g, '-$1'))
          .map((a: string) => a.toLowerCase());
        return `antd-pro${arr.join('-')}-${localName}`.replace(/--/g, '-');
      }
      return localName;
    },
  },
  manifest: {
    basePath: '/',
  },
  outputPath: `dist/${ENDPOINT}`,
  proxy: proxy[REACT_APP_ENV || 'dev'],
  extraBabelPlugins: [
    [
      "babel-plugin-optimize-clsx",
      {
        "libraries": ["clsx", "classnames", "react-data-grid"]
      }
    ]
  ],
  chainWebpack: webpackPlugin,
} as IConfig;
