import request from '@/utils/request';

// export async function save(params:any[]): Promise<any> {
//   return request('/yuncut_base_workstage_db/ServerFunc_SaveWorkStage',{
//     method:'POST',
//     data:params
//   });
// }

// export async function remove(params:{id?:string}): Promise<any> {
//   return request('/yuncut_base_workstage_db/ServerFunc_DeleteWorkStage',{
//     method:'POST',
//     data:params
//   });
// }

export async function query(): Promise<any> {
  return request('/yuncut_base_workstage_db/ServerFunc_GetWorkstage_Dropdown', {
    method:'POST'
  });
}

//获取工序列表
export async function queryList(): Promise<any> {
  return request('/yuncut_base_workstage_db/ServerFunc_GetWorkstage', {
    method:'POST'
  });
}
//保存工序列表
export async function saveProcess(param:{workstage:[],ids:string}): Promise<any> {
  return request('/yuncut_base_workstage_db/ServerFunc_SaveWorkStage', {
    method:'POST',
    data:{
      "workstage": param.workstage,
      "ids": param.ids
    }
  });
}
//删除工序列表
export async function deleteProcess(param:{bizId:string}): Promise<any> {
  return request('/yuncut_base_workstage_db/ServerFunc_DeleteWorkStage', {
    method:'POST',
    data:{
      "bizId": param.bizId
    }
  });
}
//获取保存下列数据源
export async function dropdownDataSource(): Promise<any> {
  return request('/yuncut_base_workstage_db/ServerFunc_GetWorkstage_Dropdown', {
    method:'POST'
  });
}
//验证工序是否存在
export async function verifyProcessIsExist(param:{name:string}): Promise<any> {
  return request('/yuncut_base_workstage_db/ServerFunc_VerifyProcessUnique', {
    method:'POST',
    data:{
      name:param.name
    }
  });
}

