import request from '@/utils/request';

//获取材质选项
export async function query(): Promise<any> {
  return request('/yuncut_base_texture_db/ServerFunc_GetTexture_DropDown',{
    method:'POST',
  });
}

export interface TextureMaterialQueryParams{
  isEnable?:boolean;
  textureName?:string;
  textureCode?:string;
  textureStandardId?:string;
  categoryId?:string;
}

//获取材质列表
export async function queryAll(params:TextureMaterialQueryParams): Promise<any> {
  return request('/yuncut_base_texture_db/ServerFunc_GetTextureList',{
    method:'POST',
    data:params
  });
}

//保存
export async function save(params:any): Promise<any> {
  return request('/yuncut_base_texture_db/ServerFunc_Save_texture',{
    method:'POST',
    data: {
      texture: [params]
    }
  });
}

//删除
export async function remove(params:{bizId?:string}): Promise<any> {//删除
  return request('/yuncut_base_texture_db/ServerFunc_Delete_texture',{
    method:'POST',
    data:params
  });
}

//设置是否启用
export async function setEnable(params:{ bizId?:string,isEnable?:boolean}): Promise<any> {//更新状态
  return request('/yuncut_base_texture_db/ServerFunc_SetEnable_Status',{
    method:'POST',
    data:params
  });
}

// 校验材质编码
export async function checkCodeIsExist(params:any) {
  return request('/yuncut_base_texture_db/ServerFunc_IfTextureListCodeExist', {
    method: 'POST',
    data: params,
  });
}

// 校验材质名称
export async function checkNameIsExist(params:any) {
  return request('/yuncut_base_texture_db/ServerFunc_IfTextureListNameExist', {
    method: 'POST',
    data: params,
  });
}
