import request from "@/utils/request";

export async function queryOption(params:any): Promise<any> {
  return request('/yuncut_base_texturecategory_db/ServerFunc_GetTextureCategoryInfo_V2',{
    method:'POST',
    data:params
  });
}

export async function query(params:any): Promise<any> {
  return request('/yuncut_base_texturecategory_db/ServerFunc_GetTexturecategory',{
    method:'POST',
    data:params
  });
}

export async function save(params:any): Promise<any> {//保存
  return request('/yuncut_base_texturecategory_db/ServerFunc_SaveTextureCategory', {
    method: 'POST',
    data: {
      texturecategory:[params]
    }
  });
}

export async function remove(params:{bizId?:string}): Promise<any> {//删除
  return request('/yuncut_base_texturecategory_db/ServerFunc_DeleteTextureCategory',{
    method:'POST',
    data:params
  });
}



export async function enable(params:{ bizId?:string,isEnable?:boolean}): Promise<any> {//更新状态
  return request('/yuncut_base_texturecategory_db/ServerFunc_SetEnable_Status',{
    method:'POST',
    data:params
  });
}
