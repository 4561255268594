import request from "@/utils/request";

/**
 * 查询工厂运费单列表
 * @param params
 */
export async function queryCarriageList(params:any):Promise<any>{
  return request('/yuncut_factory_finance_db/ServerFunc_GetCarriage_List',{
    method:'POST',
    data:params,
  });
}

/**
 * 新增工厂运费单列表
 * @param params
 */
export async function addCarriage(params:string): Promise<any> {
  return request('/yuncut_factory_finance_db/SeerverFunc_AddCarriage',{
    method:'POST',
    data: params
  });
}

/**
 * 删除工厂运费单列表
 * @param params
 */
export async function deleteCarriage(params:{bizId?:string}): Promise<any> {
  return request('/yuncut_factory_finance_db/ServerFunc_Delete_Carriage',{
    method:'POST',
    data:params
  });
}

/**
 * 修改工厂运费单列表
 * @param params
 */
export async function updateCarriage(params:string): Promise<any> {
  return request('/yuncut_factory_finance_db/ServerFunc_Update_Carriage',{
    method:'POST',
    data: params
  });
}

/**
 * 查询收付款列表
 * @param params
 */
export async function queryCollectPayList(params:any):Promise<any>{
  return request('/yuncut_factory_finance_db/ServerFunc_GetConfirmCollectionAndPayment_list',{
    method:'POST',
    data:params
  });
}

// ServerFunc_ConfirmCollectionAndPayment
/**
 * 确认收付款申请
 * @param params
 * @constructor
 */
export async function confirmCollectionAndPayment(params:any):Promise<any>{
  return request('/yuncut_factory_finance_db/ServerFunc_ConfirmCollectionAndPayment',{
    method:'POST',
    data:params
  });
}

/**
 * 获取结算方式
 * @param params
 */
export async function GetSetType(params:any):Promise<any>{
  return request('/yuncut_factory_base/ServerFunc_GetSetType',{
    method:'POST',
    data:params
  });
}

/**
 * 获取销售发货单下拉列表
 * @param params
 */
export async function GetDeliverType(params:any):Promise<any>{
  return request('/yuncut_factorydelivery_db/ServerFunc_GetAlldeliver_Options',{
    method:'POST',
    data:params
  });
}
/**
 * 获取材料采购下拉列表
 * @param params
 */
export async function GetPurchaseType(params:any):Promise<any>{
  return request('/yuncut_factorymaterialinout_db/ServerFunc_Purchase_InTo_List',{
    method:'POST',
    data:params
  });
}
/**
 * 获取款项类型
 * @param params
 */
export async function GetPayType(params:any):Promise<any>{
  return request('/yuncut_factory_base/ServerFunc_GetPayType',{
    method:'POST',
    data:params
  });
}

/**
 * 发起收付款申请
 * @param params
 * @constructor
 */
export async function AddCollectionAndPayment(params:any):Promise<any>{
  return request('/yuncut_factory_finance_db/ServerFunc_AddCollectionAndPayment',{
    method:'POST',
    data:params
  });
}
/**
 * 修改收付款申请
 * @param params
 * @constructor
 */
export async function UpdateCollectionAndPayment(params:any):Promise<any>{
  return request('/yuncut_factory_finance_db/ServerFunc_UpdateCollectionAndPayment',{
    method:'POST',
    data:params
  });
}
/**
 * 删除收付款申请
 * @param params
 * @constructor
 */
export async function DeleteCollectionAndPayment(params:any):Promise<any>{
  return request('/yuncut_factory_finance_db/ServerFunc_DeleteCollectionAndPayMent',{
    method:'POST',
    data:params
  });
}
/**
 * 导出收付款单据
 * @param params
 * @constructor
 */
export async function GetConfirmCollectionAndPayment_Excel(params:any):Promise<any>{
  return request('/yuncut_factory_finance_db/ServerFunc_GetConfirmCollectionAndPayment_Excel',{
    method:'POST',
    data:params
  });
}
/**
 * 收付款报表
 * @param params
 * @constructor
 */
export async function CollectionAndPayment_report(params:any):Promise<any>{
  return request('/yuncut_factory_finance_db/ServerFunc_CollectionAndPayment_report',{
    method:'POST',
    data:params
  });
}
