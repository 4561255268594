import { Effect } from 'dva';
import {AnyAction, Reducer} from 'redux';

import { query as queryCutMethod } from '@/services/common/cutMethod';
import { query as queryMaterialBrand } from '@/services/common/materialBrand';
import {
  query as queryPlateThickness,
  queryByCutMethodId as queryAllPlateThickness,
} from '@/services/common/plateThickness';
import { query as queryTextureMaterial } from '@/services/common/textureMaterial';
import {query as queryMaterialStandard, queryMaterialClassification} from '@/services/common/materialStandard';
import { queryOption as queryMaterialCategory } from '@/services/common/materialCategory';
import { query as queryPartCategory } from '@/services/user/partCategory';
import { query as queryWorkProcedure } from '@/services/common/workProcedure';
import {arrayToTree} from "@/utils/utils";
import { OptionType } from '@/types/basicLibrary';
import {queryAll as queryMaterialSupplier} from "@/services/operation/materialSupplier";
import {queryAll as querySupplier,queryGys,queryAllCentre} from "@/services/operation/supplier";
import {query as querySupplierFactory,queryCentre} from "@/services/operation/supplierFactory";
import {query as querySupplierStorehouse,
  queryC as querySupplierStorehouseCentre,
  queryYl as querySupplierStorehouseCentreyl,
  queryShelf,
  queryFilter as queryFilterSupplierStorehouse,
  queryPartsFilter as queryPartsFilterSupplierStorehouse,
  queryFilterCentre,
  queryKg as querySupplierStorehouseCentrekg,
  queryCy as querySupplierStorehouseCentreCy,
  queryIntoType, querySupplierType
} from "@/services/operation/supplierStorehouse";
import {queryProvinces} from '@/services/common/district';
import {queryAgreement,queryAllCustomer} from "@/services/operation/customerUser";
import {queryFactoryCustomerOption, queryFactoryWarehouseDropDown} from '@/services/supplier/partFile'
import {GetDeliverType, GetPayType, GetPurchaseType, GetSetType} from "@/services/supplier/finance";
import {
  queryGetYmAmbcDepName,
  getAllOrder,
  getAllDelivery,
  queryGetYMAmbReportChart
} from "@/services/operation/payment";
import {
  YmAmbCusType,
} from "@/pages/operation/financeReport/ymAmbProfitReportChart/model";

export interface BasicLibraryStateType {
  cutMethodOptions:OptionType[];
  materialBrandOptions:OptionType[];
  plateThicknessOptions:OptionType[];
  allPlateThicknessOptions:OptionType[];
  textureMaterialOptions:OptionType[];
  materialStandardOptions:OptionType[];
  materialCategoryOptions:OptionType[];
  materialClassificationOptions:OptionType[];//材质归类option
  partCategoryOptions:OptionType[];
  workProcedureOptions:OptionType[];
  materialSupplierList:OptionType[];
  storageLocation:OptionType[];
  province:OptionType[];
  agreement:OptionType[];
  customerOptions:OptionType[];
  warehouseOptions:OptionType[];//供应商仓库下拉框
  IntoTypeOptions:OptionType[];//供应商收发类别
  supplierOptions:OptionType[];//工厂_供应商下拉列表
  factoryCustomerOptions:OptionType[];//工厂客户下拉列表
  SetType:OptionType[];//工厂结算方式
  PayType:OptionType[];//工厂款项类型
  DeliverType:OptionType[];//自营销售发货单
  PurchaseType:OptionType[];//自营采购订单列表
  YmAmbcDepNameType:OptionType[];//阿米巴部门
  YmAmbcyearMounthType:OptionType[];//阿米巴月份
  AllOrderType:OptionType[];//所有马尼订单号
  AllDeliveryType:OptionType[];//所有马尼发货单号
  YmAmbProfitReportChartType:YmAmbCusType;//
}

export interface ModelType {
  namespace: 'basicLibrary';
  state: BasicLibraryStateType;
  effects: {
    queryAllPlateThicknessOptions: Effect;
    queryCutMethodOptions: Effect;
    queryMaterialBrandOptions: Effect;
    queryProvinceOptions: Effect;
    queryAgreementOptions: Effect;//查询客户协议档案
    queryCustomerOptions: Effect;//查询客户档案信息
    queryPlateThicknessOptions: Effect;
    queryTextureMaterialOptions: Effect;
    queryMaterialStandardOptions: Effect;
    queryMaterialClassification: Effect;//查询材质归类下拉数据来源
    queryMaterialCategoryOptions: Effect;
    queryPartCategoryOptions: Effect;
    queryWorkProcedureOptions: Effect;
    queryMaterialSupplier: Effect;//查询材料供应商
    querySupplier: Effect;//查询供应商
    querySupplierFactory: Effect;//查询供应商工厂
    queryFilterSupplierStorehouse: Effect;//查询过滤供应商仓库
    querySupplierType:Effect;
    querySupplierCentre: Effect;//查询供应商(中心仓)
    querySupplierFactoryCentre: Effect;//查询供应商工厂(中心仓)
    queryFilterSupplierStorehouseCentre: Effect;//查询过滤供应商仓库(中心仓)
    queryPartsFilterSupplierStorehouse: Effect;//查询产品供应商仓库
    querySupplierStorehouse: Effect;//查询供应商仓库
    querySupplierStorehouseCentre: Effect;//查询供应商仓库(中心仓)
    querySupplierStorehouseCentreyl: Effect;//查询供应商仓库(中心仓余料)
    querySupplierStorehouseCentrekg:Effect;//查询供应商仓库(中心仓客供)
    querySupplierStorehouseCentreCy:Effect;//查询供应商仓库(中心仓采购和余料)
    queryShelf: Effect;//查询货架
    queryGys: Effect;//查询货架
    queryFactoryWarehouseOptions: Effect;//查询仓库（供应商）
    queryIntoType:Effect;//供应商（收发类别）
    queryFactoryCustomerOptions:Effect;//查询工厂客户档案
    GetPayType:Effect;//获取工厂款项类型
    GetSetType:Effect;//获取工厂结算方式
    GetDeliverType:Effect;
    GetPurchaseType:Effect;
    queryGetYmAmbcDepName: Effect;//获取阿米巴部门
    queryGetYmAmbcyearMounth: Effect;//获取阿米巴月份
    getAllOrder: Effect;//所有马尼订单号
    getAllDelivery: Effect;//所有马尼发货单号
    queryGetYMAmbReportChart: Effect;//阿米巴报表数据，
  };
  reducers: {
    changePalateThicknessOptions: Reducer<BasicLibraryStateType>;
    changeAllPalateThicknessOptions: Reducer<BasicLibraryStateType>;
    changeCutMethodOptions: Reducer<BasicLibraryStateType>;
    changeMaterialBrandOptions: Reducer<BasicLibraryStateType>;
    changePlateThicknessOptions: Reducer<BasicLibraryStateType>;
    changeTextureMaterialOptions: Reducer<BasicLibraryStateType>;
    changeMaterialStandardOptions: Reducer<BasicLibraryStateType>;
    changeMaterialClassificationOptions: Reducer<BasicLibraryStateType>;//材质归类下拉列表
    changeMaterialCategoryOptions: Reducer<BasicLibraryStateType>;
    changePartCategoryOptions: Reducer<BasicLibraryStateType>;
    changeWorkProcedureOptions: Reducer<BasicLibraryStateType>;
    changeMaterialSupplierList:Reducer<BasicLibraryStateType>;//更改物料供应商清单
    changeStorageLocation:Reducer<BasicLibraryStateType>;//更改存储位置
    changeStorageLocations:Reducer<BasicLibraryStateType>;//更改存储位置（中心仓）
    changeProvinceOptions:Reducer<BasicLibraryStateType>;//省份
    changeAgreementOptions:Reducer<BasicLibraryStateType>;//客户协议档案
    changeCustomerOptions:Reducer<BasicLibraryStateType>;//客户协议档案
    changWarehouseOptions:Reducer<BasicLibraryStateType>;//供应商仓库
    changIntoTypeOptions:Reducer<BasicLibraryStateType>;//供应商收发类别
    changSupplierOptions:Reducer<BasicLibraryStateType>;//工厂供应商下拉列表
    changFactoryCustomerOptions:Reducer<BasicLibraryStateType>;//工厂客户下拉列表
    changFactorySetTypeOptions:Reducer<BasicLibraryStateType>;//工厂结算方式下拉列表
    changFactoryPayTypeOptions:Reducer<BasicLibraryStateType>;//工厂款项类型下拉列表
    changFactoryDeliverTypeOptions:Reducer<BasicLibraryStateType>;//工厂发货单下拉列表
    changFactoryPurchaseTypeOptions:Reducer<BasicLibraryStateType>;//工厂发货单下拉列表
    changYmAmbcDepNameTypeOptions:Reducer<BasicLibraryStateType>;//阿米巴部门
    changYmAmbcyearMounthTypeOptions:Reducer<BasicLibraryStateType>;//阿米巴月份
    changAllOrderTypeOptions:Reducer<BasicLibraryStateType>;//阿米巴月份
    changAllDeliveryTypeOptions:Reducer<BasicLibraryStateType>;//阿米巴月份
    changeProfitReportChartType:Reducer<BasicLibraryStateType>;//
  };
}

const Model: ModelType = {
  namespace: 'basicLibrary',
  state: {
    cutMethodOptions: [],//切割工艺
    materialBrandOptions: [],
    plateThicknessOptions: [],//板厚
    allPlateThicknessOptions: [],
    textureMaterialOptions: [],//材质
    materialStandardOptions: [],
    materialClassificationOptions:[],//材质归类
    materialCategoryOptions: [],
    partCategoryOptions: [],
    workProcedureOptions: [],
    materialSupplierList:[],//材料供应商清单
    storageLocation:[],//存储位置
    province:[],//省份
    agreement:[],//客户协议档案
    customerOptions:[],//客户档案信息
    warehouseOptions:[],//供应商仓库
    IntoTypeOptions:[],//供应商收发类别
    supplierOptions:[],//工厂——供应商下拉列表
    factoryCustomerOptions:[],
    PayType:[],//款项类型
    SetType:[],//结算方式
    DeliverType:[],//自营发货单列
    PurchaseType:[],
    YmAmbcDepNameType:[],//阿米巴部门
    YmAmbcyearMounthType:[],//阿米巴月份
    AllOrderType:[],
    AllDeliveryType:[],
    YmAmbProfitReportChartType:[],//阿米巴
  },

  effects: {
    *queryAllPlateThicknessOptions({payload}, {call, put}) {
      const {data} = yield call(queryAllPlateThickness,payload, {eCutmethodId:[]});
      const list=(data?.PlateThickLoadData||[]).map((item:any)=>{
        return {
          id:item.id,
          name:item.plateThick,
        }
      });
      yield put({
        type: 'changeAllPalateThicknessOptions',
        payload: list,
      });
    },
    *queryCutMethodOptions({ payload }, { call, put }) {
      const {data}  = yield call(queryCutMethod);
      const cutMethodOptions =  data.result;
      for (let i = 0; i < cutMethodOptions.length; i++) {
        let {data}  = yield call(queryPlateThickness, {cutmethodId :cutMethodOptions[i].id });
        cutMethodOptions[i]['plateThickness']=data?.result||[]
      }
      yield put({
        type: 'changeCutMethodOptions',
        payload: cutMethodOptions||[],
      });
      return data?.result||[];
    },
    *queryMaterialBrandOptions(_, { call, put }) {
      const {data}  = yield call(queryMaterialBrand);
      yield put({
        type: 'changeMaterialBrandOptions',
        payload: data?.result||[],
      });
      return data?.result||[];
    },
    *queryPlateThicknessOptions({payload}, { call, put }) {
      const {data}  = yield call(queryPlateThickness,payload);
      yield put({
        type: 'changePlateThicknessOptions',
        payload: data?.result||[],
      });
      return data?.result||[];
    },
    *queryTextureMaterialOptions(_, { call, put }) {
      const {data}  = yield call(queryTextureMaterial);
      yield put({
        type: 'changeTextureMaterialOptions',
        payload: data?.result||[],
      });
    },
    *queryProvinceOptions(_, { call, put }) {
      const {data}  = yield call(queryProvinces);
      yield put({
        type: 'changeProvinceOptions',
        payload: data?.result||[],
      });
    },
    *queryAgreementOptions(_, { call, put }) {//查询所有客户协议档案
      const {data}  = yield call(queryAgreement);
      yield put({
        type: 'changeAgreementOptions',
        payload: data?.result||[],
      });
    },
    *queryCustomerOptions(_, { call, put }) {//查询所有客户档案信息
      const {data}  = yield call(queryAllCustomer);
      yield put({
        type: 'changeCustomerOptions',
        payload: data?.result||[],
      });
    },
    *queryMaterialStandardOptions(_, { call, put }) {
      const {data}  = yield call(queryMaterialStandard);
      yield put({
        type: 'changeMaterialStandardOptions',
        payload: data?.result||[],
      });
    },

    *queryMaterialClassification(_, { call, put }) {
      const {data}  = yield call(queryMaterialClassification);
      yield put({
        type: 'changeMaterialClassificationOptions',
        payload: data?.result||[],
      });
    },

    *queryMaterialCategoryOptions(_, { call, put }) {
      const {data}  = yield call(queryMaterialCategory);
      yield put({
        type: 'changeMaterialCategoryOptions',
        payload: data?.result||[],
      });
    },
    *queryPartCategoryOptions(_, { call, put }) {
      const {data}  = yield call(queryPartCategory);
      yield put({
        type: 'changePartCategoryOptions',
        payload: arrayToTree(data?.categorys||[],{pIdKey:'PID'}),
      });
    },
    *queryWorkProcedureOptions(_, {call, put}) {
      const {data}  = yield call(queryWorkProcedure);
      yield put({
        type: 'changeWorkProcedureOptions',
        payload: data?.result||[],
      });
    },
    *queryMaterialSupplier({ payload }, { call, put }) {
      const {data}=yield call(queryMaterialSupplier);
      yield put({
        type:'changeMaterialSupplierList',
        payload:data?.materialsupplier||[]
      })
    },
    *querySupplier({ payload }, { call, put }) {
      const {data}=yield call(querySupplier);
      const list=data?.sup_supplier_arch||[];
      yield put({
        type:'changeStorageLocation',
        payload:list.map((item:any)=>{
          return {...item,name:item.companyName,isLeaf: false}
        })
      });
      return list.map((item:any)=>{
        return {...item,name:item.companyName,isLeaf: false}
      });
    },
    *querySupplierCentre({ payload }, { call, put }) {//查询中心仓供应商
      const {data}=yield call(queryAllCentre,payload);
      const list=data?.sup_supplier_arch||[];
      yield put({
        type:'changeStorageLocations',
        payload:list.map((item:any)=>{
          return {...item,name:item.companyName,isLeaf: false}
        })
      });
      return list.map((item:any)=>{
        return {...item,name:item.companyName,isLeaf: false}
      });
    },
    *queryGys({ payload }, { call, put }) {
      const {data}=yield call(queryGys);
      const list=data?.sup_supplier_arch||[];
      yield put({
        type:'changeStorageLocation',
        payload:list.map((item:any)=>{
          return {...item,name:item.companyName,isLeaf: false}
        })
      });
      return list.map((item:any)=>{
        return {...item,name:item.companyName,isLeaf: false}
      });
    },
    *querySupplierFactory({ payload }, { call, put,select }) {
      let options:OptionType[] = yield select(
        (state: any) => state.basicLibrary.storageLocation,
      );
      const index=options.findIndex(item=>item.id===payload[0]);
      let supplier=options[index];
      if(supplier.children&&supplier.children.length>0){
        return ;
      }
      const {data}=yield call(querySupplierFactory,payload[0]);
      supplier.children=(data.getFactory||[]).map((item:OptionType)=>{
        return {...item,name:item.factoryName,isLeaf: false}
      });
      options[index]=supplier;
      yield put({
        type: 'changeStorageLocation',
        payload:[...options],
      });
    },
    *querySupplierFactoryCentre({ payload }, { call, put,select }) {//获取所有中心仓工厂
      let options:OptionType[] = yield select(
        (state: any) => state.basicLibrary.storageLocation,
      );
      const index=options.findIndex(item=>item.id===payload[0]);
      let supplier=options[index];
      if(supplier.children&&supplier.children.length>0){
        return ;
      }
      const {data}=yield call(queryCentre,payload[0]);
      supplier.children=(data.getFactory||[]).map((item:OptionType)=>{
        return {...item,name:item.factoryName,isLeaf: false}
      });
      options[index]=supplier;
      yield put({
        type: 'changeStorageLocations',
        payload:[...options],
      });
    },
    *querySupplierStorehouse({ payload }, { call, put,select }) {
      let options:OptionType[] = yield select(
        (state: any) => state.basicLibrary.storageLocation,
      );

      const supplierIndex=options.findIndex(item=>item.id===payload[0]);
      let supplier=options[supplierIndex];
      const factoryIndex=supplier.children?.findIndex(item=>item.id===payload[1]);
      // @ts-ignore
      let factory=supplier.children[factoryIndex];
      if(factory.children&&factory.children.length>0){
        return ;
      }
      const {data} = yield call(querySupplierStorehouse,payload[1]);
      factory.children=(data.sup_supplier_warehouse||[]).map((item:OptionType)=>{
        return {...item,name:item.warehouseName,isLeaf: true}
      });
      // @ts-ignore
      supplier[factoryIndex]=factory;
      options[supplierIndex]=supplier;
      yield put({
        type: 'changeStorageLocation',
        payload: [...options],
      });
    },
    *queryFilterSupplierStorehouse({ payload }, { call, put,select }) {
      let options:OptionType[] = yield select(
        (state: any) => state.basicLibrary.storageLocation,
      );

      const supplierIndex=options.findIndex(item=>item.id===payload[0]);
      let supplier=options[supplierIndex];
      const factoryIndex=supplier.children?.findIndex(item=>item.id===payload[1]);
      // @ts-ignore
      let factory=supplier.children[factoryIndex];
      if(factory.children&&factory.children.length>0){
        return ;
      }
      const {data} = yield call(queryFilterSupplierStorehouse,payload[1]);
      factory.children=(data.sup_supplier_warehouse||[]).map((item:OptionType)=>{
        return {...item,name:item.warehouseName,isLeaf: true}
      });
      // @ts-ignore
      supplier[factoryIndex]=factory;
      options[supplierIndex]=supplier;
      yield put({
        type: 'changeStorageLocation',
        payload: [...options],
      });
    },

    *queryFilterSupplierStorehouseCentre({ payload }, { call, put,select }) {//获取所有仓库中心仓
      let options:OptionType[] = yield select(
        (state: any) => state.basicLibrary.storageLocation,
      );

      const supplierIndex=options.findIndex(item=>item.id===payload[0]);
      let supplier=options[supplierIndex];
      const factoryIndex=supplier.children?.findIndex(item=>item.id===payload[1]);
      // @ts-ignore
      let factory=supplier.children[factoryIndex];
      if(factory.children&&factory.children.length>0){
        return ;
      }
      const {data} = yield call(queryFilterCentre,payload[1]);
      factory.children=(data.sup_supplier_warehouse||[]).map((item:OptionType)=>{
        return {...item,name:item.warehouseName,isLeaf: true}
      });
      // @ts-ignore
      supplier[factoryIndex]=factory;
      options[supplierIndex]=supplier;
      yield put({
        type: 'changeStorageLocations',
        payload: [...options],
      });
    },

    *querySupplierStorehouseCentre({ payload }, { call, put,select }) {//获取所有仓库中心仓+余料
      let options:OptionType[] = yield select(
        (state: any) => state.basicLibrary.storageLocation,
      );

      const supplierIndex=options.findIndex(item=>item.id===payload[0]);
      let supplier=options[supplierIndex];
      const factoryIndex=supplier.children?.findIndex(item=>item.id===payload[1]);
      // @ts-ignore
      let factory=supplier.children[factoryIndex];
      if(factory.children&&factory.children.length>0){
        return ;
      }
      let warehouseType = null;
      if (payload.length == 3) {
        warehouseType = payload[2]
      }
      const {data} = yield call(querySupplierStorehouseCentre,payload[1],warehouseType);
      factory.children=(data.sup_supplier_warehouse||[]).map((item:OptionType)=>{
        return {...item,name:item.warehouseName,isLeaf: true}
      });
      // @ts-ignore
      supplier[factoryIndex]=factory;
      options[supplierIndex]=supplier;
      yield put({
        type: 'changeStorageLocations',
        payload: [...options],
      });
    },
    *querySupplierStorehouseCentreyl({ payload }, { call, put,select }) {//获取所有仓库中心仓+余料
      let options:OptionType[] = yield select(
        (state: any) => state.basicLibrary.storageLocation,
      );

      const supplierIndex=options.findIndex(item=>item.id===payload[0]);
      let supplier=options[supplierIndex];
      const factoryIndex=supplier.children?.findIndex(item=>item.id===payload[1]);
      // @ts-ignore
      let factory=supplier.children[factoryIndex];
      if(factory.children&&factory.children.length>0){
        return ;
      }
      const {data} = yield call(querySupplierStorehouseCentreyl,payload[1]);
      factory.children=(data.sup_supplier_warehouse||[]).map((item:OptionType)=>{
        return {...item,name:item.warehouseName,isLeaf: true}
      });
      // @ts-ignore
      supplier[factoryIndex]=factory;
      options[supplierIndex]=supplier;
      yield put({
        type: 'changeStorageLocations',
        payload: [...options],
      });
    },

    *querySupplierStorehouseCentrekg({ payload }, { call, put,select }) {//获取所有仓库中心仓+客供
      let options:OptionType[] = yield select(
        (state: any) => state.basicLibrary.storageLocation,
      );

      const supplierIndex=options.findIndex(item=>item.id===payload[0]);
      let supplier=options[supplierIndex];
      const factoryIndex=supplier.children?.findIndex(item=>item.id===payload[1]);
      // @ts-ignore
      let factory=supplier.children[factoryIndex];
      if(factory.children&&factory.children.length>0){
        return ;
      }
      const {data} = yield call(querySupplierStorehouseCentrekg,payload[1]);
      factory.children=(data.sup_supplier_warehouse||[]).map((item:OptionType)=>{
        return {...item,name:item.warehouseName,isLeaf: true}
      });
      // @ts-ignore
      supplier[factoryIndex]=factory;
      options[supplierIndex]=supplier;
      yield put({
        type: 'changeStorageLocations',
        payload: [...options],
      });
    },

    *querySupplierStorehouseCentreCy({ payload }, { call, put,select }) {//获取所有仓库中心仓+主材+余料
      let options:OptionType[] = yield select(
        (state: any) => state.basicLibrary.storageLocation,
      );

      const supplierIndex=options.findIndex(item=>item.id===payload[0]);
      let supplier=options[supplierIndex];
      const factoryIndex=supplier.children?.findIndex(item=>item.id===payload[1]);
      // @ts-ignore
      let factory=supplier.children[factoryIndex];
      if(factory.children&&factory.children.length>0){
        return ;
      }
      const {data} = yield call(querySupplierStorehouseCentreCy,payload[1]);
      factory.children=(data.sup_supplier_warehouse||[]).map((item:OptionType)=>{
        return {...item,name:item.warehouseName,isLeaf: true}
      });
      // @ts-ignore
      supplier[factoryIndex]=factory;
      options[supplierIndex]=supplier;
      yield put({
        type: 'changeStorageLocations',
        payload: [...options],
      });
    },

    //零件采购入库供应商列表
    *queryPartsFilterSupplierStorehouse({ payload }, { call, put,select }) {
      let options:OptionType[] = yield select(
        (state: any) => state.basicLibrary.storageLocation,
      );
      const supplierIndex=options.findIndex(item=>item.id===payload[0]);
      let supplier=options[supplierIndex];
      const factoryIndex=supplier.children?.findIndex(item=>item.id===payload[1]);
      // @ts-ignore
      let factory=supplier.children[factoryIndex];
      if(factory.children&&factory.children.length>0){
        return ;
      }
      const {data} = yield call(queryPartsFilterSupplierStorehouse,payload[1]);
      factory.children=(data.sup_supplier_warehouse||[]).map((item:OptionType)=>{
        return {...item,name:item.warehouseName,isLeaf: true}
      });
      // @ts-ignore
      supplier[factoryIndex]=factory;
      options[supplierIndex]=supplier;
      yield put({
        type: 'changeStorageLocation',
        payload: [...options],
      });
    },


    *queryShelf({ payload }, { call, put,select }) {
      let options:OptionType[] = yield select(
        (state: any) => state.basicLibrary.storageLocation,
      );
      const supplierIndex=options.findIndex(item=>item.id===payload[0]);
      let supplier=options[supplierIndex];
      const factoryIndex=supplier.children?.findIndex(item=>item.id===payload[1]);
      // @ts-ignore
      let factory=supplier.children[factoryIndex];
      const storehouseIndex=factory.children?.findIndex((item:any)=>item.id===payload[2]);
      let storehouse=factory.children[storehouseIndex];
      if(storehouse.children&&storehouse.children.length>0){
        return ;
      }

      const {data} = yield call(queryShelf,payload[2]);
      storehouse.children=(data.su_supplier_shelf||[]).map((item:OptionType)=>{
        return {...item,name:item.shelfName,isLeaf: false}
      });
      // @ts-ignore
      factory[storehouseIndex]=storehouse;
      // @ts-ignore
      supplier[factoryIndex]=factory;
      options[supplierIndex]=supplier;
      yield put({
        type: 'changeStorageLocation',
        payload: [...options],
      });
    },

    //新增的查询供应商下拉仓库
    *queryFactoryWarehouseOptions({ payload }, { call, put }) {
      const {data}=yield call(queryFactoryWarehouseDropDown,payload);
      yield put({
        type:'changeWarehouseSupplierList',
        payload:data?.result||[],
      })
      return data?.result||[]
    },

    *queryIntoType({payload}, { call, put }) {
      const {data}  = yield call(queryIntoType,payload);
      yield put({
        type: 'changIntoTypeOptions',
        payload: data?.result||[],
      });
      return data?.result||[];
    },

    *querySupplierType({payload}, { call, put }) {
      const {data}  = yield call(querySupplierType,payload);
      yield put({
        type: 'changSupplierOptions',
        payload: data?.supplier||[],
      });
      return data?.supplier||[];
    },

    *queryFactoryCustomerOptions({payload}, { call, put }) {
      const {data}  = yield call(queryFactoryCustomerOption,payload);
      yield put({
        type: 'changFactoryCustomerOptions',
        payload: data?.result||[],
      });
      return data?.result||[];
    },
    *GetSetType(_, { call, put }) {
      const {data}  = yield call(GetSetType);
      yield put({
        type: 'changFactorySetTypeOptions',
        payload: data?.result||[],
      });
    },
    *GetDeliverType(_, { call, put }) {
      const {data}  = yield call(GetDeliverType);
      yield put({
        type: 'changFactoryDeliverTypeOptions',
        payload: data?.result||[],
      });
    },
    *GetPurchaseType(_, { call, put }) {
      const {data}  = yield call(GetPurchaseType);
      yield put({
        type: 'changFactoryPurchaseTypeOptions',
        payload: data?.result||[],
      });
    },
    *GetPayType({payload}, { call, put }) {
      const {data}  = yield call(GetPayType,payload);
      yield put({
        type: 'changFactoryPayTypeOptions',
        payload: data?.result||[],
      });
    },
    * queryGetYmAmbcDepName({payload}, {call, put}) {
      const {data} = yield call(queryGetYmAmbcDepName, payload);
      yield put({
        type: 'changYmAmbcDepNameTypeOptions',
        payload: data?.Q_GetYmAmbcDepName||[],
      });
    },
    * queryGetYmAmbcyearMounth({payload}, {call, put}) {
      const {data} = yield call(queryGetYmAmbcDepName, payload);
      yield put({
        type: 'changYmAmbcyearMounthTypeOptions',
        payload: data?.Q_GetYmAmbYearMounth||[],
      });
    },
    * getAllOrder({payload}, {call, put}) {
      const {data} = yield call(getAllOrder, payload);
      yield put({
        type: 'changAllOrderTypeOptions',
        payload: data?.result||[],
      });
    },
    * getAllDelivery({payload}, {call, put}) {
      const {data} = yield call(getAllDelivery, payload);
      yield put({
        type: 'changAllDeliveryTypeOptions',
        payload: data?.result||[],
      });
    },
    * queryGetYMAmbReportChart({payload}, {call, put}) {
      const {data} = yield call(queryGetYMAmbReportChart, payload);
      yield put({
        type: 'changeProfitReportChartType',
        payload: data||[]
      });
    },
  },
  reducers: {
    // @ts-ignore
    changeCutMethodOptions(state:BasicLibraryStateType, action:AnyAction) {
      return {
        ...state,
        cutMethodOptions: action.payload || [],
      };
    },
    // @ts-ignore
    changeMaterialBrandOptions(state:BasicLibraryStateType, action:AnyAction) {
      return {
        ...state,
        materialBrandOptions: action.payload || [],
      };
    },
    // @ts-ignore
    changePlateThicknessOptions(state:BasicLibraryStateType, action:AnyAction) {
      return {
        ...state,
        plateThicknessOptions: action.payload || [],
      };
    },
    // @ts-ignore
    changeTextureMaterialOptions(state:BasicLibraryStateType, action:AnyAction) {
      return {
        ...state,
        textureMaterialOptions: action.payload || [],
      };
    },
    // @ts-ignore
    changeProvinceOptions(state:BasicLibraryStateType, action:AnyAction) {
      return {
        ...state,
        province: action.payload || [],
      };
    },
    // @ts-ignore
    changeAgreementOptions(state:BasicLibraryStateType, action:AnyAction) {
      return {
        ...state,
        agreement: action.payload || [],
      };
    },
    // @ts-ignore
    changeCustomerOptions(state:BasicLibraryStateType, action:AnyAction) {
      return {
        ...state,
        customerOptions: action.payload || [],
      };
    },
    // @ts-ignore
    changeMaterialStandardOptions(state:BasicLibraryStateType, action:AnyAction) {
      return {
        ...state,
        materialStandardOptions: action.payload || [],
      };
    },
    // @ts-ignore  材质归类下拉
    changeMaterialClassificationOptions(state:BasicLibraryStateType, action:AnyAction) {
      return {
        ...state,
        materialClassificationOptions: action.payload || [],
      };
    },
    // @ts-ignore
    changeMaterialCategoryOptions(state:BasicLibraryStateType, action:AnyAction) {
      return {
        ...state,
        materialCategoryOptions: action.payload || [],
      };
    },
    // @ts-ignore
    changePartCategoryOptions(state:BasicLibraryStateType, action:AnyAction) {
      return {
        ...state,
        partCategoryOptions: action.payload || [],
      };
    },
    // @ts-ignore
    changeWorkProcedureOptions(state:BasicLibraryStateType, action:AnyAction) {
      return {
        ...state,
        workProcedureOptions: action.payload || [],
      };
    },
    // @ts-ignore
    changeMaterialSupplierList(state: BasicLibraryStateType, action: AnyAction) {
      return {
        ...state,
        materialSupplierList: action.payload,
      };
    },
    // @ts-ignore
    changeStorageLocation(state: BasicLibraryStateType, action: AnyAction) {
      return {
        ...state,
        storageLocation: action.payload,
      };
    },
    // @ts-ignore
    changeStorageLocations(state: BasicLibraryStateType, action: AnyAction) {
      return {
        ...state,
        storageLocation: action.payload,
      };
    },
    // @ts-ignore
    changeAllPalateThicknessOptions(state:BasicLibraryStateType, action:AnyAction) {
      return {
        ...state,
        allPlateThicknessOptions: action.payload || [],
      };
    },

    // @ts-ignore
    changeWarehouseSupplierList(state:BasicLibraryStateType, action:AnyAction) {
      return {
        ...state,
        warehouseOptions: action.payload || [],
      };
    },

    // @ts-ignore      供应商收发类别
    changIntoTypeOptions(state:BasicLibraryStateType, action:AnyAction) {
      return {
        ...state,
        IntoTypeOptions: action.payload || [],
      };
    },
    // @ts-ignore      供应商收发类别
    changSupplierOptions(state:BasicLibraryStateType, action:AnyAction) {
      return {
        ...state,
        supplierOptions: action.payload || [],
      };
    },
    // @ts-ignore      供应商
    changFactoryCustomerOptions(state:BasicLibraryStateType, action:AnyAction) {
      return {
        ...state,
        factoryCustomerOptions: action.payload || [],
      };
    },
    // @ts-ignore      结算方式
    changFactorySetTypeOptions(state:BasicLibraryStateType, action:AnyAction) {
      return {
        ...state,
        SetType: action.payload || [],
      };
    },
    // @ts-ignore      结算方式
    changFactoryDeliverTypeOptions(state:BasicLibraryStateType, action:AnyAction) {
      return {
        ...state,
        DeliverType: action.payload || [],
      };
    },
    // @ts-ignore      结算方式
    changFactoryPurchaseTypeOptions(state:BasicLibraryStateType, action:AnyAction) {
      return {
        ...state,
        PurchaseType: action.payload || [],
      };
    },
    // @ts-ignore     款项类型
    changFactoryPayTypeOptions(state:BasicLibraryStateType, action:AnyAction) {
      return {
        ...state,
        PayType: action.payload || [],
      };
    },
    // @ts-ignore     阿米巴部门
    changYmAmbcDepNameTypeOptions(state:BasicLibraryStateType, action:AnyAction) {
      return {
        ...state,
        YmAmbcDepNameType: action.payload || [],
      };
    },
    // @ts-ignore     阿米巴月份
    changYmAmbcyearMounthTypeOptions(state:BasicLibraryStateType, action:AnyAction) {
      return {
        ...state,
        YmAmbcyearMounthType: action.payload || [],
      };
    },
    // @ts-ignore
    changAllOrderTypeOptions(state:BasicLibraryStateType, action:AnyAction) {
      return {
        ...state,
        AllOrderType: action.payload || [],
      };
    },
    // @ts-ignore
    changAllDeliveryTypeOptions(state:BasicLibraryStateType, action:AnyAction) {
      return {
        ...state,
        AllDeliveryType: action.payload || [],
      };
    },
    // @ts-ignore     阿米巴数据，
    changeProfitReportChartType(state:BasicLibraryStateType, action:AnyAction) {
      return {
        ...state,
        YmAmbProfitReportChartType: action.payload || [],
      };
    },
  },
};

export default Model;
